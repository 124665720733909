import React from "react";
import AddTutorial from "../../Components/AdminComps/TutorialComps/AddTutorial/AddTutorial";

const AddTutorialPage = () => {
  return (
    <div>
      <AddTutorial />
    </div>
  );
};

export default AddTutorialPage;

import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetch_get_my_plates } from "../../../Redux/Plates/plateActions";
import Loading from "../../../utils/Loading";
import PlateCard from "../../PlateCard/PlateCard";
import Header from "../Header/Header";

const MyPosts = () => {
  const dispatch = useDispatch();
  const { my_plates } = useSelector((state) => state.plate);

  useEffect(() => {
    dispatch(fetch_get_my_plates());
  }, []);

  if (!my_plates) {
    return (
      <div className="text-center pt-5">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <Container>
        {/* TODO: POSTS */}
        <div className="mt-3 mb-5">
          <Row>
            {my_plates?.my_posts?.map((item) => {
              return (
                <Col className="mx-auto py-3" md={8}>
                  <PlateCard item={item} />
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default MyPosts;

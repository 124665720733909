import { Paper, Button } from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import Header from "../Header/Header";
import { useFormik } from "formik";
import { AiOutlineCamera } from "react-icons/ai";
import Styles from "./HostComp.module.css";
import Switch from "@mui/material/Switch";
import swal from "sweetalert";
import Axios from "axios";
import { useHistory } from "react-router";
import Loading from "./../../../utils/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  fetch_get_my_profile_info,
  fetch_host_eligibility,
} from "../../../Redux/profile/profileAction";
import { Link } from "react-router-dom";

const initialValues = {
  plate_name: "",
  price: "",
  quantity: "",
  category: "",
  location: "",
  description: "",
  last_date_order: "",
  last_time_order: "",
  discount_price: "",
  plate_images: "",
};

const HostComps = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [images, setImages] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const { my_profileInfo, host_eligibility } = useSelector(
    (state) => state.profile
  );

  //console.log(my_profileInfo);

  const handleChange1 = (event) => {
    setChecked1(event.target.checked);
  };
  const handleChange2 = (event) => {
    setChecked2(event.target.checked);
  };

  const validate = (values) => {
    const {
      plate_name,
      price,
      location,
      quantity,
      discount_price,
      category,
      plate_images,
      last_time_order,
      last_date_order,
    } = values;
    let errors = {};

    if (!plate_name) {
      errors.plate_name = "Please enter a plate name";
    }
    if (!price) {
      errors.price = "Please enter plate price";
    }
    if (!quantity) {
      errors.quantity = "Please enter plate quantity";
    }
    if (!location) {
      errors.location = "Please enter your location";
    }
    if (!category) {
      errors.category = "Please choose category";
    }
    if (discount_price >= price) {
      errors.discount_price = "Discount price must be less than price";
    }
    if (!plate_images) {
      errors.plate_images = "Please insert your plate images";
    }
    if (!checked1) {
      if (!last_time_order) {
        errors.last_time_order = "Please enter your plates time to order";
      }
      if (!last_date_order) {
        errors.last_date_order = "Please enter your plates date to order";
      }
    }

    return errors;
  };
  const onSubmit = (values) => {
    const {
      plate_name,
      price,
      location,
      quantity,
      description,
      category,
      plate_images,
      discount_price,
      last_time_order,
      last_date_order,
    } = values;

    const formData = new FormData();

    formData.append("plate_name", plate_name);
    formData.append("price", price);
    formData.append("location", location);
    formData.append("quantity", quantity);
    formData.append("description", description);
    formData.append("plate_category", category);
    for (let i = 0; i < plate_images.length; i++) {
      formData.append("plate_images", plate_images[i]);
    }

    formData.append("discount_price", discount_price);
    formData.append("all_time_available", checked1);
    formData.append("own_delivery_system", checked2);

    if (checked1) {
      formData.append("last_time_to_order", "");
      formData.append("last_date_to_order", "");
    } else {
      formData.append("last_time_to_order", last_time_order);
      formData.append("last_date_to_order", last_date_order);
    }
    setLoad(true);
    Axios.post("/api/host-plate", formData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        setLoad(false);
        if (result.data.error) {
          return setErrorMsg(result.data.error);
        } else {
          swal("", result.data.success, "success");
          history.push("/");
        }
      })
      .catch((err) => {
        setLoad(false);
        swal("", "Something went wrong", "error");
      });
  };

  // new
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  let img = [];
  useEffect(() => {
    for (let i = 0; i < formik.values.plate_images.length; i++) {
      img.push(formik.values.plate_images[i]);
    }
    setImages(img);
  }, [formik.values.plate_images]);

  useEffect(() => {
    dispatch(fetch_get_my_profile_info());
    dispatch(fetch_host_eligibility());
  }, []);

  console.log(host_eligibility.hostQuota);
  return (
    <div>
      <Header />
      <Container className="mb-5 pb-5">
        {/* <h4 className="text-center py-3">Host new plate</h4> */}

        {host_eligibility.subscription === "free" && (
          <h5 className="text-center">
            {host_eligibility.hostQuota - host_eligibility.plate_host_count ? (
              <Alert variant="dark" className="text-center">
                You can host only{" "}
                {host_eligibility.hostQuota - host_eligibility.plate_host_count}{" "}
                plates more in this month
              </Alert>
            ) : (
              <Alert variant="danger" className="text-center">
                Your Per Month Quota Exits
              </Alert>
            )}
          </h5>
        )}

        <Row>
          <Col className="mx-auto" md={8}>
            <Paper className="p-4 shadow">
              {errorMsg && (
                <Alert variant="danger" className="text-center">
                  {errorMsg}
                </Alert>
              )}
              {my_profileInfo?.userId?.phone ? (
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Plate name *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Please enter plate name"
                      name="plate_name"
                      {...formik.getFieldProps("plate_name")}
                    />
                    <small style={{ color: "red" }}>
                      {formik.touched.plate_name &&
                        formik.errors.plate_name &&
                        formik.errors.plate_name}
                    </small>
                  </Form.Group>

                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Plate price *</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Please enter plate price"
                          name="price"
                          {...formik.getFieldProps("price")}
                        />
                        <small style={{ color: "red" }}>
                          {formik.touched.price &&
                            formik.errors.price &&
                            formik.errors.price}
                        </small>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className="mb-3">
                    <Form.Label>Plate quantity *</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Please enter plate quantity"
                      name="quantity"
                      {...formik.getFieldProps("quantity")}
                    />
                    <small style={{ color: "red" }}>
                      {formik.touched.quantity &&
                        formik.errors.quantity &&
                        formik.errors.quantity}
                    </small>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Category *</Form.Label>
                    <Form.Select
                      name="category"
                      {...formik.getFieldProps("category")}
                    >
                      <option value="">Please choose</option>
                      <option value="All">Any</option>
                      <option value="Breakfast">Breakfast</option>
                      <option value="Lunch">Lunch</option>
                      <option value="Dinner">Dinner</option>
                      <option value="Side Dish">Side Dish</option>
                      <option value="Frozen Food">Frozen Food</option>
                    </Form.Select>
                    <small style={{ color: "red" }}>
                      {formik.touched.category &&
                        formik.errors.category &&
                        formik.errors.category}
                    </small>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Your location *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Please enter your current location"
                      name="location"
                      {...formik.getFieldProps("location")}
                    />
                    <small style={{ color: "red" }}>
                      {formik.touched.location &&
                        formik.errors.location &&
                        formik.errors.location}
                    </small>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Please enter your plate description"
                      name="description"
                      {...formik.getFieldProps("description")}
                    />
                  </Form.Group>
                  <Form.Group className="d-flex align-items-center mb-3 flex-wrap">
                    <span style={{ width: "130px" }}>Plate images *:</span>
                    {images && (
                      <>
                        {images?.map((item) => {
                          return (
                            <>
                              <img
                                className={Styles.imagePreview__Style}
                                src={URL.createObjectURL(item)}
                                alt=""
                              />
                            </>
                          );
                        })}
                      </>
                    )}
                    <label className={Styles.imageUpload__style}>
                      <AiOutlineCamera
                        style={{
                          fontSize: "34px",
                          color: "gray",
                          marginTop: "5px",
                        }}
                      />
                      <br />
                      <small>Upload your plate images </small>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        multiple={true}
                        name="plate_images"
                        onChange={(e) => {
                          formik.setFieldValue("plate_images", e.target.files);
                        }}
                      />
                    </label>
                    <small style={{ color: "red" }}>
                      {formik.touched.plate_images &&
                        formik.errors.plate_images &&
                        formik.errors.plate_images}
                    </small>
                  </Form.Group>

                  <Row>
                    <Col md={6}>
                      {" "}
                      <Form.Group className="mb-3">
                        <Form.Label>Last date to order </Form.Label>
                        <Form.Control
                          disabled={checked1}
                          type="date"
                          name="last_date_order"
                          {...formik.getFieldProps("last_date_order")}
                        />
                        <small style={{ color: "red" }}>
                          {formik.touched.last_date_order &&
                            formik.errors.last_date_order &&
                            formik.errors.last_date_order}
                        </small>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      {" "}
                      <Form.Group className="mb-3">
                        <Form.Label>Last time to order </Form.Label>
                        <Form.Control
                          disabled={checked1}
                          name="last_time_order"
                          type="time"
                          {...formik.getFieldProps("last_time_order")}
                        />
                        <small style={{ color: "red" }}>
                          {formik.touched.last_time_order &&
                            formik.errors.last_time_order &&
                            formik.errors.last_time_order}
                        </small>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <span>Can order any time </span> :
                      <Switch
                        checked={checked1}
                        onChange={handleChange1}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Col>
                    <Col md={6}>
                      <span>Own delivery service</span> :
                      <Switch
                        checked={checked2}
                        onChange={handleChange2}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Col>
                  </Row>

                  <div className="text-center mt-4">
                    {/* checking eligibility
                    if not eligibile the disable the button */}
                    {host_eligibility.hostQuota <=
                    host_eligibility.plate_host_count ? (
                      <Button
                        style={{ backgroundColor: "#958470" }}
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          swal("", "Your Quota of this month exists", "error")
                        }
                      >
                        Host Plate
                      </Button>
                    ) : (
                      <>
                        {load ? (
                          <Loading />
                        ) : (
                          <Button
                            style={{ backgroundColor: "#958470" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Host Plate
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </Form>
              ) : (
                <>
                  <Alert variant="info" className="text-center">
                    You must add your phone number before host a plate.{" "}
                    <Link to="/profile">Add Phone Number</Link>
                  </Alert>
                </>
              )}
            </Paper>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HostComps;

import React, { useState } from "react";
import { Col, Container, Form } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Styles from "./DeliveryManNewPass.module.css";
import { Paper, Button } from "@material-ui/core";
import loginImg from "../../../assets/Images/Login/Rectangle 11 (1).png";

import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useFormik } from "formik";
import swal from "sweetalert";
import { useHistory } from "react-router";
import plateLogo from "../../../assets/Images/Login/plateLogo.svg";
import { useSpring, animated } from "react-spring";

const initialValues = {
  confirmPassword: "",
  password: "",
};

const DeliveryManNewPass = () => {
  const [passType, setPassType] = useState("password");
  const [passType2, setPassType2] = useState("password");
  const history = useHistory();

  const validate = (values) => {
    const { confirmPassword, password } = values;

    let errors = {};

    if (!password) {
      errors.password = "Please enter your password";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Please enter your confirm password";
    } else if (password !== confirmPassword) {
      errors.confirmPassword = "password must be matched";
    }

    return errors;
  };

  const onSubmit = (values) => {
    const { password } = values;

    const userData = JSON.parse(
      sessionStorage.getItem("deliveryMan_forgotPassData")
    );

    // console.log(userData);

    if (!userData) {
      swal("", "Please verify your email or phone number first", "error");
      history.push("/forgot-password");
    }

    let newPassData;

    newPassData = {
      password,
      phone: userData.phone,
    };

    console.log(newPassData);

    fetch("/api/delivery-man/new-password", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPassData),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.success) {
          history.push("/delivery-man/login");
          return swal("", result.success, "success");
        } else {
          return swal("", "Something went wrong", "error");
        }
      })
      .catch((err) => {
        console.log(err);
        return swal("", "Something went wrong", "error");
      });
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  //react-spring animation props
  const props = useSpring({
    to: { x: 0 },
    from: { x: -80 },
    config: { duration: 750 },
  });

  return (
    <div className={Styles.main__div}>
      <Container className="mt-4 ">
        <Row>
          <Col className="mx-auto" md={10}>
            <Paper className="shadow">
              <Row>
                <Col md={6}>
                  <div className={Styles.left__style}>
                    <img src={loginImg} alt="" />
                    <div className={Styles.overlay__style}>
                      <div className={Styles.content__style}>
                        <img
                          style={{ width: "130px", height: "130px" }}
                          src={plateLogo}
                          alt=""
                        />
                        {/* react-spring animated div */}
                        <animated.div style={props}>
                          <h4>HOMEMADE </h4>
                          <h1>FOOD</h1>
                          <h6>AT YOUR DOORSTEP</h6>
                        </animated.div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="px-5 pt-4" md={6}>
                  <div className="text-center">
                    <img
                      className={Styles.myPlate__logo__style}
                      src={plateLogo}
                      alt=""
                    />
                  </div>

                  <h4 className="text-center py-4" style={{ color: "#372B2B" }}>
                    Change Password
                  </h4>
                  <Form onSubmit={formik.handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Password *</Form.Label> <br />
                      <div className={Styles.passwordField__Style}>
                        <input
                          type={passType}
                          placeholder="Please enter your password"
                          name="password"
                          {...formik.getFieldProps("password")}
                        />
                        {passType === "password" ? (
                          <AiOutlineEyeInvisible
                            onClick={() => setPassType("text")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        ) : (
                          <AiOutlineEye
                            onClick={() => setPassType("password")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        )}
                      </div>
                      <small style={{ color: "red" }}>
                        {formik.touched.password &&
                          formik.errors.password &&
                          formik.errors.password}
                      </small>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Confirm Password *</Form.Label> <br />
                      <div className={Styles.passwordField__Style}>
                        <input
                          type={passType2}
                          placeholder="Please enter your confirm password"
                          name="confirmPassword"
                          {...formik.getFieldProps("confirmPassword")}
                        />
                        {passType2 === "password" ? (
                          <AiOutlineEyeInvisible
                            onClick={() => setPassType2("text")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        ) : (
                          <AiOutlineEye
                            onClick={() => setPassType2("password")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        )}
                      </div>
                      <small style={{ color: "red" }}>
                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword &&
                          formik.errors.confirmPassword}
                      </small>
                    </Form.Group>

                    <Button
                      style={{
                        width: "100%",
                        color: "white",
                        backgroundColor: "#372B2B",
                        margin: "10px 0px",
                      }}
                      variant="contained"
                      type="submit"
                    >
                      Change Password
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Paper>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DeliveryManNewPass;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetch_get_deliveryMan_lists, delete_deliveryman_action, block_deliveryman_action, unblock_deliveryman_action } from "../../../../Redux/Admin/AdminActions";
import { Alert, Container, Table } from "react-bootstrap";
//import CommonViewTitle from "./../../../../utils/CommonViewTitle";
import Loading from "./../../../../utils/Loading";
import { MdDeleteOutline } from "react-icons/md";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ModalImage from "react-modal-image";


const ViewDeliveryMans = () => {
  const dispatch = useDispatch();
  const { deliveryManList } = useSelector((state) => state.admin);
  const [openBlockDialog, setOpenBlockDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [userId, setUserId] = React.useState(false);

  const handleClickOpenBlockDialog = (userId) => {
    setOpenBlockDialog(true);
    setUserId(userId);
  };

  const handleCloseBlockDialog = () => {
    setOpenBlockDialog(false);
    setUserId(false);
  };

  const handleClickOpenDeleteDialog = (userId) => {
    setOpenDeleteDialog(true);
    setUserId(userId);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setUserId(false);
  };

  useEffect(() => {
    dispatch(fetch_get_deliveryMan_lists());
  }, []);

  const blockHandler = () => {
    if (userId) {
      dispatch(block_deliveryman_action(userId));
      handleCloseBlockDialog();
    }
  }
  const deleteHandler = () => {
    if (userId) {
      dispatch(delete_deliveryman_action(userId));
      handleCloseDeleteDialog();
    }
  }
  const unblockHandler = (user_id) => {
    dispatch(unblock_deliveryman_action(user_id));
  }


  if (!deliveryManList) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (deliveryManList?.error) {
    return (
      <Alert variant="danger" className="text-center">
        {deliveryManList?.error}
      </Alert>
    );
  }


  return (
    <div>
      {" "}
      <div>
        <Container>
          {/* <CommonViewTitle title="Delivery Man Infos" /> */}
          <h4 className="text-center py-3">Delivery Man Infos</h4>
          <Table bordered hover responsive className="text-center">
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>NID Photo</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {deliveryManList?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.phone}</td>
                    <td>
                      <ModalImage
                        small={item?.nid}
                        large={item?.nid}
                        alt="Hello World!"
                      />
                      {/* <img
                        className="common_table_image_style"
                        src={item?.nid}

                      /> */}
                    </td>
                    <td>
                      {item.status === "blocked" ?
                        <LockOutlinedIcon fontSize="small" color="disabled" onClick={() => unblockHandler(item._id)} /> :
                        <LockOpenOutlinedIcon fontSize="small" color="success" onClick={() => handleClickOpenBlockDialog(item._id)} />}
                      <MdDeleteOutline className="delete_icon_Style" onClick={() => handleClickOpenDeleteDialog(item._id)} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      </div>
      {/* block dialog */}
      <Dialog
        open={openBlockDialog}
        onClose={handleCloseBlockDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Block this active user?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are going to block this active user. Blocking this user,the user will no longer available to post, report, like, and host a plate.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBlockDialog} autoFocus>Cancel</Button>
          <Button onClick={blockHandler}>Block</Button>
        </DialogActions>
      </Dialog>

      {/* delete dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this  user?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are going to delete this  user. Deleting this user,the user will no longer available in my plate system.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} autoFocus>Cancel</Button>
          <Button onClick={deleteHandler}>Delete</Button>
        </DialogActions>
      </Dialog>


    </div>
  );
};

export default ViewDeliveryMans;

import React from 'react';
import Tutorial from '../../Components/Tutorial/Tutorial';

const TutorialPage = () => {
    return (
        <div>
            <Tutorial/>
        </div>
    );
};

export default TutorialPage;
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import img1 from "../../assets/Images/About/About US.jpg";
import img2 from "../../assets/Images/About/img2.jpg";
import img3 from "../../assets/Images/About/img3.jpg";
import Styles from "./AboutUs.module.css";

const AboutUs = () => {
  return (
    <div>
      <Container>
        <h4 className="text-center py-3 mb-3">About US</h4>
        <Row className="d-flex align-items-center justify-content-between">
          <Col md={6}>
            <h4 className="p-4 text-center">
              MyPlate is a HomeMade Food Sharing Web Application.
            </h4>
          </Col>
          <Col md={6}>
            <img className={Styles.image__style} src={img1} alt="Side Image" />
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-between">
          <Col md={6}>
            <img className={Styles.image__style} src={img2} alt="Side Image" />
          </Col>
          <Col md={6}>
            <h4 className="p-4 text-center">
              Bringing HomeMade food at your DroorStep for an Affortable cost.
            </h4>
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-between">
          <Col md={6}>
            <h4 className="p-4 text-center">Cook And Share</h4>
          </Col>
          <Col md={6}>
            <img className={Styles.image__style} src={img3} alt="Side Image" />
          </Col>
        </Row>
        <b>MyPlatexyz Inc</b>
        <br />
        Jamaica, NY - 11432 <br />
        USA <br />
        <u>support@myplate.xyz</u>
      </Container>
    </div>
  );
};

export default AboutUs;

import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import Styles from "./OrderCard.module.css";
import { Col, Row } from "react-bootstrap";
import AcceptOrderModal from "../AcceptOrderModal/AcceptOrderModal";

const OrderCard = ({ item }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [orderId, setOrderId] = useState("");
  const {
    delivery_address,
    total_price,
    plate_info,
    last_date_to_order,
    last_time_to_order,
    order_id,
    host_info,
  } = item;

  return (
    <div>
      <Paper className="p-4 shadow ">
        <h3 className="text-center">Delivery Information</h3>
        <hr />
        <Row className="mb-2">
          <Col>
            <b>OrderId: </b>
          </Col>
          <Col>
            <span>{order_id}</span>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <b>Location: </b>
          </Col>
          <Col>
            <span>
              {plate_info.location} <b style={{ color: "red" }}>to </b>{" "}
              {delivery_address}
            </span>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <b>Host Name : </b>
          </Col>
          <Col>
            <span>{host_info?.name} </span>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <b>Host Phone: </b>
          </Col>
          <Col>
            <span>{host_info?.phone} </span>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <b>Total Price : </b>
          </Col>
          <Col>
            <span>{total_price} BDT</span>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <b>Delivery Time : </b>
          </Col>
          <Col>
            <span> 12.30 pm</span>
          </Col>
        </Row>

        <button
          onClick={() => {
            setOrderId(item._id);
            setModalShow(true);
          }}
          className={Styles.button__Style}
        >
          Accept
        </button>

        <AcceptOrderModal
          price={total_price}
          orderId={orderId}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </Paper>
    </div>
  );
};

export default OrderCard;

import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetch_get_tutorial_lists } from "../../Redux/Admin/AdminActions";
import Loading from "../../utils/Loading";
import AOS from "aos";

const Tutorial = () => {
  AOS.init();
  const dispatch = useDispatch();
  const { tutorialList } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(fetch_get_tutorial_lists());
  }, [dispatch]);

  if (!tutorialList) {
    return <Loading />;
  }
  return (
    <div>
      <Container>
        <Row>
          <h4 className="text-center py-3">Follow Our Tutorials</h4>
          {tutorialList?.map((item, index) => {
            return (
              <Col
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                className="mx-auto py-2"
                md={8}
              >
                <iframe
                  style={{ width: "100%", height: "400px" }}
                  src={item.video_link}
                  title={item.title}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Tutorial;

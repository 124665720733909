import React from "react";
import ViewTutorials from "./../../Components/AdminComps/TutorialComps/ViewTutorials/ViewTutorials";

const ViewTutorialsPage = () => {
  return (
    <div>
      <ViewTutorials />
    </div>
  );
};

export default ViewTutorialsPage;

import React, { useState } from "react";
import Header from "../Header/Header";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { Paper } from "@mui/material";
import alImg from "../../../assets/Images/PlaceHolderImages/img_avatar.png";
import Styles from "./MyProfile.module.css";
import { fetch_get_my_profile_info } from "../../../Redux/profile/profileAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Loading from "./../../../utils/Loading";
import { FiEdit } from "react-icons/fi";
import Axios from "axios";
import EditGeneralInfo from "./EditGeneralInfoModal/EditGeneralInfo";
import AddPhoneNumberModal from "../../ConfirmOrderComp/AddPhoneNumberModal/AddPhoneNumberModal";
import { AiOutlineEdit } from "react-icons/ai";
import { IconButton } from "@material-ui/core";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import QrCodeOutlinedIcon from "@mui/icons-material/QrCodeOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import WcOutlinedIcon from "@mui/icons-material/WcOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import Tooltip from "@mui/material/Tooltip";

const MyProfile = () => {
  const [modalShowOne, setModalShowOne] = React.useState(false);
  // const [modalShowTwo, setModalShowTwo] = React.useState(false);
  // const [modalShowThree, setModalShowThree] = React.useState(false);
  const [addPhoneModal, setAddPhoneModal] = React.useState(false);
  const dispatch = useDispatch();
  const { my_profileInfo } = useSelector((state) => state.profile);
  const [profileImg, setProfileImg] = useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  // copy to clipboard handler
  const copyToClipBoard = (msg) => {
    navigator.clipboard.writeText(msg);
    setOpenSnackbar(true);
  };

  // snackbar transitions
  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  // get the user details on first load
  useEffect(() => {
    dispatch(fetch_get_my_profile_info());
  }, []);

  // if profile image is changed
  useEffect(() => {
    if (profileImg) {
      const formData = new FormData();
      formData.append("profile_image", profileImg);

      Axios.put("/api/profile-image", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user_token"),
        },
      })
        .then((result) => {
          dispatch(fetch_get_my_profile_info());
          setProfileImg("");
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
  }, [profileImg]);

  // console.log(my_profileInfo);
  if (!my_profileInfo) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  if (my_profileInfo?.error) {
    return (
      <div>
        <Alert variant="danger">{my_profileInfo?.error}</Alert>
      </div>
    );
  }

  const { gender, profile_image, userId, user_address } = my_profileInfo;

  return (
    <div>
      <Header />
      <Container>
        <Paper className="p-6 shadow">
          <div className={Styles.profile_img_main_style}>
            <img
              className={Styles.profile_image_style}
              src={profile_image ? profile_image : alImg}
              alt=""
            />
            <label className={Styles.edit_profile_img_style}>
              <FiEdit className={Styles.editIcon_profile_img_style} />
              <input
                onChange={(e) => {
                  setProfileImg(e.target.files[0]);
                }}
                style={{ display: "none" }}
                type="file"
                name=""
                id=""
              />
            </label>
          </div>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <BadgeOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Name"
                secondary={
                  userId?.name ? (
                    userId?.name
                  ) : (
                    <span style={{ color: "gray" }}>Not added yet</span>
                  )
                }
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PhoneInTalkOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Phone"
                secondary={userId?.phone || "Not added yet"}
              />
              <IconButton
                aria-label="Edit Phone Number"
                onClick={() => setAddPhoneModal(true)}
              >
                <EditOutlinedIcon />
              </IconButton>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AlternateEmailOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Email"
                secondary={userId?.email ? userId?.email : "Not added yet"}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <HomeOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Address"
                secondary={user_address ? user_address : "Not added yet"}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <WcOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Gender"
                secondary={gender ? gender : " Not added yet"}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <QrCodeOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="My Promo" secondary={userId.myPromoCode} />
              <Tooltip title="Copy Promo Code">
                <IconButton
                  aria-label="Copy Promo Code"
                  onClick={() => copyToClipBoard(userId.myPromoCode)}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <RestaurantIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="My Quota" secondary={userId.myQuota} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ConnectWithoutContactOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="My Client"
                secondary={Object.keys(userId.myRefClients).length}
              />
            </ListItem>
          </List>
          <div className={Styles.profile_editIcon__Style}>
            <FiEdit onClick={() => setModalShowOne(true)} />
          </div>
          <Snackbar
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
            TransitionComponent={TransitionUp}
            message="Promo Code Copied..."
          />

          {/* <Row className="mt-5">
            <Col className="mx-auto" md={4}>
              <Row className="text-center py-2">
                <Col>
                  <b>Name :</b>
                </Col>
                <Col>
                  {userId?.name ? (
                    userId?.name
                  ) : (
                    <span style={{ color: "gray" }}>Not added yet</span>
                  )}
                </Col>
              </Row>
              <Row className="text-center py-2">
                <Col>
                  <b>Email :</b>
                </Col>
                <Col>
                  {" "}
                  {userId?.email ? (
                    userId?.email
                  ) : (
                    <span style={{ color: "gray" }}>Add</span>
                  )}
                </Col>
              </Row>
              <Row className="text-center py-2">
                <Col>
                  <b>Phone :</b>
                </Col>
                <Col>
                  <span>{userId?.phone || "Not added yet"}</span>
                  <span
                    style={{
                      fontSize: "20px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setAddPhoneModal(true)}
                  >
                    <AiOutlineEdit />
                  </span>{" "}
                </Col>
              </Row>

              <Row className="text-center py-2">
                <Col>
                  <b>Gender :</b>
                </Col>
                <Col>
                  {gender ? (
                    gender
                  ) : (
                    <span style={{ color: "gray" }}>Not added yet</span>
                  )}
                </Col>
              </Row>
              <Row className="text-center py-2">
                <Col>
                  <b>Address :</b>
                </Col>
                <Col>
                  {user_address ? (
                    user_address
                  ) : (
                    <span style={{ color: "gray" }}>Not added yet</span>
                  )}
                </Col>
              </Row>
            </Col>
            <div className={Styles.profile_editIcon__Style}>
              <FiEdit onClick={() => setModalShowOne(true)} />
            </div>
          </Row> */}
        </Paper>
      </Container>

      {/* MODALS */}

      <EditGeneralInfo
        gender={gender}
        name={userId.name}
        user_address={user_address}
        show={modalShowOne}
        onHide={() => setModalShowOne(false)}
      />

      <AddPhoneNumberModal
        show={addPhoneModal}
        onHide={() => setAddPhoneModal(false)}
      />
    </div>
  );
};

export default MyProfile;

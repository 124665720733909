import React, { useState } from "react";
import { Col, Container, Form } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Styles from "./DeliveryManRegister.module.css";
import { Paper, Button } from "@material-ui/core";
//import loginImg from "../../../assets/Images/Login/Rectangle 11 (1).png";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useFormik } from "formik";
import swal from "sweetalert";
import { useHistory } from "react-router";
import plateLogo from "../../../assets/Images/Login/plateLogo.svg";
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

const initialValues = {
  email: "",
  phone: "",
  name: "",
  otp_code: "",
  password: "",
  confirmPassword: "",
  nid_photo: "",
};

const DeliveryManRegisterComp = () => {
  // const [emailType, setEmailType] = useState("email");
  // const [phoneType, setPhoneType] = useState("");
  const [passType, setPassType] = useState("password");
  const [passType2, setPassType2] = useState("password");
  const history = useHistory();
  const [isDisable, setIsDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const validate = (values) => {
    const {
      name,

      phone,
      otp_code,
      password,
      confirmPassword,
      nid_photo,
    } = values;

    let errors = {};

    if (!name) {
      errors.name = "Please enter your full name";
    }

    if (!phone) {
      errors.phone = "Please enter your phone number";
    }

    if (!otp_code) {
      errors.otp_code = "Please enter your verification code";
    }
    if (!nid_photo) {
      errors.nid_photo = "Please enter your nid photo";
    }

    if (!password) {
      errors.password = "Please enter your password";
    } else if (password.length < 6) {
      errors.password = "Password at least 6 characters";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Please enter your confirmPassword";
    } else if (confirmPassword !== password) {
      errors.confirmPassword = "Password not matched";
    }

    return errors;
  };
  const onSubmit = (values) => {
    const { name, phone, otp_code, password, nid_photo } = values;

    //let registerData;

    const formData = new FormData();

    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("verificationCode", otp_code);
    formData.append("password", password);
    formData.append("nid_photo", nid_photo);

    fetch("/api/delivery-man/register", {
      body: formData,
      method: "POST",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.success) {
          history.push("/delivery-man/login");
          return swal("", result.success, "success");
        } else {
          return swal("", result.error, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        return swal("", "Something went wrong", "error");
      });
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  const verificationCodeHandler = async () => {
    let only_phone_number = 0;
    setLoading(true);
    const { phone } = formik.values;
    if (!phone) {
      return swal("", "Please provide your phone number", "error");
    }
    if (phone) {
      // regualr expression checking to remove country code and extra spaces and dash
      only_phone_number = phone.replace(/\D/g, '').slice(-11);
      console.log(only_phone_number);
      if (only_phone_number.length !== 11) {
        setLoading(false);
        return swal("", "Please provide valid phone number", "error");
      }
    }
    let verifyData;
    verifyData = {
      value: phone,
      registerBy: "phone",
    };
    await fetch("/api/verify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(verifyData),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          setLoading(false);
          setIsDisable(true)
          wait3min();
          return swal("", result.success, "success");
        } else {
          setLoading(false);
          return swal("", result.error, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return swal("", "Something went wrong", "error");
      });
  };

  //count down timer for verficiation code resend...
  const wait3min = () => {
    setTimeout(() => { setIsDisable(false) }, 180000);
  }


  return (
    <div className={Styles.main__div}>
      <Container className="mt-4 py-2">
        <Row>
          <Col className="mx-auto" md={8}>
            <Paper className="shadow">
              <Row>
                {/* <Col md={6}>
                  <div className={Styles.left__style}>
                    <img src={loginImg} alt="" />
                    <div className={Styles.overlay__style}>
                      <div className={Styles.content__style}>
                        <h4>HOMEMADE </h4>
                        <h1>FOOD</h1>
                        <h6>AT YOUR DOORSTEP</h6>
                      </div>
                    </div>
                  </div>
                </Col> */}
                <Col className="px-5 pb-4">
                  <img
                    className={Styles.myPlate__logo__style}
                    src={plateLogo}
                    alt=""
                  />
                  <h4 className="text-center py-4" style={{ color: "#372B2B" }}>
                    Registration
                  </h4>
                  <p className="mt-2">
                    Already have an account ?
                    <Link
                      className={Styles.registerLink__style}
                      to="/delivery-man/login"
                    >
                      Login
                    </Link>
                  </p>
                  <Form onSubmit={formik.handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Fullname *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Please enter your fullname"
                        name="name"
                        {...formik.getFieldProps("name")}
                      />
                      <small style={{ color: "red" }}>
                        {formik.touched.name &&
                          formik.errors.name &&
                          formik.errors.name}
                      </small>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Phone Number *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Please enter your phone number "
                        name="phone"
                        {...formik.getFieldProps("phone")}
                      />
                      <small style={{ color: "red" }}>
                        {formik.touched.phone &&
                          formik.errors.phone &&
                          formik.errors.phone}
                      </small>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Verification code *</Form.Label> <br />
                      <LoadingButton
                        onClick={verificationCodeHandler}
                        endIcon={<SendIcon />}
                        disabled={isDisable}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        style={{
                          backgroundColor: '#958470',
                          marginBottom: '10px',
                          '&:hover': {
                            backgroundColor: '#372B2B',
                            color: 'white',
                          }
                        }}
                      >
                        Send Code
                      </LoadingButton>
                      {isDisable && <p style={{ color: '#958470' }}>plz wait atleast 3 mins before resending the code</p>}
                      <Form.Control
                        className={Styles.sendCodeInputField__style}
                        type="text"
                        placeholder="Please enter your verification code"
                        name="otp_code"
                        {...formik.getFieldProps("otp_code")}
                      />
                      {/* <button
                          type="button"
                          onClick={verificationCodeHandler}
                          className={Styles.sendCodeButton__Style}
                        >
                          Send code
                        </button> */}

                      <small style={{ color: "red" }}>
                        {formik.touched.otp_code &&
                          formik.errors.otp_code &&
                          formik.errors.otp_code}
                      </small>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Password *</Form.Label> <br />
                      <div className={Styles.passwordField__Style}>
                        <input
                          type={passType}
                          placeholder="Please enter your password"
                          name="password"
                          {...formik.getFieldProps("password")}
                        />
                        {passType === "password" ? (
                          <AiOutlineEyeInvisible
                            onClick={() => setPassType("text")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        ) : (
                          <AiOutlineEye
                            onClick={() => setPassType("password")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        )}
                      </div>
                      <small style={{ color: "red" }}>
                        {formik.touched.password &&
                          formik.errors.password &&
                          formik.errors.password}
                      </small>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Confirm Password *</Form.Label> <br />
                      <div className={Styles.passwordField__Style}>
                        <input
                          type={passType2}
                          placeholder="Please enter your confirm password"
                          name="confirmPassword"
                          {...formik.getFieldProps("confirmPassword")}
                          s
                        />
                        {passType2 === "password" ? (
                          <AiOutlineEyeInvisible
                            onClick={() => setPassType2("text")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        ) : (
                          <AiOutlineEye
                            onClick={() => setPassType2("password")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        )}
                      </div>
                      <small style={{ color: "red" }}>
                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword &&
                          formik.errors.confirmPassword}
                      </small>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Nid Photo *</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="Please enter your nid photo"
                        name="nid_photo"
                        onChange={(e) => {
                          formik.setFieldValue("nid_photo", e.target.files[0]);
                        }}
                      />
                      <small style={{ color: "red" }}>
                        {formik.touched.nid_photo &&
                          formik.errors.nid_photo &&
                          formik.errors.nid_photo}
                      </small>
                    </Form.Group>

                    <Form.Group
                      className="mb-3 d-flex align-items-center justify-content-between"
                      controlId="formBasicCheckbox"
                    >
                      <Form.Check
                        required
                        style={{ fontSize: "14px" }}
                        type="checkbox"
                        label="I agree to all the Terms and Privacy Policy"
                      />
                    </Form.Group>

                    <Button
                      style={{
                        width: "100%",
                        color: "white",
                        backgroundColor: "#372B2B",
                        marginBottom: "50px",
                      }}
                      variant="contained"
                      type="submit"
                    >
                      Sign in
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Paper>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DeliveryManRegisterComp;

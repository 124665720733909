//import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
//import Styles from "./UserDetailsComp.module.css";
import avatarImg from "../../assets/Images/PlaceHolderImages/img_avatar.png";
import PlateCard from "./../PlateCard/PlateCard";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetch_get_profile_info } from "../../Redux/profile/profileAction";
import { useParams } from "react-router-dom";
import Loading from "./../../utils/Loading";
import Header from "./Header/Header";
import UserProfile from "./UserProfile/UserProfile";
import { fetch_get_user_plates } from "../../Redux/MyPlates/myPlatesAction";
import swal from "sweetalert";
import IconButton from "@mui/material/IconButton";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";

const UserDetailsComp = () => {
  const { userId } = useParams();
  const { profileInfo } = useSelector((state) => state.profile);
  const { user_plates } = useSelector((state) => state.myPlates);
  const dispatch = useDispatch();
  const [headerInfo, setHeaderInfo] = useState("Profile");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  // copy to clipboard handler
  const copyToClipBoard = (msg) => {
    navigator.clipboard.writeText(msg);
    setOpenSnackbar(true);
  };

  // snackbar transitions
  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  useEffect(() => {
    dispatch(fetch_get_profile_info(userId));
    dispatch(fetch_get_user_plates(userId));
  }, [userId, headerInfo]);

  if (!profileInfo || !user_plates) {
    return (
      <div className="text-center">
        <Loading />
      </div>
    );
  }
  const { name, email, plateInfo, profile_image, total_plates, phone } =
    profileInfo;

  const { userPlates } = user_plates;

  //console.log(plateInfo);

  return (
    <div>
      <Header
        userId={userId}
        headerInfo={headerInfo}
        setHeaderInfo={setHeaderInfo}
      />
      <Container>
        {headerInfo && headerInfo == "Profile" && (
          <Row>
            <Col className="mx-auto" md={8}>
              <UserProfile
                userPlates={userPlates}
                email={email}
                profile_image={profile_image}
                name={name}
                avatarImg={avatarImg}
                total_plates={total_plates}
              />
            </Col>
          </Row>
        )}

        {headerInfo && headerInfo == "Posts" && (
          <Row>
            {plateInfo?.map((item, index) => {
              return (
                <Col className="mx-auto py-2" key={index} md={8}>
                  <PlateCard item={item} is_profile={true} />
                </Col>
              );
            })}
          </Row>
        )}

        {headerInfo && headerInfo == "Plates" && (
          <>
            <Table className="text-center" responsive striped bordered hover>
              <thead>
                <tr>
                  <th>Plate Name</th>
                  <th>Image</th>
                  <th>Price</th>
                  <th>Order</th>
                </tr>
              </thead>
              <tbody>
                {user_plates?.userPlates?.map((item) => {
                  return (
                    <tr>
                      <td>{item.plate_name}</td>
                      <td>
                        <img
                          style={{ width: "100px", height: "50px" }}
                          src={item.plate_image}
                          alt=""
                        />
                      </td>
                      <td>{item.plate_price} Taka</td>
                      <td>
                        {" "}
                        <IconButton
                          aria-label="order the plate"
                          onClick={() => {
                            swal(
                              `Welcome to ${name}'s kitchen`,
                              `call ${phone} to order...`,
                              {
                                buttons: {
                                  Copy: "Copy",
                                  OK: true,
                                },
                              }
                            ).then((value) => {
                              switch (value) {
                                case "Copy":
                                  copyToClipBoard(phone);
                                  break;
                                default:
                                  setOpenSnackbar(false);
                                  break;
                              }
                            });
                          }}
                        >
                          <LocalGroceryStoreOutlinedIcon />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
      </Container>
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        TransitionComponent={TransitionUp}
        message="Phone Number Copied..."
      />
    </div>
  );
};

export default UserDetailsComp;

import React from "react";
import ViewUsers from "../../Components/AdminComps/Users/ViewUsers/ViewUsers";

const ViewUserListPage = () => {
  return (
    <div>
      <ViewUsers />
    </div>
  );
};

export default ViewUserListPage;

import React, { useEffect } from "react";
import { Container, Form, Alert } from "react-bootstrap";
import { Paper } from "@mui/material";
import { useFormik } from "formik";
import Axios from "axios";
import swal from "sweetalert";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../utils/Loading";
import { fetch_get_single_deliveryMethod } from "../../../../Redux/DeliveryMethod/DeliveryMethodActions";

const initialValues = {
  delivery_method: "",
  delivery_charge: "",
};

const UpdateDeliveryMethod = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { methodId } = useParams();
  const { single_deliveryMethod } = useSelector(
    (state) => state.deliveryMethod
  );

  useEffect(() => {
    dispatch(fetch_get_single_deliveryMethod(methodId));
  }, [methodId]);

  const validate = (values) => {
    const { delivery_method, delivery_charge } = values;

    let errors = {};

    if (!delivery_method) {
      errors.delivery_method = "Please enter a delivery method";
    }
    if (!delivery_charge) {
      errors.delivery_charge = "Please enter delivery charge";
    }

    return errors;
  };
  const onSubmit = (values) => {
    const { delivery_method, delivery_charge } = values;
    const data = { delivery_method, delivery_charge };

    Axios.put(`/api/update-delivery-charge/${methodId}`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
      },
    })
      .then((result) => {
        if (result.data.success) {
          history.push("/admin/view-delivery-methods");
          return swal("", result.data.success, "success");
        } else {
          return swal("", result.data.error, "error");
        }
      })
      .catch((err) => {
        console.log(err.response.data.error);
        return swal("", err.response.data.error, "error");
      });
  };
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });
  if (single_deliveryMethod) {
    const { delivery_method, delivery_charge } = single_deliveryMethod;
    formik.initialValues.delivery_method = delivery_method;
    formik.initialValues.delivery_charge = delivery_charge;
  }

  if (!single_deliveryMethod) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (single_deliveryMethod?.error) {
    return (
      <Alert variant="danger" className="text-center">
        {single_deliveryMethod?.error}
      </Alert>
    );
  }

  return (
    <Container>
      <h4 className="text-center py-3">Update Delivery Method</h4>
      <Paper className="p-4 shadow">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Delivery Method Name *</Form.Label>
            <Form.Control
              type="text"
              placeholder="Please enter delivery method"
              nam="delivery_method"
              {...formik.getFieldProps("delivery_method")}
            />
            <Form.Text style={{ color: "red" }}>
              {formik.touched.delivery_method &&
                formik.errors.delivery_method &&
                formik.errors.delivery_method}
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Delivery Charge *</Form.Label>
            <Form.Control
              type="number"
              min="0"
              placeholder="Please enter delivery charge"
              nam="delivery_charge"
              {...formik.getFieldProps("delivery_charge")}
            />
            <Form.Text style={{ color: "red" }}>
              {formik.touched.delivery_charge &&
                formik.errors.delivery_charge &&
                formik.errors.delivery_charge}
            </Form.Text>
          </Form.Group>

          <button className="common_button_Style" type="submit">
            Update Delivery Method
          </button>
        </Form>
      </Paper>
    </Container>
  );
};

export default UpdateDeliveryMethod;

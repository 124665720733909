import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
//import { BiSearchAlt2 } from "react-icons/bi";
import PlateCard from "../PlateCard/PlateCard";
import Styles from "./HomeComps.module.css";
//import { useDispatch, useSelector } from "react-redux";
// import {
//   fetch_get_all_plates,
//   fetch_get_paginate_plates,
//   fetch_get_filter_plates,
// } from "../../Redux/Plates/plateActions";
// import Loading from "./../../utils/Loading";
import AOS from "aos";
import CardSkeleton from "../../utils/CardSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import PostEndCard from "../../utils/PostEndCard";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
const HomeComps = () => {
  //  const dispatch = useDispatch();
  const [plateData, setPlateData] = useState([]);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [category, setCategory] = useState("ALL");
  AOS.init();
  const [searchInput, setSearchInput] = useState("");
  // const [search, setSearch] = useState(false);

  //const { all_plates, paginate_plates } = useSelector((state) => state.plate);

  //fetching the paginated data for the first time
  useEffect(() => {
    const getPosts = async () => {
      const res = await fetch(`/api/all-plates/ALL/1`);
      const data = await res.json();
      // console.log(data.all_posts);
      setPlateData(data.all_posts);
    };
    getPosts();
  }, []);

  // fetch paginated data on demand
  const fetchPosts = async () => {
    const res = await fetch(`/api/all-plates/${category}/${page}`);
    const data = await res.json();
    return data.all_posts;
  };

  //fetch data from server function
  const fetchData = async () => {
    const postsFormServer = await fetchPosts();

    setPlateData([...plateData, ...postsFormServer]);
    if (postsFormServer.length === 0) {
      setHasMore(false);
    }
    setPage(page + 1);
  };

  //filter button data
  const plateCategoryFilterHandler = async (ctg) => {
    setHasMore(true);
    setSearchInput("");
    setCategory(ctg);
    if (ctg == "ALL") {
      setPlateData("");
      setCategory("ALL");
    }

    const getPosts = async () => {
      const res = await fetch(`/api/all-plates/${ctg}/1`);
      const data = await res.json();
      setPlateData(data.all_posts);
    };
    getPosts();
  };

  //search box
  const handleSearch = async () => {
    setPlateData([]);
    const getSearchedPosts = async () => {
      const res = await fetch(`/api/all-plates/all/${searchInput}/1`);
      const data = await res.json();
      // console.log(data.all_posts);
      setPlateData(data.all_posts);
    };
    getSearchedPosts();
    // console.log("searching for " + searchInput);
  };

  if (!plateData) {
    return (
      <div className={Styles.background__style}>
        <CardSkeleton />
        {/* <Loading /> */}
      </div>
    );
  }

  return (
    <InfiniteScroll
      className={Styles.background__style}
      dataLength={plateData.length} //This is important field to render the next data
      next={fetchData}
      hasMore={hasMore}
      loader={<CardSkeleton />}
      endMessage={<PostEndCard />}
    >
      <Container>
        <Row>
          <Col className="mx-auto my-3" md={7}>
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search My Plate"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && handleSearch()}
              />
            </Paper>
            <div className={Styles.filters__style}>
              <button
                onClick={() => plateCategoryFilterHandler("ALL")}
                className={Styles.button__Style}
              >
                All
              </button>
              <button
                onClick={() => plateCategoryFilterHandler("Breakfast")}
                className={Styles.button__Style}
              >
                Breakfast
              </button>
              <button
                onClick={() => plateCategoryFilterHandler("Lunch")}
                className={Styles.button__Style}
              >
                Lunch
              </button>
              <button
                onClick={() => plateCategoryFilterHandler("Dinner")}
                className={Styles.button__Style}
              >
                Dinner
              </button>

              <button
                onClick={() => plateCategoryFilterHandler("Side Dish")}
                className={Styles.button__Style}
              >
                Side Dish
              </button>
              <button
                onClick={() => plateCategoryFilterHandler("Frozen Food")}
                className={Styles.button__Style}
              >
                Frozen Food
              </button>
            </div>
          </Col>
        </Row>

        {plateData?.map((item, index) => {
          return (
            <Col
              key={index}
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              className="py-2 mx-auto"
              md={8}
            >
              {" "}
              <PlateCard key={index} item={item} />
            </Col>
          );
        })}
      </Container>
    </InfiniteScroll>
  );
};

export default HomeComps;

import React from "react";
import ConfirmOrderComp from "../../Components/ConfirmOrderComp/ConfirmOrderComp";

const ConfirmOrderPage = () => {
  return (
    <div>
      <ConfirmOrderComp />
    </div>
  );
};

export default ConfirmOrderPage;

import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";

const CommonViewTitle = ({ title, btnText, url }) => {
  const history = useHistory();
  return (
    <div className="d-flex align-items-center justify-content-between py-4">
      <h4>{title}</h4>
      <button onClick={() => history.push(url)} className="common_button_Style">
        <AddIcon style={{ color: "white" }} />
        {btnText}
      </button>
    </div>
  );
};

export default CommonViewTitle;

import React from "react";
import ViewAdmins from "../../Components/AdminComps/AdminComps/ViewAdmins/ViewAdmins";

const ViewAdminsPage = () => {
  return (
    <div>
      <ViewAdmins />
    </div>
  );
};

export default ViewAdminsPage;

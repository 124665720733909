import React from "react";
import { NavLink, useParams } from "react-router-dom";
import Styles from "./Header.module.css";

const Header = () => {
  const { id } = useParams();
  return (
    <div>
      <div className={Styles.main__header}>
        {/* <NavLink
          exact
          activeClassName={Styles.navActive__style}
          className={Styles.navitem__style}
          to="/profile"
        >
          Profile
        </NavLink> */}
        <NavLink
          exact
          activeClassName={Styles.navActive__style}
          className={Styles.navitem__style}
          to="/host"
        >
          Host
        </NavLink>
        <NavLink
          exact
          activeClassName={Styles.navActive__style}
          className={Styles.navitem__style}
          to="/my-posts"
        >
          My Posts
        </NavLink>
        <NavLink
          exact
          activeClassName={Styles.navActive__style}
          className={Styles.navitem__style}
          to="/my-plates"
        >
          My Plates
        </NavLink>

        <NavLink
          exact
          activeClassName={Styles.navActive__style}
          className={Styles.navitem__style}
          to="/my-orders"
        >
          My Orders
        </NavLink>
        <NavLink
          exact
          activeClassName={Styles.navActive__style}
          className={Styles.navitem__style}
          to="/customer-orders"
        >
          Customer Orders
        </NavLink>
        <NavLink
          exact
          activeClassName={Styles.navActive__style}
          className={Styles.navitem__style}
          to="/my-delivery"
        >
          Delivery
        </NavLink>
      </div>
    </div>
  );
};

export default Header;

import React from "react";
import ViewDeliveryMethods from "../../Components/AdminComps/DeliveryMethodComps/ViewDeliveryMethods/ViewDeliveryMethods";

const ViewDeliveryMethodsPage = () => {
  return (
    <div>
      <ViewDeliveryMethods />
    </div>
  );
};

export default ViewDeliveryMethodsPage;

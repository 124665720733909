import React from 'react';
import MyPlates from '../../../Components/UserProfileComps/MyPlates/MyPlates';

const MyPlatesPage = () => {
    return (
        <div>
            <MyPlates/>
        </div>
    );
};

export default MyPlatesPage;
import {
  GET_ALL_DELIVERY_METHODS,
  GET_SINGLE_DELIVERY_METHOD,
} from "./DeliveryMethodTypes";

const init = {
  all_deliveryMethods: "",
  single_deliveryMethod: "",
};

export const deliveryMethodReducer = (state = init, action) => {
  switch (action.type) {
    case GET_ALL_DELIVERY_METHODS:
      return {
        ...state,
        all_deliveryMethods: action.payload,
      };
    case GET_SINGLE_DELIVERY_METHOD:
      return {
        ...state,
        single_deliveryMethod: action.payload,
      };

    default:
      return state;
  }
};

import React, { useState } from "react";
import { Col, Container, Form } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Styles from "./LoginComp.module.css";
import { Paper, Button } from "@material-ui/core";
import loginImg from "../../assets/Images/Login/Rectangle 11 (1).png";
import fbLogo from "../../assets/Images/Login/fb.svg";
import googleLogo from "../../assets/Images/Login/google.svg";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useFormik } from "formik";
import swal from "sweetalert";
import { useHistory } from "react-router";
import plateLogo from "../../assets/Images/Login/plateLogo.svg";
import { useSpring, animated } from "react-spring";

const initialValues = {
  username: "",
  password: "",
};

const LoginComp = () => {
  const [passType, setPassType] = useState("password");
  const history = useHistory();

  const validate = (values) => {
    const { username, password } = values;

    let errors = {};

    if (!username) {
      errors.username = "Please enter your phone number or email";
    }

    if (!password) {
      errors.password = "Please enter your password";
    }

    return errors;
  };

  const onSubmit = (values) => {
    const { password, username } = values;

    let registerData;

    fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.error) {
          return swal("", result.error, "error");
        } else if (result.token) {
          localStorage.setItem("user_token", result.token);
          return history.push("/");
        } else {
          return swal("", "Something went wrong", "error");
        }
      })
      .catch((err) => {
        return swal("", "Something went wrong", "error");
      });
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  //react-spring animation props
  const props = useSpring({
    to: { x: 0 },
    from: { x: -80 },
    config: { duration: 750 },
  });

  return (
    <div className={Styles.main__div}>
      <Container className="mt-4 py-2">
        <Row>
          <Col className="mx-auto" md={10}>
            <Paper className="shadow">
              <Row>
                <Col md={6}>
                  <div className={Styles.left__style}>
                    <img src={loginImg} alt="" />
                    <div className={Styles.overlay__style}>
                      <div className={Styles.content__style}>
                        <img
                          style={{ width: "130px", height: "130px" }}
                          src={plateLogo}
                          alt=""
                        />
                        {/* react-spring animated div */}
                        <animated.div style={props}>
                          <h4>HOMEMADE </h4>
                          <h1>FOOD</h1>
                          <h6>AT YOUR DOORSTEP</h6>
                        </animated.div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="px-5 pb-4" md={6}>
                  <div className="text-center">
                    <img
                      className={Styles.myPlate__logo__style}
                      src={plateLogo}
                      alt=""
                    />
                  </div>

                  <h4 className="text-center py-4" style={{ color: "#372B2B" }}>
                    Log in!
                  </h4>
                  <Form onSubmit={formik.handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Phone or Email <span style={{ color: "red" }}>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Please enter your phone number or email"
                        name="username"
                        {...formik.getFieldProps("username")}
                      />
                      <small style={{ color: "red" }}>
                        {formik.touched.username &&
                          formik.errors.username &&
                          formik.errors.username}
                      </small>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Password <span style={{ color: "red" }}>*</span></Form.Label> <br />
                      <div className={Styles.passwordField__Style}>
                        <input
                          type={passType}
                          placeholder="Please enter your password"
                          name="password"
                          {...formik.getFieldProps("password")}
                        />
                        {passType === "password" ? (
                          <AiOutlineEyeInvisible
                            onClick={() => setPassType("text")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        ) : (
                          <AiOutlineEye
                            onClick={() => setPassType("password")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        )}
                      </div>
                      <small style={{ color: "red" }}>
                        {formik.touched.password &&
                          formik.errors.password &&
                          formik.errors.password}
                      </small>
                    </Form.Group>

                    <Button
                      style={{
                        width: "100%",
                        color: "white",
                        backgroundColor: "#372B2B",
                        margin: "10px 0px",
                      }}
                      variant="contained"
                      type="submit"
                    >
                      Log in
                    </Button>
                    <Link className={Styles.link__style} to="/forgot-password">
                      Forgot password?
                    </Link>
                    {/* <div className={Styles.or__style}>OR</div> */}
                  </Form>
                  {/* <Row>
                    <div style={{ fontSize: "14px" }}>
                      <Button
                        style={{ fontSize: "14px" }}
                        className={Styles.authButton__Style}
                      >
                        <img
                          style={{ marginRight: "6px" }}
                          src={fbLogo}
                          alt=""
                        />
                        Join with facebook
                      </Button>
                    </div>
                    <div>
                      <Button className={Styles.authButton__Style}>
                        <img
                          style={{ marginRight: "16px" }}
                          src={googleLogo}
                          alt=""
                        />
                        Join with google
                      </Button>
                    </div>
                  </Row> */}
                  <p className="mt-2">
                    {" "}
                    Don't have an account?{" "}
                    <Link className={Styles.registerLink__style} to="/register">
                      Register
                    </Link>
                    {/* <div>
                      <Link
                        className={Styles.registerLink__style}
                        to="/delivery-man/login"
                      >
                        My Plate Delivery
                      </Link>
                    </div> */}
                  </p>
                </Col>
              </Row>
            </Paper>
          </Col>
        </Row>
        <div className={Styles.downLinksDiv__style}>
          <Link className={Styles.downLinks__style} to="/about-us">
            About us
          </Link>
          <Link className={Styles.downLinks__style} to="/terms">
            Terms
          </Link>

          <Link className={Styles.downLinks__style} to="/delivery-man/login">
            My Plate Delivery
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default LoginComp;

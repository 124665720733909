import React from "react";
import ForgotPassComp from "../../Components/ForgotPassComp/ForgotPassComp";

const ForgotPassPage = () => {
  return (
    <div>
      <ForgotPassComp />
    </div>
  );
};

export default ForgotPassPage;

import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { fetch_delete_post } from "../Redux/Plates/plateActions";

const DeleteModal = (props) => {
  const dispatch = useDispatch();

  const deleteItemHandler = () => {
    if (props.plateId) {
      dispatch(fetch_delete_post(props.plateId));
      props.onHide();
    }
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-4">
        <b>Are your sure want to delete this {props.deleteText}</b> <br />
        <br />
        <button
          style={{ marginRight: "10px" }}
          onClick={deleteItemHandler}
          className="common_button_Style"
          type="submit"
        >
          Confirm
        </button>
        <Button
          onClick={() => {
            props.onHide();
          }}
          className="btn btn-danger"
        >
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;

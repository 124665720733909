import React, { useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import DeliveryManHeadersComp from "./../DeliveryManHeaderComp/DeliveryManHeadersComp";
import OrderCard from "./OrderCard/OrderCard";
import { useDispatch, useSelector } from "react-redux";
import { fetch_get_upcoming_orders } from "../../../Redux/DeliveryMan/DeliveryManActions";
import Loading from "./../../../utils/Loading";

const DeliveryManOrders = () => {
  const dispatch = useDispatch();
  const { upcoming_orders } = useSelector((state) => state.deliveryMan);

  useEffect(() => {
    dispatch(fetch_get_upcoming_orders());
  }, [dispatch]);

  if (!upcoming_orders) {
    return (
      <div className="text-center">
        <Loading />
      </div>
    );
  }

  if (upcoming_orders.error) {
    return (
      <Container className="text-center">
        <Alert className="alert alert-danger text-center">
          {upcoming_orders.error}
        </Alert>
      </Container>
    );
  }
  return (
    <Container>
      <DeliveryManHeadersComp />
      <Row>
        <h4 className="text-center py-3">
          Orders Remaining ({upcoming_orders?.length})
        </h4>
        {upcoming_orders?.map((item, index) => {
          return (
            <Col key={index} md={4} className="py-2">
              <OrderCard item={item} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default DeliveryManOrders;

import React, { useEffect } from "react";
import { Alert, Container, Table } from "react-bootstrap";
import CommonViewTitle from "../../../../utils/CommonViewTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  fetch_get_user_lists,
  delete_user_action,
  block_user_action,
  unblock_user_action,
  add_certification_action,
  edit_host_quota_action,
} from "../../../../Redux/Admin/AdminActions";
import Loading from "./../../../../utils/Loading";
import { MdDeleteOutline } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VerifiedIcon from "@mui/icons-material/Verified";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { brown } from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
const ViewUsers = () => {
  const dispatch = useDispatch();
  const { userList } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(fetch_get_user_lists());
  }, []);
  //console.log(userList);
  const [openBlockDialog, setOpenBlockDialog] = React.useState(false);
  const [openCertificationDialog, setOpenCertificationDialog] =
    React.useState(false);
  const [openCertificationEditDialog, setOpenCertificationEditDialog] =
    React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [userId, setUserId] = React.useState(false);
  const [hostQuota, setHostQuota] = React.useState(0);

  //user block handlers
  const handleClickOpenBlockDialog = (userId) => {
    setOpenBlockDialog(true);
    setUserId(userId);
  };

  const handleCloseBlockDialog = () => {
    setOpenBlockDialog(false);
    setUserId(false);
  };

  const blockHandler = () => {
    if (userId) {
      dispatch(block_user_action(userId));
      handleCloseBlockDialog();
    }
  };

  const unblockHandler = (user_id) => {
    dispatch(unblock_user_action(user_id));
  };

  // add certification handlers
  const handleClickOpenCertificationDialog = (userId) => {
    setOpenCertificationDialog(true);
    setUserId(userId);
  };

  const handleCloseCertificationDialog = () => {
    setOpenCertificationDialog(false);
    setUserId(false);
  };

  const certificationHandler = () => {
    if (userId) {
      dispatch(add_certification_action(userId));
      handleCloseCertificationDialog();
    }
  };
  // edit certification handlers
  const handleClickOpenCertificationEditDialog = (userId, quota) => {
    setOpenCertificationEditDialog(true);
    setUserId(userId);
    setHostQuota(quota);
  };

  const handleCloseCertificationEditDialog = () => {
    setOpenCertificationEditDialog(false);
    setUserId(false);
    setHostQuota(0);
  };

  const editCertificationHandler = () => {
    dispatch(edit_host_quota_action(userId, hostQuota));
    handleCloseCertificationEditDialog();
  };

  // delete handlers
  const handleClickOpenDeleteDialog = (userId) => {
    setOpenDeleteDialog(true);
    setUserId(userId);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setUserId(false);
  };

  const deleteHandler = () => {
    if (userId) {
      dispatch(delete_user_action(userId));
      handleCloseDeleteDialog();
    }
  };
  if (!userList) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (userList?.error) {
    return (
      <Alert variant="danger" className="text-center">
        {userList?.error}
      </Alert>
    );
  }

  return (
    <div>
      <Container>
        <CommonViewTitle
          title="Users"
          btnText="Add User"
          onClick={() => alert("Coming Soon..!")}
        />
        <Table bordered hover responsive className="text-center">
          <thead>
            <tr>
              <th>Name</th>
              {/* <th>Username</th> */}
              <th>Email</th>
              <th>Phone</th>
              <th>Promo</th>
              <th>Clients</th>
              <th>Quota</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {userList?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.name}</td>
                  {/* <td>{item.username}</td> */}
                  <td>{item?.email}</td>
                  <td>{item?.phone}</td>
                  <td>{item.myPromoCode}</td>
                  <td>{Object.keys(item.myRefClients).length}</td>
                  <td>{item.myQuota}</td>
                  <td>
                    {item.myCertification ? (
                      <Tooltip title="Certified User">
                        <IconButton>
                          <VerifiedIcon
                            sx={{ color: brown[500] }}
                            fontSize="small"
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add Certification">
                        <IconButton
                          onClick={() =>
                            handleClickOpenCertificationDialog(
                              item._id,
                              item.myQuota
                            )
                          }
                        >
                          <VerifiedOutlinedIcon
                            className="delete_icon_Style"
                            sx={{ color: brown[500] }}
                            fontSize="small"
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Edit Quata">
                      <IconButton //edit quota button
                        onClick={() =>
                          handleClickOpenCertificationEditDialog(
                            item._id,
                            item.myQuota
                          )
                        }
                      >
                        <BiEdit className="edit_icon_Style" />
                      </IconButton>
                    </Tooltip>
                    {item.status === "blocked" ? (
                      <Tooltip title="Blocked User">
                        <IconButton onClick={() => unblockHandler(item._id)}>
                          <LockOutlinedIcon
                            className="delete_icon_Style"
                            fontSize="small"
                            color="disabled"
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Unblocked User">
                        <IconButton
                          onClick={() => handleClickOpenBlockDialog(item._id)}
                        >
                          <LockOpenOutlinedIcon
                            className="delete_icon_Style"
                            fontSize="small"
                            color="success"
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleClickOpenDeleteDialog(item._id)}
                      >
                        <MdDeleteOutline className="delete_icon_Style" />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>

      {/* block dialog */}
      <Dialog
        open={openBlockDialog}
        onClose={handleCloseBlockDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Block this active user?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are going to block this active user. Blocking this user,the user
            will no longer available to post, report, like, and host a plate.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBlockDialog} autoFocus>
            Cancel
          </Button>
          <Button onClick={blockHandler}>Block</Button>
        </DialogActions>
      </Dialog>

      {/* delete dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this  user?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are going to delete this user. Deleting this user,the user will
            no longer available in my plate system.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} autoFocus>
            Cancel
          </Button>
          <Button onClick={deleteHandler}>Delete</Button>
        </DialogActions>
      </Dialog>

      {/* certification dialog */}
      <Dialog
        open={openCertificationDialog}
        onClose={handleCloseCertificationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Certified this Host?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure? You can't undo this action...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCertificationDialog} autoFocus>
            Cancel
          </Button>
          <Button onClick={certificationHandler}>Certify</Button>
        </DialogActions>
      </Dialog>

      {/* certification edit dialog */}
      <Dialog
        open={openCertificationEditDialog}
        onClose={handleCloseCertificationEditDialog}
      >
        <DialogTitle>QUOTA UPDATE</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are going to update the quota of this user. Before updaing
            please make sure that the host is certified.
          </DialogContentText>
          <br></br>
          <TextField
            id="outlined-number"
            label="Host Quota"
            type="number"
            value={hostQuota}
            onChange={(e) => setHostQuota(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCertificationEditDialog}>Cancel</Button>
          <Button onClick={editCertificationHandler}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewUsers;

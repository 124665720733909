import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../../assets/Images/Header/logo.svg";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const HeaderAdmin = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div>
      <div className={classes.root}>
        <AppBar position="static" style={{ backgroundColor: "#372B2B" }}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Typography
            style={{cursor:"pointer"}}
              onClick={() => {
                history.push("/");
              }}
              variant="h6"
              className={classes.title}
            >
              {/* <img src={logo} /> */}
              My Plate
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
};

export default HeaderAdmin;

import React from "react";
import { Paper } from "@material-ui/core";
import Styles from "./DeliveryCard.module.css";
import { Col, Row } from "react-bootstrap";
import { fetch_complete_my_delivery } from "../../../../Redux/Order/OrderActions";
import { useDispatch } from "react-redux";

const DeliveryCard = ({ item }) => {
  const dispatch = useDispatch();
  const {
    delivery_address,
    total_price,
    plate_info,
    last_date_to_order,
    last_time_to_order,
    order_id,
    order_user,
  } = item;

  // console.log(item);

  const completeMyDeliveryHandler = (orderId) => {
    dispatch(fetch_complete_my_delivery(orderId));
  };
  return (
    <div>
      <div>
        <Paper className="p-4 shadow ">
          <h3 className="text-center">Delivery Information</h3>
          <hr />
          <Row className="mb-2">
            <Col>
              <b>OrderId: </b>
            </Col>
            <Col>
              <span>{order_id}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <b>Customer Name: </b>
            </Col>
            <Col>
              <span>{order_user.name}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <b>Phone Number: </b>
            </Col>
            <Col>
              <span>{order_user.phone}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <b>Location: </b>
            </Col>
            <Col>
              <span>
                {plate_info.location} to {delivery_address}
              </span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <b>Total Price : </b>
            </Col>
            <Col>
              <span>{total_price} BDT</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <b>Delivery Time : </b>
            </Col>
            <Col>
              <span> 12.30 pm</span>
            </Col>
          </Row>

          <button
            onClick={() => completeMyDeliveryHandler(item._id)}
            className={Styles.button__Style}
          >
            Complete The Delivery
          </button>
        </Paper>
      </div>
    </div>
  );
};

export default DeliveryCard;

import {
  GET_MY_PROFILE_INFO,
  GET_USER_PROFILE_INFO,
  HOST_ELIGIBILITY,
} from "./profileTypes";

const init = {
  profileInfo: "",
  my_profileInfo: "",
  host_eligibility: "",
};

export const profileReducer = (state = init, action) => {
  switch (action.type) {
    case GET_USER_PROFILE_INFO:
      return {
        ...state,
        profileInfo: action.payload,
      };
    case GET_MY_PROFILE_INFO:
      return {
        ...state,
        my_profileInfo: action.payload,
      };
    case HOST_ELIGIBILITY:
      return {
        ...state,
        host_eligibility: action.payload,
      };
    default:
      return state;
  }
};

import React, { useEffect } from "react";
import { Alert, Container, Table } from "react-bootstrap";
import CommonViewTitle from "../../../../utils/CommonViewTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  fetch_get_order_lists,
  fetch_get_user_lists,
} from "../../../../Redux/Admin/AdminActions";
import Loading from "./../../../../utils/Loading";
import { MdDeleteOutline } from "react-icons/md";
import { BiEdit } from "react-icons/bi";

const ViewOrders = () => {
  const dispatch = useDispatch();
  const { orderList } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(fetch_get_order_lists());
  }, []);

  if (!orderList) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (orderList?.error) {
    return (
      <Alert variant="danger" className="text-center">
        {orderList?.error}
      </Alert>
    );
  }

  return (
    <div>
      {" "}
      <Container>
        {/* <CommonViewTitle title="Orders" /> */}
        <h4 className="text-center py-3">Orders</h4>
        <Table bordered hover responsive className="text-center">
          <thead>
            <tr>
              <th>OrderId</th>
              <th>Plate Name</th>
              <th>Customer Name</th>
              <th>Customer Phone</th>
              <th>Delivery Address</th>
              <th>Host Name</th>
              <th>Host Email</th>
              <th>Host Phone</th>
              <th>Quantity</th>
              <th>Total Price</th>
              <th>Order Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {orderList?.map(
              (
                {
                  plate_info,
                  order_id,
                  item_quantity,
                  order_status,
                  total_price,
                  order_user,
                  host_info,
                  delivery_address,
                },
                index
              ) => {
                return (
                  <tr key={index}>
                    <td>{order_id}</td>
                    <td>{plate_info?.plate_name}</td>
                    <td>{order_user?.name}</td>
                    <td>{order_user?.phone}</td>
                    <td>{delivery_address}</td>
                    <td>{host_info?.name}</td>
                    <td>{host_info?.email}</td>
                    <td>{host_info?.phone}</td>
                    <td>{item_quantity}</td>
                    <td>{total_price}</td>
                    <td>{order_status}</td>

                    <td>
                      <BiEdit className="edit_icon_Style" />
                      <MdDeleteOutline className="delete_icon_Style" />
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default ViewOrders;

import React from "react";
import { Paper } from "@material-ui/core";
import Styles from "../Components/PlateCard/PlateCard.module.css";
function PostEndCard() {
  return (
    <div>
      <Paper className="p-4 shadow center"></Paper>
    </div>
  );
}

export default PostEndCard;

import { GET_MY_PLATES, GET_USER_PLATES } from "./myPlatesType";
import Axios from "axios";

export const get_my_plates = (plates) => {
  return {
    type: GET_MY_PLATES,
    payload: plates,
  };
};

export const get_user_plates = (plates) => {
  return {
    type: GET_USER_PLATES,
    payload: plates,
  };
};

export const fetch_get_my_plates = () => {
  return (dispatch) => {
    Axios.get("/api/get-plates", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        dispatch(get_my_plates(result.data));
      })
      .catch((err) => {
        dispatch(get_my_plates({ error: "Something went wrong" }));
      });
  };
};

export const fetch_get_user_plates = (userId) => {
  return (dispatch) => {
    Axios.get(`/api/get-user-plates/${userId}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        dispatch(get_user_plates(result.data));
      })
      .catch((err) => {
        dispatch(get_user_plates({ error: "Something went wrong" }));
      });
  };
};

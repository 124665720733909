import React from "react";
import ViewOrders from "../../Components/AdminComps/Orders/ViewOrders/ViewOrders";

const ViewOrdersPage = () => {
  return (
    <div>
      <ViewOrders />
    </div>
  );
};

export default ViewOrdersPage;

import React from "react";
import NewPassComp from "../../Components/NewPassComp/NewPassComp";

const NewPassPage = () => {
  return (
    <div>
      <NewPassComp />
    </div>
  );
};

export default NewPassPage;

import React from "react";
import MyDeliveryComp from "../../Components/UserProfileComps/MyDelivery/MyDeliveryComp";

const MyDeliveryPage = () => {
  return (
    <div>
      <MyDeliveryComp />
    </div>
  );
};

export default MyDeliveryPage;

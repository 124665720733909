import {
  GET_ADMIN_LIST,
  GET_COUNT_ITEMS,
  GET_ORDERS_LIST,
  GET_USER_LIST,
  GET_TUTORIAL_LIST,
  GET_DELIVERYMAN_INFOS,
  GET_REPORT_INFOS,
  GET_REPORT_DETAILS,
  GET_REPORTED_PLATE_INFO,
} from "./AdminTypes";

const init = {
  userList: "",
  adminList: "",
  orderList: "",
  count_items: "",
  tutorialList: "",
  deliveryManList: "",
  report_info: "",
  report_details: "",
  reported_plate_info:""
};

export const adminReducer = (state = init, action) => {
  switch (action.type) {
    case GET_USER_LIST:
      return {
        ...state,
        userList: action.payload,
      };
    case GET_ADMIN_LIST:
      return {
        ...state,
        adminList: action.payload,
      };
    case GET_ORDERS_LIST:
      return {
        ...state,
        orderList: action.payload,
      };
    case GET_COUNT_ITEMS:
      return {
        ...state,
        count_items: action.payload,
      };
    case GET_TUTORIAL_LIST:
      return {
        ...state,
        tutorialList: action.payload,
      };
    case GET_DELIVERYMAN_INFOS:
      return {
        ...state,
        deliveryManList: action.payload,
      };
    case GET_REPORT_INFOS:
      return {
        ...state,
        report_info: action.payload,
      };
    case GET_REPORT_DETAILS:
      return {
        ...state,
        report_details: action.payload,
      };
      case GET_REPORTED_PLATE_INFO: {
        return {
          ...state,
          reported_plate_info: action.payload,
        };
      }

    default:
      return state;
  }
};

import { plateReducer } from "./Plates/PlateReducer";
import { combineReducers } from "redux";
import { profileReducer } from "./profile/profileReducer";
import { myPlatesReducer } from "./MyPlates/myPlatesReducer";
import { orderReducer } from "./Order/OrderRedcuer";
import { deliveryManReducer } from "./DeliveryMan/DeliveryManReducer";
import { adminReducer } from "./Admin/AdminReducer";
import { deliveryMethodReducer } from "./DeliveryMethod/DeliveryMethodReducer";

export const rootReducer = combineReducers({
  plate: plateReducer,
  profile: profileReducer,
  myPlates: myPlatesReducer,
  order: orderReducer,
  deliveryMan: deliveryManReducer,
  admin: adminReducer,
  deliveryMethod: deliveryMethodReducer,
});

import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';


function SinglePlate({ plate_des, plate_images, plate_name, plate_price }) {
    return <div>
        <Typography component="div">
            <Box sx={{ letterSpacing: 6, m: 1 ,textAlign: 'center'}}>Reported Post</Box>
            <Divider />
        </Typography>
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 2, width: '45%' },
            }}
            noValidate
            autoComplete="off"
        >
            <TextField id="outlined-basic" label="Plate name" variant="outlined" value={plate_name} />
            <TextField id="outlined-basic" label="Plate Price" variant="outlined" value={plate_price} />

        </Box>
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 2, width: '93%' },
            }}
            noValidate
            autoComplete="off"
        >
            <TextField
                id="outlined-multiline-flexible"
                label="Plate Description"
                multiline
                maxRows={20}
                value={plate_des}
                fullWidth
            />

        </Box>


        <Box sx={{
            m: 2,
        }}>
            <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
                {plate_images.map((item, i) => (
                    <ImageListItem key={i}>
                        <img
                            src={`${item}?w=200&h=200&fit=crop&auto=format`}
                            srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            alt={'reported post image'}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    </div>;
}

export default SinglePlate;

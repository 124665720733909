import React from "react";
import CustomerOrderComp from "../../../Components/UserProfileComps/CustomerOrdersComp/CustomerOrderComp";

const CustomerOrdersPage = () => {
  return (
    <div>
      <CustomerOrderComp />
    </div>
  );
};

export default CustomerOrdersPage;

import React from "react";
import Styles from "./SidebarItems.module.css";
import { MdSpaceDashboard } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";

const SidebarItems = () => {
  const history = useHistory();
  return (
    <div>
      <NavLink
        exact
        className={Styles.row__Style}
        activeClassName={Styles.active_row__Style}
        to="/admin/dashboard"
      >
        <MdSpaceDashboard style={{ marginRight: "10px" }} />
        <span>Dashboard</span>
      </NavLink>
      <NavLink
        exact
        className={Styles.row__Style}
        activeClassName={Styles.active_row__Style}
        to="/admin/view-admins"
      >
        <MdSpaceDashboard style={{ marginRight: "10px" }} />
        <span>Admin</span>
      </NavLink>
      <NavLink
        exact
        className={Styles.row__Style}
        activeClassName={Styles.active_row__Style}
        to="/admin/view-users"
      >
        <MdSpaceDashboard style={{ marginRight: "10px" }} />
        <span>Users</span>
      </NavLink>
      <NavLink
        exact
        className={Styles.row__Style}
        activeClassName={Styles.active_row__Style}
        to="/admin/view-deliveryMans"
      >
        <MdSpaceDashboard style={{ marginRight: "10px" }} />
        <span>DeliveryMan</span>
      </NavLink>
      <NavLink
        exact
        className={Styles.row__Style}
        activeClassName={Styles.active_row__Style}
        to="/admin/view-orders"
      >
        <MdSpaceDashboard style={{ marginRight: "10px" }} />
        <span>Orders</span>
      </NavLink>
      <NavLink
        exact
        className={Styles.row__Style}
        activeClassName={Styles.active_row__Style}
        to="/admin/view-delivery-methods"
      >
        <MdSpaceDashboard style={{ marginRight: "10px" }} />
        <span>Delivery Methods</span>
      </NavLink>
      <NavLink
        exact
        className={Styles.row__Style}
        activeClassName={Styles.active_row__Style}
        to="/admin/view-tutorials"
      >
        <MdSpaceDashboard style={{ marginRight: "10px" }} />
        <span>Tutorials</span>
      </NavLink>
      <NavLink
        exact
        className={Styles.row__Style}
        activeClassName={Styles.active_row__Style}
        to="/admin/view-reports"
      >
        <MdSpaceDashboard style={{ marginRight: "10px" }} />
        <span>Reports</span>
      </NavLink>

      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          sessionStorage.removeItem("admin_token");
          history.push("/");
        }}
        className={Styles.row__Style}
      >
        <MdSpaceDashboard style={{ marginRight: "10px" }} />
        <span>Logout</span>
      </div>
    </div>
  );
};

export default SidebarItems;

import React from "react";
import congImg from "../../assets/Images/orderCongrats/congratsImg.svg";
import { Button } from "@material-ui/core";
import shareIcon from "../../assets/Images/orderCongrats/shareIcon.svg";
import { Container } from "react-bootstrap";

const OrderCongrats = () => {
  return (
    <Container className="text-center">
      <img src={congImg} alt="" />
      <h3>Congartulations </h3>
      <p>Your order is complete</p>
      <Button style={{ color: "#1877F2" }} variant="contained">
        Facebook <img style={{ marginLeft: "6px" }} src={shareIcon} alt="" />
      </Button>
    </Container>
  );
};

export default OrderCongrats;

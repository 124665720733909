import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DeliveryManHeadersComp from "../DeliveryManHeaderComp/DeliveryManHeadersComp";
import { useDispatch, useSelector } from "react-redux";
import { fetch_deliveryman_accepted_delivery_for_orders } from "./../../../Redux/DeliveryMan/DeliveryManActions";
import AcceptOrderCard from "./AcceptOrderCard/AcceptOrderCard";
import Loading from "./../../../utils/Loading";

const AcceptedOrders = () => {
  const dispatch = useDispatch();

  const { accepted_orders } = useSelector((state) => state.deliveryMan);

  useEffect(() => {
    dispatch(fetch_deliveryman_accepted_delivery_for_orders());
  }, []);

  console.log(accepted_orders);
  if (!accepted_orders) {
    return (
      <div className="text-center">
        <Loading />
      </div>
    );
  }
  return (
    <Container>
      <DeliveryManHeadersComp />
      <Row>
        <h4 className="text-center py-3">
          Delivery Remaining ({accepted_orders?.length})
        </h4>
        {accepted_orders?.map((item, index) => {
          return (
            <Col className="py-2" key={index} md={4}>
              <AcceptOrderCard item={item} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default AcceptedOrders;

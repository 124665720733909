import React from "react";
import DeliveryManOrders from "./../../Components/DeliveryManComps/DeliveryManOrders/DeliveryManOrders";

const DeliveryManOrdersPage = () => {
  return (
    <div>
      <DeliveryManOrders />
    </div>
  );
};

export default DeliveryManOrdersPage;

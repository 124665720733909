import React, { useState } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { fetch_report_plate } from "../../Redux/Plates/plateActions";

const ReportPlatePage = () => {
  const dispatch = useDispatch();
  const { productId } = useParams();
  const [reportText, setReportText] = useState("");

  const history = useHistory();

  const reportHandler = (e) => {
    e.preventDefault();

    dispatch(fetch_report_plate(productId, reportText));

    history.push('/');
  };
  return (
    <div>
      <h2 className="text-center py-3">Report post?</h2>
      <Container>
        <Row>
          <Col md={6} className="mx-auto">
            <Form onSubmit={reportHandler}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Why Do You Want to Report this Post..?</Form.Label>
                <Form.Control
                  onChange={(e) => setReportText(e.target.value)}
                  as="textarea"
                  rows={3}
                  required
                />
              </Form.Group>
              <button className="common_button_Style me-3" type="submit">
                Report
              </button>
              <Button
                onClick={() => {
                  history.push("/");
                }}
                variant="outlined"
              >
                Go Back
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ReportPlatePage;

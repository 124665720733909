import React from "react";
import AcceptedOrders from "../../Components/DeliveryManComps/AcceptedOrders/AcceptedOrders";

const AcceptOrdersPage = () => {
  return (
    <div>
      <AcceptedOrders />
    </div>
  );
};

export default AcceptOrdersPage;

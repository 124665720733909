import {
  GET_MY_PROFILE_INFO,
  GET_USER_PROFILE_INFO,
  HOST_ELIGIBILITY,
} from "./profileTypes";
import Axios from "axios";

export const get_profileInfo = (info) => {
  return {
    type: GET_USER_PROFILE_INFO,
    payload: info,
  };
};

export const get_my_profileInfo = (info) => {
  return {
    type: GET_MY_PROFILE_INFO,
    payload: info,
  };
};

export const get_host_eligibility = (info) => {
  return {
    type: HOST_ELIGIBILITY,
    payload: info,
  };
};

export const fetch_get_profile_info = (userId) => {
  return (dispatch) => {
    Axios.get(`/api/user-profile/${userId}`)
      .then((result) => {
        dispatch(get_profileInfo(result.data));
        //   console.log(result.data.plateInfo);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetch_get_my_profile_info = () => {
  return (dispatch) => {
    Axios.get(`/api/my-profile-info`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        dispatch(get_my_profileInfo(result.data));
      })
      .catch((err) => {
        dispatch(get_my_profileInfo({ error: "Something went wrong" }));
      });
  };
};

export const fetch_host_eligibility = () => {
  return (dispatch) => {
    Axios.get(`/api/host-eligibility-info`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        dispatch(get_host_eligibility(result.data));
      })
      .catch((err) => {
        dispatch(get_host_eligibility({ error: "Something went wrong" }));
      });
  };
};

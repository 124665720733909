import { GET_MY_PLATES, GET_USER_PLATES } from "./myPlatesType";

const init = {
  my_plates: "",
  user_plates: "",
};

export const myPlatesReducer = (state = init, action) => {
  switch (action.type) {
    case GET_MY_PLATES:
      return {
        ...state,
        my_plates: action.payload,
      };
    case GET_USER_PLATES:
      return {
        ...state,
        user_plates: action.payload,
      };

    default:
      return state;
  }
};

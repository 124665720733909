import React from "react";
import { Container } from "react-bootstrap";

const Terms = () => {
  return (
    <Container>
      {/* <img src="Logo_02.jpg" alt="" /> */}
      <h2 className="text-center py-3">Terms And Condition</h2>
      <div>
        <h3>
          <u>INTELLECTUAL PROPERTY RIGHTS</u>
        </h3>
        <p>
          Unless otherwise indicated, the Application is MyPlate’s proprietary
          property and all source code, databases, functionality, software,
          website designs, audio, video, text, photographs, and graphics on the
          Application (collectively, the “Content”) and the trademarks, service
          marks, and logos contained therein (the “Marks”) are owned or
          controlled by us or licensed to MyPlate Inc, and are protected by
          copyright and trademark laws and various other intellectual property
          rights and unfair competition laws of the United States, foreign
          jurisdictions, and international conventions. The Content and the
          Marks are provided on the Application “AS IS” for your information and
          personal use only. Except as expressly provided in these Terms of Use,
          no part of the Application and no Content or Marks may be copied,
          reproduced, aggregated, republished, uploaded, posted, publicly
          displayed, encoded, translated, transmitted, distributed, sold,
          licensed, or otherwise exploited for any commercial purpose
          whatsoever, without our express prior written permission.
        </p>
        <p>
          MyPlate reserves all rights not expressly granted to you in and to the
          Application, Content, and the Marks.
        </p>
      </div>
      <div>
        <h3>
          <u> USER REPRESENTATIONS </u>
        </h3>
        <p>By using the Application, you represent and warrant that:</p>
        <ul>
          <li>
            All registration information you submit will be true, accurate,
            current, and complete;
          </li>
          <li>
            You will maintain the accuracy of such information and promptly
            update such registration information as necessary
          </li>
          <li>
            You have the legal capacity and you agree to comply with these Terms
            of Use
          </li>
          <li>
            You are not under the age of 13 and not a minor in the jurisdiction
            in which you reside, or if a minor, you have received parental
            permission to use the Site
          </li>
          <li>
            You will not access the Application through automated or non-human
            means, whether through a bot, script or otherwise
          </li>
          <li>
            You will not use the Application for any illegal or unauthorized
            purpose
          </li>
          <li>
            Your use of the Application will not violate any applicable law or
            regulations
          </li>
          <li>
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Application(or any portion thereof)
          </li>
        </ul>
      </div>
      <div>
        <h3>
          <u>USER REGISTRATION</u>
        </h3>
        <p>
          You may be required to register with the Application. You agree to
          keep your password confidential and will be responsible for all use of
          your account and password. We reserve the right to remove, reclaim, or
          change a username you select if we determine, in our sole discretion,
          that such username is inappropriate, obscene, or otherwise
          objectionable.
        </p>
      </div>
      <div class="signature">
        <b>MyPlatexyz Inc</b>
        <br />
        Jamaica, NY - 11432 <br />
        USA <br />
        <u>support@myplate.xyz</u>
      </div>
    </Container>
  );
};

export default Terms;

import React from "react";
import Styles from "./DeliveryManProfileComp.module.css";
import { Container } from "react-bootstrap";
import DeliveryManHeadersComp from "../DeliveryManHeaderComp/DeliveryManHeadersComp";

const DeliveryManProfileComp = () => {
  return (
    <Container>
      <DeliveryManHeadersComp />
    </Container>
  );
};

export default DeliveryManProfileComp;

import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import Styles from "./AcceptOrderModal.module.css";
import { useDispatch } from "react-redux";
import { fetch_delivery_man_accept_order } from "./../../../../Redux/DeliveryMan/DeliveryManActions";

const AcceptOrderModal = (props) => {
  //   alert(props.orderId);
  const dispatch = useDispatch();

  const acceptOrderHandler = () => {
    dispatch(fetch_delivery_man_accept_order(props.orderId));
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-4">
          <p>
            You are confirming that, you are willing to pay {props.price} Tk to
            the host, deliver the plate to the buyer and the collect the payment
          </p>
          <button
            onClick={acceptOrderHandler}
            className={Styles.accept__button__style}
          >
            Accept
          </button>
          <button
            className={Styles.cancel__button__style}
            onClick={props.onHide}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AcceptOrderModal;

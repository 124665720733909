import React, { useState } from "react";
import { Col, Container, Form } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Styles from "./DeliveryManLogin.module.css";
import { Paper, Button } from "@material-ui/core";
import loginImg from "../../../assets/Images/Login/Rectangle 11 (1).png";
import plateLogo from "../../../assets/Images/Login/plateLogo.svg";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useFormik } from "formik";
import swal from "sweetalert";
import { useHistory } from "react-router";

const initialValues = {
  phone: "",
  password: "",
};

const DeliveryManLoginComp = () => {
  const [passType, setPassType] = useState("password");
  const history = useHistory();

  const validate = (values) => {
    const { phone, password } = values;

    let errors = {};

    if (!phone) {
      errors.phone = "Please enter your phone number";
    }

    if (!password) {
      errors.password = "Please enter your password";
    }

    return errors;
  };

  const onSubmit = (values) => {
    const { password, phone } = values;

    let registerData;

    fetch("/api/delivery-man/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ phone, password }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.error) {
          return swal("", result.error, "error");
        } else if (result.token) {
          localStorage.setItem("delivery_man_token", result.token);
          swal("", "Logged in successfully", "success");
          return history.push("/delivery-man/orders");
        } else {
          return swal("", "Something went wrong", "error");
        }
      })
      .catch((err) => {
        return swal("", "Something went wrong", "error");
      });
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });
  return (
    <div className={Styles.main__div}>
      <Container className="mt-4 py-2 pt-4">
        <Row>
          <Col className="mx-auto" md={6}>
            <Paper className="shadow">
              <Row>
                {/* <Col md={6}>
                  <div className={Styles.left__style}>
                    <img src={loginImg} alt="" />
                    <div className={Styles.overlay__style}>
                      <div className={Styles.content__style}>
                        <h4>HOMEMADE </h4>
                        <h1>FOOD</h1>
                        <h6>AT YOUR DOORSTEP</h6>
                      </div>
                    </div>
                  </div>
                </Col> */}
                <Col className="px-5 pb-4 ">
                  <div className="text-center pt-4">
                    <img
                      className={Styles.myPlate__logo__style}
                      src={plateLogo}
                      alt=""
                    />
                  </div>
                  <h4 className="text-center py-4" style={{ color: "#372B2B" }}>
                    Delivery Man Login
                  </h4>
                  <Form onSubmit={formik.handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone Number *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Please enter your phone number"
                        name="phone"
                        {...formik.getFieldProps("phone")}
                      />
                      <small style={{ color: "red" }}>
                        {formik.touched.phone &&
                          formik.errors.phone &&
                          formik.errors.phone}
                      </small>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Password *</Form.Label> <br />
                      <div className={Styles.passwordField__Style}>
                        <input
                          type={passType}
                          placeholder="Please enter your password"
                          name="password"
                          {...formik.getFieldProps("password")}
                        />
                        {passType === "password" ? (
                          <AiOutlineEyeInvisible
                            onClick={() => setPassType("text")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        ) : (
                          <AiOutlineEye
                            onClick={() => setPassType("password")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        )}
                      </div>
                      <small style={{ color: "red" }}>
                        {formik.touched.password &&
                          formik.errors.password &&
                          formik.errors.password}
                      </small>
                    </Form.Group>

                    <Button
                      style={{
                        width: "100%",
                        color: "white",
                        backgroundColor: "#372B2B",
                        margin: "10px 0px",
                      }}
                      variant="contained"
                      type="submit"
                    >
                      Log in
                    </Button>
                    <Link
                      className={Styles.link__style}
                      to="/delivery-man/forgot-password"
                    >
                      Forgot password?
                    </Link>
                  </Form>

                  <p className="mt-2">
                    {" "}
                    Don't have an account?{" "}
                    <Link
                      className={Styles.registerLink__style}
                      to="/delivery-man/register"
                    >
                      Register
                    </Link>
                  </p>
                </Col>
              </Row>
            </Paper>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DeliveryManLoginComp;

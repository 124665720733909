import React from "react";
import AboutUs from "../Components/AboutUs/AboutUs";

const AboutUsPage = () => {
  return (
    <div>
      <AboutUs />
    </div>
  );
};

export default AboutUsPage;

import React from "react";
import DashBoard from "../../Components/AdminComps/DashBoard/DashBoard";

const DashBoardPage = () => {
  return (
    <div>
      <DashBoard />
    </div>
  );
};

export default DashBoardPage;

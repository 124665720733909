import React from "react";
import { Route, Redirect } from "react-router-dom";

const UserPrivateRoute = ({ children, ...rest }) => {
  const user_token = localStorage.getItem("user_token");
  return (
    <div>
      <Route
        {...rest}
        render={({ location }) =>
          user_token ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    </div>
  );
};

export default UserPrivateRoute;

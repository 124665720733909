import React, { useEffect } from "react";
import Header from "../Header/Header";
import { Alert, Container, Table, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetch_get_customer_orders } from "../../../Redux/Order/OrderActions";
import Loading from "./../../../utils/Loading";
import OrderItemCard from "./../../OrderItemCard/OrderItemCard";

const CustomerOrderComp = () => {
  const dispatch = useDispatch();
  const { customer_orders } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(fetch_get_customer_orders());
  }, []);

  //console.log(customer_orders);

  if (!customer_orders) {
    return (
      <div className="text-center">
        <Loading />
      </div>
    );
  }

  if (customer_orders?.error) {
    return (
      <Alert className="alert alert-danger text-center">
        {customer_orders?.error}
      </Alert>
    );
  }
  if (customer_orders?.text) {
    return (
      <div>
        {" "}
        <Header />
        <Container>
          <Alert className="alert alert-info text-center">
            {customer_orders?.text}
          </Alert>
        </Container>
      </div>
    );
  }
  return (
    <>
      <Header />
      <Container>
        <Row>
          <h4 className="text-center py-3">Customer Orders</h4>
          {customer_orders?.map((item, index) => {
            return (
              <Col className="py-2" md={4}>
                <OrderItemCard item={item} key={index} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default CustomerOrderComp;

import React from "react";
import RegisterComp from '../../Components/RegisterComp/RegisterComp';

const RegisterPage = () => {
  return (
    <div>
      <RegisterComp />
    </div>
  );
};

export default RegisterPage;

import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
//import * as yup from "yup";
import Axios from "axios";
import { useDispatch } from "react-redux";
//import { useParams } from "react-router-dom";
//import { fetch_get_single_plate } from "../../../Redux/Plates/plateActions";
import swal from "sweetalert";
import Styles from "./AddPhoneNumberModal.module.css";

import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';

const initialValues = {
  phone: "",
  verification_code: "",
};

const AddPhoneNumberModal = (props) => {

  const [loading, setLoading] = React.useState(false);
  const [saveLoading, setSaveLoading] = React.useState(false);
  //  const { productId } = useParams();
  const dispatch = useDispatch();
  const [verify, setVerify] = useState(false);
  const validate = (values) => {
    const { phone, verification_code } = values;
    let errors = {};

    if (!phone) {
      errors.phone = "Please enter your phone number";
    } else if (phone.length !== 11) {
      errors.phone = "Phone number must contain 11 characters";
    }
    if (!verification_code) {
      errors.verification_code = "Please enter your verification code";
    }

    return errors;
  };

  const onSubmit = (values) => {
    setSaveLoading(true);
    const { phone, verification_code } = values;
    const data = {
      phone,
      verification_code,
    };

    Axios.put("/api/add-phone", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        if (result.data.success) {
          setSaveLoading(false);
          props.onHide();
          return swal("", result.data.success, "success");
        }
      })
      .catch((err) => {
        setSaveLoading(false);
        return swal("", err.response.data.error, "error");
      });
  };
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  const phoneNumberVerificationHandler = async () => {
    let only_phone_number = 0;
    setLoading(true);
    const { phone } = formik.values;

    if (!phone) {
      setLoading(false);
      return swal("", "Please enter your phone number", "error");
    } else if (phone.length !== 11) {
      setLoading(false);
      return swal("", "Phone number must contain 11 characters", "error");
    }

    if (phone) {
      // regualr expression checking to remove country code and extra spaces and dash
      only_phone_number = phone.replace(/\D/g, '').slice(-11);
      console.log(only_phone_number);
      if (only_phone_number.length !== 11) {
        setLoading(false);
        return swal("", "Please provide valid phone number", "error");
      }
    }

    const data = {
      value: only_phone_number,
      registerBy: "phone",
    };
    await Axios.post("/api/verify", data)
      .then((result) => {
        if (result.data.success) {
          setLoading(false);
          setVerify(true);
          return swal("", result.data.success, "success");
        } else {
          setLoading(false);
          return swal("", "Something went wrong", "error");
        }
      })
      .catch((err) => {
        setLoading(false);
        return swal("", err.response.data.error, "error");
      });
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Phone Number
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Phone Number *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please enter your phone number"
                name="phone"
                {...formik.getFieldProps("phone")}
              />
              <small style={{ color: "red" }}>
                {formik.touched.phone &&
                  formik.errors.phone &&
                  formik.errors.phone}
              </small>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Verification Code *</Form.Label><br />

              <LoadingButton
                style={{
                  color: 'white',
                  backgroundColor: '#958470',
                  marginBottom: '10px',
                  '&:hover': {
                    backgroundColor: '#372B2B',
                    color: 'white',
                  }
                }}
                onClick={phoneNumberVerificationHandler}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                variant="contained"
              >
                Send Code
              </LoadingButton>
              <Form.Control
                disabled={!verify}
                className={Styles.input_filed__style}
                type="text"
                placeholder="Verification Code"
                name="verification_code"
                {...formik.getFieldProps("verification_code")}
              />

              {/* <button
                  disabled={verify}
                  type="button"
                  onClick={phoneNumberVerificationHandler}
                  className={Styles.sendCode__button}
                >
                  Send Code
                </button> */}


              <small style={{ color: "red" }}>
                {formik.touched.verification_code &&
                  formik.errors.verification_code &&
                  formik.errors.verification_code}
              </small>
            </Form.Group>

            {/* <button className="common_button_Style" type="submit">
              Save
            </button> */}

            <LoadingButton
              style={{
                backgroundColor: '#958470',
                color: 'white',
                marginBottom: '10px',
                '&:hover': {
                  backgroundColor: '#372B2B',
                  color: 'white',
                }
              }}
              type="submit"
              // onClick={phoneNumberVerificationHandler}
              endIcon={<SaveIcon />}
              loading={saveLoading}
              loadingPosition="end"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddPhoneNumberModal;

import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import Axios from "axios";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetch_get_single_plate } from "../../../Redux/Plates/plateActions";
import swal from "sweetalert";

const initialValues = {
  address: "",
};

const DeliveryAddressModal = (props) => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const validationSchema = yup.object({
    address: yup.string().required("Please enter your delivery address"),
  });

  const onSubmit = (values) => {
    const { address } = values;
    const data = { address };

    Axios.post("/api/add-address", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
       
        if (result.data.success) {
          dispatch(fetch_get_single_plate(productId));
          props.onHide();
          return swal("", result.data.success, "success");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add delivery address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Delivery Address *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please enter your delivery address"
                name="address"
                {...formik.getFieldProps("address")}
              />
              <small style={{ color: "red" }}>
                {formik.touched.address &&
                  formik.errors.address &&
                  formik.errors.address}
              </small>
            </Form.Group>

            <button className="common_button_Style" type="submit">
              Add Address
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeliveryAddressModal;

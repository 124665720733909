import React from "react";
import LoginComp from "../../Components/LoginComp/LoginComp";

const LoginPage = () => {
  return (
    <div>
      <LoginComp />
    </div>
  );
};

export default LoginPage;

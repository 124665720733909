import React from "react";
import UserDetailsComp from "./../../Components/UserDetails/UserDetailsComp";

const UserDetailsPage = () => {
  return (
    <div>
      <UserDetailsComp />
    </div>
  );
};

export default UserDetailsPage;

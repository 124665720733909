import React from "react";
import Styles from "./OrderItemCard.module.css";
import { Button, Paper } from "@material-ui/core";
import plateIcon from "../../assets/Images/Order/plateImg.svg";
import { useDispatch } from "react-redux";
import { fetch_updateOrderStatus } from "./../../Redux/Order/OrderActions";

const OrderItemCard = ({ item }) => {
  const dispatch = useDispatch();
  const {
    item_quantity,
    note,
    delivery_address,
    order_status,
    plate_info,
    order_user,
    _id,
  } = item;
  const { name, email, phone } = order_user;
  //const { plate_info?.plate_name, plate_images } = plate_info;

  const orderStatusUpdateHandler = (orderId, order_status) => {
    dispatch(fetch_updateOrderStatus(orderId, order_status));
    // alert(orderId, order_status);
  };

  return (
    <div>
      <Paper className="p-4 shadow text-center">
        <img
          className={Styles.image__style}
          src={plate_info?.plate_images && plate_info?.plate_images[0]}
          alt=""
        />
        <h4 className="py-2">
          {plate_info?.plate_name ? plate_info?.plate_name : "N/A"}
        </h4>
        <b>Name : {name}</b> <br />
        <b>Phone : {phone}</b> <br />
        <small style={{ color: "gray" }}>
          NOTE: {note ? note : "Not available"}
        </small>
        <div className="d-flex align-items-center justify-content-center py-3">
          <img src={plateIcon} alt="" />
          <h5 style={{ margin: "0px", paddingLeft: "10px" }}>
            {item_quantity} {item_quantity == 1 ? "Plate" : "Plates"}
          </h5>
        </div>
        <div className="text-center">
          {order_status === "Pending" ? (
            <>
              {" "}
              <button
                onClick={() => orderStatusUpdateHandler(_id, "Confirmed")}
                className={`${Styles.button__style} mx-2`}
              >
                Confirm
              </button>
              <button
                onClick={() => orderStatusUpdateHandler(_id, "Cancelled")}
                className={`${Styles.button__style} mx-2`}
              >
                Cancel
              </button>
            </>
          ) : (
            <button
              style={{ width: "100%" }}
              className={Styles.active_button__style}
            >
              {order_status}
            </button>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default OrderItemCard;

import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Styles from "./PlateCard.module.css";
//import { AiFillStar } from "react-icons/ai";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import {
  fetch_get_my_plates,
  fetch_like_post,
  fetch_check_report_plate,
  fetch_get_edit_plate_info,
} from "./../../Redux/Plates/plateActions";
import profileImg from "../../assets/Images/PlaceHolderImages/img_avatar.png";
import DeleteModal from "./../../utils/DeleteModal";
// import CardDialogPopUp from "./CardDialogPopup/CardDialogPopUp";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { styled, alpha } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
// import FavoriteIcon from '@mui/icons-material/Favorite';
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { brown } from "@mui/material/colors";
const PlateCard = ({ item, is_profile }) => {
  const dispatch = useDispatch();
  const {
    plate_name,
    _id,
    all_time_available,
    description,
    discount_price,
    in_stock,
    last_date_to_order,
    last_time_to_order,
    location,
    own_delivery_system,
    plate_images,
    postedBy,
    quantity,
    price,
    host_profile,
    like,
  } = item;

  // console.log("plate last time"+last_time_to_order);

  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [desShow, setDesShow] = useState(200);
  const [seeMore, setSeeMore] = useState(true);
  const [orderButton, setOrderButton] = useState("");
  const [decodeUserInfo, setDecodeUserInfo] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [plateId, setPlateId] = React.useState(false);

  let today1 = new Date().toLocaleDateString();
  let plateDate = moment(last_date_to_order).format("L");

  var today = new Date();
  var time = today.getHours() + ":" + today.getMinutes();

  if (Date.parse(time) === Date.parse(last_time_to_order)) {
    //console.log("true");
  }

  const viewDetailsHandler = () => {
    if (localStorage.getItem("user_token")) {
      var decoded = jwt_decode(localStorage.getItem("user_token"));
      if (postedBy?._id === decoded._id) {
        history.push("/my-posts");
      } else {
        history.push(`/user-info/profile/${postedBy?._id}`);
      }
    } else {
      history.push(`/user-info/profile/${postedBy?._id}`);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("user_token")) {
      var decoded = jwt_decode(localStorage.getItem("user_token"));
      setDecodeUserInfo(decoded);

      if (postedBy?._id === decoded._id) {
        setOrderButton(true);
      }
    }

    if (is_profile) {
      setOrderButton(true);
    }
  }, [item, is_profile]);

  // hide delete modal
  const handleModalCLose = () => {
    setModalShow(false);
  };
  // delete a post
  const deletePostHandler = (postId) => {
    setModalShow(true);
    setPlateId(postId);
    setAnchorEl(null);
  };

  // love reaction button handler
  const handleLike = (post_id) => {
    dispatch(fetch_like_post(post_id));
    setAnchorEl(null);
  };

  // report button handler
  const handleReport = (post_id) => {
    dispatch(fetch_check_report_plate(post_id));
    setAnchorEl(null);
    //send email
  };

  const editPostHandler = (post_id) => {
    dispatch(fetch_get_edit_plate_info(post_id));
    history.push(`/edit-post/${post_id}`);
  };

  // three dot menu item styles
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 120,

      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        display: "block",
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  //menu item variables
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //handling the back button during the model popup

  const neutralizeBack = (callback) => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
      callback();
    };
  };

  const revivalBack = () => {
    window.onpopstate = undefined;
    window.history.back();
  };

  const openImagePopUp = (index) => {
    setOpen(true);
    setPhotoIndex(index);
    neutralizeBack(closeImagePopUp);
  };

  const closeImagePopUp = () => {
    setOpen(false);
    revivalBack();
  };

  return (
    <div>
      <Paper className="p-4 shadow">
        <div className={Styles.headerCard__Style}>
          <div className={Styles.plateInfo__style}>
            {host_profile?.profile_image ? (
              <img
                onClick={viewDetailsHandler}
                className={Styles.plateProfileImage__style}
                src={host_profile?.profile_image}
                alt=""
              />
            ) : (
              <img
                onClick={viewDetailsHandler}
                className={Styles.plateProfileImage__style}
                src={profileImg}
                alt=""
              />
            )}

            <div className="">
              <b
                onClick={viewDetailsHandler}
                style={{ color: "#958470", cursor: "pointer" }}
              >
                {postedBy?.name.split(" ")[0]}
              </b>{" "}
              {/* certificate of host */}
              {postedBy?.myCertification && (
                <WorkspacePremiumRoundedIcon
                  sx={{ color: brown[500] }}
                  fontSize="small"
                />
              )}
              <br />
              <small>{location.slice(0, 20)}</small> <br />
            </div>
          </div>
          <div className={Styles.platePrice_Style}>
            {decodeUserInfo && decodeUserInfo?._id === postedBy?._id ? (
              //user's own post
              <div>
                <Button
                  id="basic-button"
                  aria-controls={openMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <MoreVertIcon fontSize="small" sx={{ color: "#372b2b" }} />
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => editPostHandler(_id)} disableRipple>
                    <EditOutlinedIcon />
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => deletePostHandler(_id)}
                    disableRipple
                  >
                    <DeleteOutlineOutlinedIcon />
                    Delete
                  </MenuItem>
                </StyledMenu>
              </div>
            ) : (
              // not user's own post
              <div>
                <Button
                  id="basic-button"
                  aria-controls={openMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <MoreVertIcon fontSize="small" sx={{ color: "#372b2b" }} />
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleLike(_id);
                    }}
                  >
                    <FavoriteBorderOutlinedIcon />
                    {/* <FavoriteIcon/> */}
                    Love
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleReport(_id);
                    }}
                    disableRipple
                  >
                    <ReportGmailerrorredOutlinedIcon />
                    Report
                  </MenuItem>
                </StyledMenu>
              </div>
            )}
            {/* {like.length} Likes
            <CardDialogPopUp _id={_id} like={like} /> */}

            {/* {discount_price ? (
              <div className="d-flex align-items-center">
                {" "}
                <h5 className="me-2">{discount_price} Tk </h5>
                <del style={{ color: "gray", fontSize: "12px" }}>
                  {price} tk
                </del>
              </div>
            ) : (
              <h5>{price} Tk </h5>
            )} */}
          </div>
        </div>
        <p style={{ color: "#958470" }}>
          Plate name : <b style={{ color: "#372B2B" }}>{plate_name}</b>
        </p>
        <p style={{ color: "#958470" }}>
          Plate price : <b style={{ color: "#372B2B" }}>{price}</b>
        </p>
        {description.length > 150 ? (
          <div className="">
            <p>
              {description.slice(0, desShow)}
              {seeMore ? (
                <b
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  onClick={() => {
                    setDesShow(description.length);
                    setSeeMore(false);
                  }}
                >
                  See more
                </b>
              ) : (
                <b
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  onClick={() => {
                    setDesShow(200);
                    setSeeMore(true);
                  }}
                >
                  See less
                </b>
              )}
            </p>
          </div>
        ) : (
          <p>{description}</p>
        )}

        <div className={Styles.plateRemainingItems__style}>
          <span style={{ color: "#958470" }}>Remaining plate : </span>{" "}
          <b style={{ color: "#372B2B" }}>{quantity}</b> <br />
          <span style={{ color: "#958470" }}>Last time to order : </span>{" "}
          {all_time_available ? (
            <b style={{ color: "#372B2B" }}>Anytime</b>
          ) : (
            <b style={{ color: "#372B2B" }}>
              {moment(last_time_to_order, "hh:mm a").format("hh:mm A")},
              {moment(last_date_to_order, "YYYY:MM:DD").format("DD MMM YYYY")}
            </b>
          )}
        </div>
        <div className="py-2">
          <Row className={Styles.desktop__style}>
            {(() => {
              switch (true) {
                case plate_images.length === 1:
                  {
                    return (
                      <ImageList cols={1}>
                        {plate_images.map((item, index) => (
                          <ImageListItem key={item}>
                            <img
                              src={`${item}`}
                              srcSet={`${item}`}
                              alt={"myplate post image"}
                              loading="lazy"
                              onClick={() => openImagePopUp(index)}
                              // onClick={() => {
                              //   setOpen(true);
                              //   setPhotoIndex(index);
                              // }}
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    );
                  }
                  break;
                case plate_images.length % 2 === 0:
                  {
                    return (
                      <ImageList cols={2}>
                        {plate_images.map((item, index) => (
                          <ImageListItem key={item}>
                            <img
                              src={`${item}`}
                              srcSet={`${item}`}
                              alt={"myplate post image"}
                              loading="lazy"
                              onClick={() => openImagePopUp(index)}
                              // onClick={() => {
                              //   setOpen(true);
                              //   setPhotoIndex(index);
                              // }}
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    );
                  }
                  break;
                case plate_images.length % 3 === 0:
                  {
                    return (
                      <ImageList cols={3}>
                        {plate_images.map((item, index) => (
                          <ImageListItem key={item}>
                            <img
                              src={`${item}`}
                              srcSet={`${item}`}
                              alt={"myplate post image"}
                              loading="lazy"
                              onClick={() => openImagePopUp(index)}
                              // onClick={() => {
                              //   setOpen(true);
                              //   setPhotoIndex(index);
                              // }}
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    );
                  }
                  break;
                default:
                  {
                    return (
                      <ImageList cols={3}>
                        {plate_images.map((item, index) => (
                          <ImageListItem key={item}>
                            <img
                              src={`${item}`}
                              srcSet={`${item}`}
                              alt={"myplate post image"}
                              loading="lazy"
                              onClick={() => openImagePopUp(index)}
                              // onClick={() => {
                              //   setOpen(true);
                              //   setPhotoIndex(index);
                              // }}
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    );
                  }
                  break;
              }
            })()}
          </Row>
          <Row className={Styles.mobile__style}>
            {(() => {
              switch (true) {
                case plate_images.length === 1:
                  {
                    return (
                      <ImageList cols={1}>
                        {plate_images.map((item, index) => (
                          <ImageListItem key={item}>
                            <img
                              src={`${item}`}
                              srcSet={`${item}`}
                              alt={"myplate post image"}
                              loading="lazy"
                              onClick={() => openImagePopUp(index)}
                              // onClick={() => {
                              //   setOpen(true);
                              //   setPhotoIndex(index);
                              // }}
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    );
                  }
                  break;
                default:
                  {
                    return (
                      <ImageList cols={2}>
                        {plate_images.map((item, index) => (
                          <ImageListItem key={item}>
                            <img
                              src={`${item}`}
                              srcSet={`${item}`}
                              alt={"myplate post image"}
                              loading="lazy"
                              onClick={() => openImagePopUp(index)}
                              // onClick={() => {
                              //   setOpen(true);
                              //   setPhotoIndex(index);
                              // }}
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    );
                  }
                  break;
              }
            })()}
          </Row>
        </div>
        {orderButton ? (
          ""
        ) : (
          <>
            {quantity > 0 ? (
              <button
                onClick={() => {
                  fetch(
                    "https://extreme-ip-lookup.com/json/?key=2lVY8K8boFCOZVg0lmVY"
                  )
                    .then((res) => res.json())
                    .then((response) => {
                      console.log("Country is : ", response);
                      // setCountryCode(response.countryCode);
                      if (response.countryCode === "BD") {
                        history.push(`/confirm-order/${item?._id}`);
                      } else {
                        swal(
                          "Oops!",
                          "Currently service is not available in your area.",
                          "error"
                        );
                      }
                    })
                    .catch((data, status) => {
                      console.log("Request failed:", data);
                    });
                }}
                className={Styles.button__Style}
              >
                ORDER NOW
              </button>
            ) : (
              <button
                onClick={() => {
                  swal("Oops!", "This item is out of stock", "error");
                }}
                className={Styles.button__Style}
              >
                ORDER NOW
              </button>
            )}
          </>
        )}
      </Paper>

      <DeleteModal
        show={modalShow}
        onHide={handleModalCLose}
        plateId={plateId}
        deleteText="plate"
      />

      {open && (
        <Lightbox
          mainSrc={plate_images[photoIndex]}
          nextSrc={plate_images[(photoIndex + 1) % plate_images.length]}
          prevSrc={
            plate_images[
              (photoIndex + plate_images.length - 1) % plate_images.length
            ]
          }
          onCloseRequest={closeImagePopUp}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + plate_images.length - 1) % plate_images.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % plate_images.length)
          }
          clickOutsideToClose={false}
        />
      )}
    </div>
  );
};

export default PlateCard;

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from "./Components/Header/Header";
import HomePage from "./pages/User/HomePage";
import LoginPage from "./pages/User/LoginPage";
import ConfirmOrderPage from "./pages/User/ConfirmOrderPage";
import OrderCongratsPage from "./pages/User/OrderCongratsPage";
import OrderStatusPage from "./pages/User/OrderStatusPage";
import RegisterPage from "./pages/User/RegisterPage";
// import UserProfilePage from "./pages/User/UserProfilePage";
// import UserPlatesPage from "./pages/User/UserPlatesPage";
// import UserPostsPage from "./pages/User/UserPostsPage";
import MyProfilePage from "./pages/User/MyProfilePages/MyProfilePage";
import MyPlatesPage from "./pages/User/MyProfilePages/MyPlatesPage";
import MyPostsPage from "./pages/User/MyProfilePages/MyPostsPage";
import HostPlatesPage from "./pages/User/MyProfilePages/HostPlatesPage";
//import OrderPage from "./pages/User/MyProfilePages/MyOrdersPage";
import UserPrivateRoute from "./utils/UserPrivateRoute";
import UserDetailsPage from "./pages/User/UserDetailsPage";
import CustomerOrdersPage from "./pages/User/MyProfilePages/CustomerOrdersPage";
import MyOrdersPage from "./pages/User/MyProfilePages/MyOrdersPage";
import DeliveryManRegisterPage from "./pages/DeliveryMan/DeliveryManRegisterPage";
import DeliveryManLoginPage from "./pages/DeliveryMan/DeliveryManLoginPage";
import DeliveryManprofilePage from "./pages/DeliveryMan/DeliveryManprofilePage";
import DeliveryManOrdersPage from "./pages/DeliveryMan/DeliveryManOrdersPage";
import MyDeliveryPage from "./pages/User/MyDeliveryPage";
import AcceptOrdersPage from "./pages/DeliveryMan/AcceptOrdersPage";
import TutorialPage from "./pages/User/TutorialPage";
import Index from "./pages/Admin/Index";
import Terms from "./Components/Terms/Terms";
import AboutUsPage from "./pages/AboutUsPage";
import ForgotPassPage from "./pages/User/ForgotPassPage";
import NewPassPage from "./pages/User/NewPassPage";
import DeliveryManForgotPassPage from "./pages/DeliveryMan/DeliveryManForgotPassPage";
// import TermsPage from "./pages/TermsPage";
import DeliveryManNewPassPage from "./pages/DeliveryMan/DeliveryManNewPassPage";
import ReportPlatePage from "./pages/User/ReportPlatePage";
import EditPost from "./pages/User/EditPost";

const AdminLoginPage = React.lazy(() => import("./pages/Admin/AdminLoginPage"));
const AdminPrivateRoute = React.lazy(() => import("./utils/AdminPrivateRoute"));

const Routing = () => {
  return (
    <React.Suspense fallback={<p>loading...</p>}>
      <Switch>
        <Route exact path="/">
          <Header />
          <HomePage />
        </Route>
        <Route exact path="/login">
          <Header />
          <LoginPage />
        </Route>
        <Route exact path="/forgot-password">
          <Header />
          <ForgotPassPage />
        </Route>
        <Route exact path="/new-password">
          <Header />
          <NewPassPage />
        </Route>
        <UserPrivateRoute exact path="/confirm-order/:productId">
          <Header />
          <ConfirmOrderPage />
        </UserPrivateRoute>
        <UserPrivateRoute exact path="/report-plate/:productId">
          <Header />
          <ReportPlatePage />
        </UserPrivateRoute>
        <Route exact path="/order-success">
          <Header />
          <OrderCongratsPage />
        </Route>
        <Route exact path="/order-status">
          <Header />
          <OrderStatusPage />
        </Route>
        <Route exact path="/register">
          <Header />
          <RegisterPage />
        </Route>
        <Route exact path="/user-info/profile/:userId">
          <Header />
          <UserDetailsPage />
        </Route>
        <Route exact path="/tutorial">
          <Header />
          <TutorialPage />
        </Route>
        <Route exact path="/terms">
          <Header />
          <Terms />
        </Route>
        <Route exact path="/about-us">
          <Header />
          <AboutUsPage />
        </Route>

        {/* TODO: SHOW USER PROFILE */}

        {/* <Route exact path="/profile/:id">
        <UserProfilePage />
      </Route>
      <Route exact path="/plates/:id">
        <UserPlatesPage />
      </Route>
      <Route exact path="/posts/:id">
        <UserPostsPage />
      </Route> */}

        {/* MY PROFILE */}

        <UserPrivateRoute exact path="/profile">
          <Header />
          <MyProfilePage />
        </UserPrivateRoute>
        <UserPrivateRoute exact path="/my-plates">
          <Header />
          <MyPlatesPage />
        </UserPrivateRoute>
        <UserPrivateRoute exact path="/my-posts">
          <Header />
          <MyPostsPage />
        </UserPrivateRoute>
        <UserPrivateRoute exact path="/edit-post/:postId">
          <Header />
          <EditPost />
        </UserPrivateRoute>
        <UserPrivateRoute exact path="/host">
          <Header />
          <HostPlatesPage />
        </UserPrivateRoute>
        <UserPrivateRoute exact path="/customer-orders">
          <Header />
          <CustomerOrdersPage />
        </UserPrivateRoute>
        <UserPrivateRoute exact path="/my-orders">
          <Header />
          <MyOrdersPage />
        </UserPrivateRoute>
        <UserPrivateRoute exact path="/my-delivery">
          <Header />
          <MyDeliveryPage />
        </UserPrivateRoute>

        {/* TODO: DELIVERYMAN */}

        <Route exact path="/delivery-man/register">
          <Header />
          <DeliveryManRegisterPage />
        </Route>
        <Route exact path="/delivery-man/login">
          <Header />
          <DeliveryManLoginPage />
        </Route>
        <Route exact path="/delivery-man/profile">
          <Header />
          <DeliveryManprofilePage />
        </Route>
        <Route exact path="/delivery-man/orders">
          <Header />
          <DeliveryManOrdersPage />
        </Route>
        <Route exact path="/delivery-man/accepted-delivery">
          <Header />
          <AcceptOrdersPage />
        </Route>
        <Route exact path="/delivery-man/forgot-password">
          <Header />
          <DeliveryManForgotPassPage />
        </Route>
        <Route exact path="/delivery-man/new-password">
          <Header />
          <DeliveryManNewPassPage />
        </Route>

        {/* TODO: ADMIN DASHBOARD */}

        {/* Admin Login */}
        <Route exact path="/admin/admin-login">
          <AdminLoginPage />
        </Route>

        <AdminPrivateRoute exact path="/admin/dashboard">
          <Index />
        </AdminPrivateRoute>
        <AdminPrivateRoute exact path="/admin/view-reports">
          <Index />
        </AdminPrivateRoute>
        <AdminPrivateRoute exact path="/admin/report-post/:reportedPostId">
          <Index />
        </AdminPrivateRoute>
        <AdminPrivateRoute exact path="/admin/view-users">
          <Index />
        </AdminPrivateRoute>
        <AdminPrivateRoute exact path="/admin/view-orders">
          <Index />
        </AdminPrivateRoute>
        <AdminPrivateRoute exact path="/admin/view-admins">
          <Index />
        </AdminPrivateRoute>
        <AdminPrivateRoute exact path="/admin/add-admin">
          <Index />
        </AdminPrivateRoute>
        <AdminPrivateRoute exact path="/admin/view-tutorials">
          <Index />
        </AdminPrivateRoute>
        <AdminPrivateRoute exact path="/admin/add-tutorial">
          <Index />
        </AdminPrivateRoute>
        <AdminPrivateRoute exact path="/admin/view-deliveryMans">
          <Index />
        </AdminPrivateRoute>

        {/* Delivery Method */}

        <AdminPrivateRoute exact path="/admin/view-delivery-methods">
          <Index />
        </AdminPrivateRoute>
        <AdminPrivateRoute exact path="/admin/add-delivery-method">
          <Index />
        </AdminPrivateRoute>
        <AdminPrivateRoute exact path="/admin/update-delivery-method/:methodId">
          <Index />
        </AdminPrivateRoute>
      </Switch>
    </React.Suspense>
  );
};

function App() {
  return (
    <Router>
      <Routing />
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetch_get_tutorial_lists, delete_tutorial_action } from "../../../../Redux/Admin/AdminActions";
import CommonViewTitle from "./../../../../utils/CommonViewTitle";
import Loading from "./../../../../utils/Loading";
import { MdDeleteOutline, MdLinkOff } from "react-icons/md";
import { BiEdit } from "react-icons/bi";

const ViewTutorials = () => {
  const dispatch = useDispatch();
  const { tutorialList } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(fetch_get_tutorial_lists());
  }, []);

  if (!tutorialList) {
    return <Loading />;
  }

  const handleEdit = (tutorial_id) => {
    //delete code
    alert('will be updated soon...!')
  }

  const handleDelete = (tutorial_id) => {
    console.log('admin clicked to delete a tutorial');
    dispatch(delete_tutorial_action(tutorial_id));
  }
  return (
    <Container>
      <CommonViewTitle
        title="Tutorials"
        btnText="Add Tutorial"
        url="/admin/add-tutorial"
      />

      <Table className="text-center" striped bordered hover responsive>
        <thead>
          <tr>
            {/* <th>ID</th> */}
            <th>Title</th>
            <th>Video Link</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tutorialList?.map((item, index) => {
            return (
              <tr key={index}>
                {/* <td>{item._id}</td> */}
                <td>{item.title}</td>
                <td>{item.video_link}</td>
                <td>
                  <BiEdit className="edit_icon_Style" onClick={() => handleEdit(item._id)} />
                  <MdDeleteOutline className="delete_icon_Style" onClick={() => handleDelete(item._id)} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default ViewTutorials;

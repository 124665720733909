import React, { useEffect, useState } from "react";
import { Alert, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import Axios from "axios";
import swal from "sweetalert";
import { fetch_get_my_profile_info } from "../../../../Redux/profile/profileAction";
import { useDispatch } from "react-redux";

const initialValues = {
  user_address: "",
  gender: "",
  name: "",
};

const EditGeneralInfo = (props) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();

  const validate = (values) => {
    const { user_address, gender, name } = values;

    let errors = {};

    if (!name) {
      errors.name = "Please enter your name";
    }

    return errors;
  };

  const onSubmit = (values) => {
    const { user_address, gender, name } = values;

    const data = { user_address, gender, name };

    setLoad(true);

    Axios.put("/api/update-my-profile-info", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        setLoad(false);
        console.log(result);
        if (result.data.success) {
          dispatch(fetch_get_my_profile_info());
          swal("", result.data.success, "success");
          props.onHide();
        } else {
          setErrorMsg("Something went wrong");
        }
      })
      .catch((err) => {
        setLoad(false);
        setErrorMsg("Something went wrong");
      });
  };
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  useEffect(() => {
    if (props) {
      //   console.log(props);
      formik.initialValues.name = props?.name;
      formik.initialValues.gender = props?.gender;
      formik.initialValues.user_address = props?.user_address;
    }
  }, [props]);
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMsg && (
            <Alert className="alert alert-danger text-center">{errorMsg}</Alert>
          )}
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please enter your name"
                name="name"
                {...formik.getFieldProps("name")}
              />
              <small style={{ color: "red" }}>
                {formik.touched.name &&
                  formik.errors.name &&
                  formik.errors.name}
              </small>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please enter your address"
                name="user_address"
                {...formik.getFieldProps("user_address")}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Gender</Form.Label>
              <Form.Select name="gender" {...formik.getFieldProps("gender")}>
                <option value="">Please choose</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Form.Select>
            </Form.Group>

            <button className="common_button_Style" type="submit">
              Submit
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditGeneralInfo;

import {
  GET_ALL_PLATES,
  GET_PAGINATE_PLATES,
  GET_MY_PLATES,
  GET_SINGLE_PLATE_INFO,
  GET_EDIT_PLATE_INFO,
  GET_FILTER_PLATE,
} from "./PlateTypes";
import Axios from "axios";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

export const get_all_plates = (plates) => {
  return {
    type: GET_ALL_PLATES,
    payload: plates,
  };
};
export const get_paginate_plates = (plates) => {
  return {
    type: GET_PAGINATE_PLATES,
    payload: plates,
  };
};
export const get_my_plates = (plates) => {
  return {
    type: GET_MY_PLATES,
    payload: plates,
  };
};

export const get_single_plate = (plate) => {
  return {
    type: GET_SINGLE_PLATE_INFO,
    payload: plate,
  };
};

export const get_filter_plates = (plate) => {
  return {
    type: GET_FILTER_PLATE,
    payload: plate,
  };
};

export const get_edit_plate = (plate) => {
  return {
    type: GET_EDIT_PLATE_INFO,
    payload: plate,
  };
};

export const fetch_get_all_plates = () => {
  return (dispatch) => {
    Axios.get(`/api/all-plates`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        dispatch(get_all_plates(result.data));
      })
      .catch((err) => {
        dispatch(get_all_plates("Something went wrong"));
      });
  };
};

export const fetch_get_paginate_plates = (page) => {
  return (dispatch) => {
    Axios.get(`/api/all-plates/${page}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        dispatch(get_paginate_plates(result.data));
      })
      .catch((err) => {
        dispatch(get_paginate_plates("Something went wrong"));
      });
  };
};

export const fetch_get_filter_plates = (category) => {
  return (dispatch) => {
    Axios.get(`/api/all-plates/filter/${category}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        dispatch(get_filter_plates(result.data));
      })
      .catch((err) => {
        dispatch(get_filter_plates("Something went wrong"));
      });
  };
};

export const fetch_get_my_plates = () => {
  return (dispatch) => {
    Axios.get("/api/my-plates", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        dispatch(get_my_plates(result.data));
      })
      .catch((err) => {
        dispatch(get_all_plates("Something went wrong"));
      });
  };
};

export const fetch_get_single_plate = (plateId) => {
  return (dispatch) => {
    Axios.get(`/api/single-plate/${plateId}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        dispatch(get_single_plate(result.data));
      })
      .catch((err) => {
        console.log(err.response.data);
        dispatch(get_single_plate({ error: "Something went wrong" }));
      });
  };
};

export const fetch_get_edit_plate_info = (plateId) => {
  return async (dispatch) => {
    Axios.get(`/api/edit-plate/${plateId}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        dispatch(get_edit_plate(result.data.plateInfo));
      })
      .catch((err) => {
        console.log(err.response.data);
        dispatch(get_edit_plate({ error: "Something went wrong" }));
      });
  };
};

export const fetch_delete_post = (postId) => {
  return async (dispatch) => {
    Axios.delete(`/api/delete-plate/${postId}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        dispatch(fetch_get_all_plates());

        if (result.data.success) {
          dispatch(fetch_get_all_plates());
          return swal("", "Post deleted successfully", "success");
        } else {
          return swal("", "Something went wrong ", "error");
        }
      })
      .catch((err) => {
        return swal("", "Something went wrong ", "error");
      });
  };
};

export const fetch_like_post = (plateId) => {
  return async (dispatch) => {
    // console.log(localStorage.getItem("user_token"));
    fetch(`/api/like-plate/${plateId}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.error) {
          return swal("", result.error, "error");
        }
        if (result.success) {
          swal("", result.success, "success");
        }
        dispatch(fetch_get_all_plates());
      })
      .catch((err) => {
        return swal("", "Something went wrong ", "error");
      });
  };
};

export const fetch_check_report_plate = (plateId) => {
  //const history = useHistory();
  return async (dispatch) => {
    fetch(`/api/check-report-plate/${plateId}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.error) {
          return swal("", result.error, "error");
        }
        if (result.success) {
          //history.push(`/report-plate/${plateId}`);
          window.location.href = `/report-plate/${plateId}`;
          //swal("", result.success, "success");
        }
      })
      .catch((err) => {
        return swal("", "Something went wrong ", "error");
      });
  };
};

export const fetch_report_plate = (plateId, reportText) => {
  return async (dispatch) => {
    // console.log(localStorage.getItem("user_token"));
    fetch(`/api/report-plate/${plateId}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ reportText }),
    })
      .then((res) => res.json())
      .then((result) => {
        dispatch(fetch_get_all_plates());
        swal("", result.success, "success");
      })
      .catch((err) => {
        return swal("", "Something went wrong ", "error");
      });
  };
};

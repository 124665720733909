import React from "react";
import { Paper } from "@material-ui/core";
import Styles from "./AcceptOrderCard.module.css";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Axios from "axios";
import swal from "sweetalert";
import { fetch_deliveryman_accepted_delivery_for_orders } from "./../../../../Redux/DeliveryMan/DeliveryManActions";

const AcceptOrderCard = ({ item }) => {
  const dispatch = useDispatch();
  const {
    delivery_address,
    total_price,
    plate_info,
    last_date_to_order,
    last_time_to_order,
    order_id,
    order_user,
    host_info,
  } = item;

  console.log(host_info);

  // console.log(item);

  const completeMyDeliveryHandler = (orderId) => {
    fetch(`/api/delivery-man/complete-delivery/${orderId}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("delivery_man_token"),
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          dispatch(fetch_deliveryman_accepted_delivery_for_orders());
          return swal("", result.success, "success");
        } else if (result.error) {
          return swal("", result.error, "success");
        } else {
          return swal("", "Something went wrong", "success");
        }
      })
      .catch((error) => {
        return swal("", "Something went wrong", "success");
      });
  };
  return (
    <div>
      <div>
        <Paper className="p-4 shadow ">
          <h3 className="text-center">Delivery Information</h3>
          <hr />
          <Row className="mb-2">
            <Col>
              <b>OrderId: </b>
            </Col>
            <Col>
              <span>{order_id}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <b>Host Name: </b>
            </Col>
            <Col>
              <span>{host_info.name}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <b>Host Number: </b>
            </Col>
            <Col>
              <span>{host_info.phone}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <b>Customer Name: </b>
            </Col>
            <Col>
              <span>{order_user.name}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <b>Customer Phone: </b>
            </Col>
            <Col>
              <span>{order_user.phone}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <b>Location: </b>
            </Col>
            <Col>
              <span>
                {plate_info.location} to {delivery_address}
              </span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <b>Total Price : </b>
            </Col>
            <Col>
              <span>{total_price} BDT</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <b>Delivery Time : </b>
            </Col>
            <Col>
              <span> 12.30 pm</span>
            </Col>
          </Row>

          <button
            onClick={() => completeMyDeliveryHandler(item._id)}
            className={Styles.button__Style}
          >
            Complete The Delivery
          </button>
        </Paper>
      </div>
    </div>
  );
};

export default AcceptOrderCard;

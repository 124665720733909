import React from 'react';
import HostComps from '../../../Components/UserProfileComps/HostComps/HostComps';

const HostPlatesPage = () => {
    return (
        <div>
            <HostComps/>
        </div>
    );
};

export default HostPlatesPage;
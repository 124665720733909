import React from "react";
import AddDeliveryMethod from "../../Components/AdminComps/DeliveryMethodComps/AddDeliveryMethod/AddDeliveryMethod";

const AddDeliveryChargePage = () => {
  return (
    <div>
      <AddDeliveryMethod />
    </div>
  );
};

export default AddDeliveryChargePage;

import React from "react";
import DeliveryManNewPass from "./../../Components/DeliveryManComps/DeliveryManNewPass/DeliveryManNewPass";

const DeliveryManNewPassPage = () => {
  return (
    <div>
      <DeliveryManNewPass />
    </div>
  );
};

export default DeliveryManNewPassPage;

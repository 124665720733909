import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Alert } from "react-bootstrap";
import Styles from "./ConfirmOrderComp.module.css";
import { AiOutlineEdit } from "react-icons/ai";
import { IconButton, Paper } from "@material-ui/core";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetch_get_single_plate } from "../../Redux/Plates/plateActions";
import Loading from "./../../utils/Loading";
import DeliveryAddressModal from "./DeliveryAddressModal/DeliveryAddressModal";
import AddPhoneNumberModal from "./AddPhoneNumberModal/AddPhoneNumberModal";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import swal from "sweetalert";
import Axios from "axios";
import { useHistory } from "react-router-dom";

const ConfirmOrderComp = () => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const { single_plate_info } = useSelector((state) => state.plate);
  const [deliveryModal, setDeliveryModal] = React.useState(false);
  const [addPhoneModal, setAddPhoneModal] = React.useState(false);
  const [quantity, setQuantity] = React.useState(1);
  const [total_amount,setTotal_amount] = React.useState(0);
  const [note, setNote] = React.useState("");
  const [deliveryInfo, setDeliveryInfo] = useState("Delivery Man");
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const history = useHistory();

  useEffect(() => {
    dispatch(fetch_get_single_plate(productId));
  }, [productId]);


  useEffect(() => {
    if (single_plate_info) {
      setDeliveryCharge(
        single_plate_info?.deliveryCharge_info?.delivery_charge
      );
      setTotal_amount(
        single_plate_info?.deliveryCharge_info?.delivery_charge + single_plate_info?.plateInfo?.price * quantity
      )
    }
  }, [single_plate_info]);

  console.log(single_plate_info)

  if (!single_plate_info) {
    return (
      <div className="text-center">
        <Loading />
      </div>
    );
  }
  if (single_plate_info?.error) {
    return (
      <div className="text-center">
        <Alert className="text-center" variant="danger">
          {single_plate_info?.error}
        </Alert>
      </div>
    );
  }

  const { userInfo, address, plateInfo } = single_plate_info;
  const { price, plate_name, plate_images } = single_plate_info?.plateInfo;
console.log(price,plateInfo);
  const confirmOrderHandler = () => {
    if (!userInfo.phone) {
      return swal("", "Please add your phone number", "error");
    }
    if (!address || address?.address.length === 0) {
      return swal("", "Please add your delivery address", "error");
    }
    if (!deliveryInfo) {
      return swal("", "Please select any delivery option", "error");
    }
    const orderData = {
      delivery_address: address?.address[address?.address.length - 1],
      item_quantity: quantity,
      note,
      plate_info: productId,
      total_price: price * quantity + deliveryCharge,
      host_info: plateInfo.postedBy,
      deliveryCharge_info: single_plate_info?.deliveryCharge_info?._id,
    };
console.log(orderData)
    if (deliveryInfo.toLowerCase() === "self pickup") {
      orderData.self_pickup = true;
    }

    Axios.post("/api/create-order", orderData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        if (result.data?.success) {
          history.push("/my-orders");
          return swal("", "Order placed successfully", "success");
        } else {
          return swal("", "Something went wrong", "error");
        }
      })
      .catch((err) => {
        return swal("", "Something went wrong", "error");
      });
  };

  return (
    <div>
      <Container className="text-center">
        <span className={Styles.header__style}>USER INFORMATION</span>
        <div className="mt-4">
          <div className="mb-2">
            <b>Name : </b> <span>{userInfo.name}</span>
          </div>
          <div className="mb-2">
            <b>Email : </b> <span>{userInfo?.email || "Not added yet"}</span>
          </div>
          <div className="mb-2">
            <b>Phone : </b> <span>{userInfo?.phone || "Not added yet"}</span>
            <IconButton onClick={() => setAddPhoneModal(true)}>
              <AiOutlineEdit />
            </IconButton>
          </div>
        </div>
      </Container>
      <Container className="text-center py-3">
        <span className={Styles.header__style}>ADDRESS</span>
        <div className="d-flex align-items-center justify-content-center">
          {address ? (
            <span>{address?.address[address?.address.length - 1]}</span>
          ) : (
            <span style={{ color: "gray" }}>
              Please add your delivery address
            </span>
          )}
          <IconButton onClick={() => setDeliveryModal(true)}>
            <AiOutlineEdit />
          </IconButton>
        </div>
      </Container>
      <Container className="text-center py-3">
        <Row>
          <Col className="mx-auto" md={6}>
            <Paper className="p-4 shadow">
              <img
                className={Styles.plate_image_style}
                src={plate_images[0]}
                alt=""
              />
              <div className="mb-1">
                <b>Item name :</b> <span>{plate_name}</span>
              </div>

              <div className="mb-1">
                <b className="me-2">Quantity :</b>{" "}
                <button
                  onClick={() => {
                    if (quantity > 1 && plateInfo?.quantity >= quantity) {
                      setQuantity(quantity - 1);
                    }
                  }}
                  className={Styles.quantity_button_style}
                >
                  <AiOutlineMinus />
                </button>{" "}
                <span className="mx-2">{quantity}</span>
                <button
                  onClick={() => {
                    if (quantity > 0 && plateInfo?.quantity > quantity) {
                      setQuantity(quantity + 1);
                    }
                  }}
                  className={Styles.quantity_button_style}
                >
                  <AiOutlinePlus />
                </button>
              </div>

              <Form>
                <Form.Group className="my-3 d-flex">
                  <b style={{ width: "70px" }}>Note :</b>
                  <Form.Control
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                    as="textarea"
                    rows={2}
                  />
                </Form.Group>
                {/* <Form.Check
                  onChange={(e) => {
                    setDeliveryCharge(0);
                    setDeliveryInfo(e.target.value);
                  }}
                  inline
                  label="Self Pickup"
                  name="delivery"
                  type="radio"
                  id={`inline-radio-2`}
                  value="Self Pickup"
                /> */}
                {/* <Form.Check
                  onChange={(e) => {
                    setDeliveryCharge(60);
                    setDeliveryInfo(e.target.value);
                  }}
                  inline
                  label="Delivery"
                  name="delivery"
                  type="radio"
                  id={`inline-radio-3`}
                  value="Delivery Man"
                /> */}
              </Form>
              <span>Delivery Charge: {deliveryCharge}Taka</span>
              <h5 className="pt-3">Total cost(incl. vat)</h5>
              <button className="common_button_Style">
                {price * quantity + deliveryCharge} Taka
              </button>
            </Paper>
            <button
              type="submit"
              onClick={confirmOrderHandler}
              style={{ width: "100%" ,marginBottom:"60px"}}
              className="common_button_Style"
            >
              Confirm Order
            </button>
          </Col>
        </Row>
      </Container>

      {/* TODO:MODALS */}

      <DeliveryAddressModal
        show={deliveryModal}
        onHide={() => setDeliveryModal(false)}
      />
      <AddPhoneNumberModal
        show={addPhoneModal}
        onHide={() => setAddPhoneModal(false)}
      />
    </div>
  );
};

export default ConfirmOrderComp;

import React from "react";
import ViewDeliveryMans from "../../Components/AdminComps/DeliveryManComps/ViewDeliveryMans/ViewDeliveryMans";

const ViewDeliveryMansPage = () => {
  return (
    <div>
      <ViewDeliveryMans />
    </div>
  );
};

export default ViewDeliveryMansPage;

import React, { useEffect } from 'react';
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetch_get_reported_plate } from "../../Redux/Admin/AdminActions";
import SinglePlate from '../../Components/PlateCard/SinglePlate';
import Loading from '../../utils/Loading';
function ViewReportedPost() {
    const { reportedPostId } = useParams();
    const dispatch = useDispatch()

    const { reported_plate_info } = useSelector((state) => state.admin);

    useEffect(() => {
        dispatch(fetch_get_reported_plate(reportedPostId));
    }, []);

    if (!reported_plate_info) {
        return (
            <div className="text-center">
                <Loading />
            </div>
        );
    }
    return <div>
        <SinglePlate
            plate_name={reported_plate_info.plate_name}
            plate_price={reported_plate_info.price}
            plate_des={reported_plate_info.description}
            plate_images={reported_plate_info.plate_images}
        />
    </div>
}

export default ViewReportedPost;

import React, { useState } from "react";

import { Container, Form, Button } from "react-bootstrap";
import { Paper } from "@material-ui/core";
import { useFormik } from "formik";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Styles from "./AddAdmin.module.css";
import Axios from "axios";
import swal from "sweetalert";
import { useHistory } from "react-router";

const initialValues = {
  name: "",
  email: "",
  password: "",
};

const AddAdmin = () => {
  const history = useHistory();
  const [showPass, setShowPass] = useState(false);
  const validate = (values) => {
    const { name, email, password } = values;

    const errors = {};

    if (!name) {
      errors.name = "Please enter email";
    }
    if (!email) {
      errors.email = "Please enter email";
    }
    if (!password) {
      errors.password = "Please enter password";
    }

    return errors;
  };
  const onSubmit = (values) => {
    const { name, email, password } = values;
    const data = { name, email, password };

    Axios.post("/admin/api/add-admin", data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
      },
    })
      .then((result) => {
        console.log(result.data);
        if (result.data.success) {
          swal("", "Admin added successfully", "success");
          history.push("/admin/view-admins");
        } else {
          swal("", result.data.error, "error");
        }
      })
      .catch((err) => {
        swal("", err.response.data, "error");
      });
  };
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });
  return (
    <Container>
      <h4 className="text-center py-3">Add Admin</h4>
      <Paper className="p-4 shadow">
        <Form onSubmit={formik.handleSubmit}>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Name *</Form.Label>
            <Form.Control
              type="name"
              placeholder="Please enter name "
              name="name"
              {...formik.getFieldProps("name")}
            />
            <small style={{ color: "red" }}>
              {formik.touched.name &&
                formik.errors.name &&
                formik.errors.name}
            </small>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email *</Form.Label>
            <Form.Control
              type="email"
              placeholder="Please enter email "
              name="email"
              {...formik.getFieldProps("email")}
            />
            <small style={{ color: "red" }}>
              {formik.touched.email &&
                formik.errors.email &&
                formik.errors.email}
            </small>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password *</Form.Label> <br />
            <div className={Styles.password__filed__Style}>
              <input
                className={Styles.input_filed_style}
                type={showPass ? "text" : "password"}
                placeholder="Password"
                name="password"
                {...formik.getFieldProps("password")}
              />
              {showPass ? (
                <AiOutlineEye
                  onClick={() => {
                    setShowPass(!showPass);
                  }}
                  style={{ fontSize: "24px", cursor: "pointer" }}
                />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={() => {
                    setShowPass(!showPass);
                  }}
                  style={{ fontSize: "24px", cursor: "pointer" }}
                />
              )}
            </div>
            <small style={{ color: "red" }}>
              {formik.touched.password &&
                formik.errors.password &&
                formik.errors.password}
            </small>
          </Form.Group>

          <button className="common_button_Style" type="submit">
            Add Admin
          </button>
        </Form>
      </Paper>
    </Container>
  );
};

export default AddAdmin;

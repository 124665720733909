import Axios from "axios";
import swal from "sweetalert";
import {
  DELETE_MY_ORDER,
  GET_CUSTOMER_ORDERS,
  GET_MY_ORDERS,
  OWN_DELIVERY_ORDERS,
} from "./OrderTypes";

export const get_my_orders = (items) => {
  return {
    type: GET_MY_ORDERS,
    payload: items,
  };
};
export const delete_my_order = (items) => {
  return {
    type: DELETE_MY_ORDER,
    payload: items,
  };
};
export const get_customer_orders = (items) => {
  return {
    type: GET_CUSTOMER_ORDERS,
    payload: items,
  };
};

export const get_own_delivery_orders = (items) => {
  return {
    type: OWN_DELIVERY_ORDERS,
    payload: items,
  };
};

export const fetch_get_my_orders = () => {
  return (dispatch) => {
    fetch("/api/my-orders", {
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.length > 0) {
          dispatch(get_my_orders(result));
        } else {
          dispatch(get_my_orders({ text: "No order found" }));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(get_my_orders({ error: "Something went wrong" }));
      });
  };
};

export const fetch_get_customer_orders = () => {
  return (dispatch) => {
    fetch("/api/customer-orders", {
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.length > 0) {
          dispatch(get_customer_orders(result));
        } else {
          dispatch(get_customer_orders({ text: "No order found" }));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(get_customer_orders({ error: "Something went wrong" }));
      });
  };
};

export const fetch_updateOrderStatus = (orderId, order_status) => {
  return (dispatch) => {
    const data = {
      order_status,
    };
    Axios.put(`/api/update-order-status/${orderId}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        console.log(result.data);
        dispatch(fetch_get_customer_orders());
      })
      .catch((err) => {
        swal("", "Something went wrong", "error");
      });
  };
};

export const fetch_get_own_delivery_info = () => {
  return (dispatch) => {
    Axios.get("/api/own-delivery-orders", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        dispatch(get_own_delivery_orders(result.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetch_complete_my_delivery = (orderId) => {
  return (dispatch) => {
    fetch(`/api/delivery-complete/${orderId}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          dispatch(fetch_get_own_delivery_info());
          return swal("", result.success, "success");
        } else {
          return swal("", "Something went wrong", "error");
        }
      })
      .catch((error) => {
        return swal("", "Something went wrong", "error");
      });
  };
};

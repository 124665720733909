import React from "react";
import OrderCongrats from "../../Components/OrderCongratulations/OrderCongrats";

const OrderCongratsPage = () => {
  return (
    <div>
      <OrderCongrats />
    </div>
  );
};

export default OrderCongratsPage;

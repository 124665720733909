import React, { useEffect } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { Alert, Container, Table } from "react-bootstrap";
import CommonViewTitle from "../../../../utils/CommonViewTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetch_get_all_deliveryMethod } from "./../../../../Redux/DeliveryMethod/DeliveryMethodActions";
import Loading from "./../../../../utils/Loading";
import { useHistory } from "react-router-dom";

const ViewDeliveryMethods = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { all_deliveryMethods } = useSelector((state) => state.deliveryMethod);

  useEffect(() => {
    dispatch(fetch_get_all_deliveryMethod());
  }, []);

  if (!all_deliveryMethods) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (all_deliveryMethods?.error) {
    return (
      <Alert variant="danger" className="text-center">
        {all_deliveryMethods?.error}
      </Alert>
    );
  }
  return (
    <div>
      <Container>
        <CommonViewTitle
          title="Users"
          btnText="Add Delivery Method"
          url="/admin/add-delivery-method"
        />
        <Table bordered hover responsive className="text-center">
          <thead>
            <tr>
              {/* <th>ID</th> */}
              <th>Delivery Method</th>
              <th>Delivery Charge</th>

              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {all_deliveryMethods?.map((item, index) => {
              return (
                <tr key={index}>
                  {/* <td>{item._id}</td> */}
                  <td>{item.delivery_method}</td>
                  <td>{item.delivery_charge} Taka</td>

                  <td>
                    <BiEdit
                      onClick={() => {
                        history.push(
                          `/admin/update-delivery-method/${item._id}`
                        );
                      }}
                      className="edit_icon_Style"
                    />
                    {/* <MdDeleteOutline className="delete_icon_Style" /> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default ViewDeliveryMethods;

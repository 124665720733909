import React from "react";
import { Badge, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Styles from "./DeliveryManHeaders.module.css";

const DeliveryManHeadersComp = () => {
  return (
    <div>
      <div className={Styles.main__header}>
        <NavLink
          exact
          activeClassName={Styles.navActive__style}
          className={Styles.navitem__style}
          to="/delivery-man/orders"
        >
          Orders
        </NavLink>
        <NavLink
          exact
          activeClassName={Styles.navActive__style}
          className={Styles.navitem__style}
          to="/delivery-man/accepted-delivery"
        >
          Accepted
        </NavLink>
        {/* <NavLink
          exact
          activeClassName={Styles.navActive__style}
          className={Styles.navitem__style}
          to="/delivery-man/completed-delivery"
        >
          Completed
        </NavLink> */}
      </div>
    </div>
  );
};

export default DeliveryManHeadersComp;

import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import Axios from "axios";
import Loading from "./../../../../utils/Loading";
import swal from "sweetalert";
import { fetch_get_my_plates } from "../../../../Redux/MyPlates/myPlatesAction";
import { useDispatch } from "react-redux";

const initialValues = {
  plate_name: "",
  plate_price: "",
  plate_image: "",
};

const AddPlateModal = (props) => {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState("");
  const [load, setLoad] = useState(false);

  const validationSchema = yup.object({
    plate_name: yup.string().required("Please enter your plate name"),
    plate_price: yup.string().required("Please enter your plate name"),
  });

  const onSubmit = (values) => {
    const { plate_name, plate_price, plate_image } = values;

    const formData = new FormData();

    formData.append("plate_name", plate_name);
    formData.append("plate_price", plate_price);
    formData.append("plate_image", plate_image);
    setLoad(true);
    Axios.post("/api/add-plate", formData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        setLoad(false);
        console.log(result.data);
        if (result.data.success) {
          formik.values.plate_name = "";
          formik.values.plate_price = "";

          dispatch(fetch_get_my_plates());
          props.onHide();
          return swal("", result.data.success, "success");
        } else {
          return swal("", result.data.error, "error");
        }
      })
      .catch((err) => {
        setLoad(false);
        console.log(err.response.data.error);
        return swal("", err.response.data.error, "error");
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4 className="text-center py-3">ADD NEW PLATE</h4>

          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Plate name *</Form.Label>
              <Form.Control
                name="plate_name"
                {...formik.getFieldProps("plate_name")}
                type="text"
                placeholder="Please enter plate name"
              />
              <small style={{ color: "red" }}>
                {formik.touched.plate_name &&
                  formik.errors.plate_name &&
                  formik.errors.plate_name}
              </small>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Price *</Form.Label>
              <Form.Control
                name="plate_price"
                {...formik.getFieldProps("plate_price")}
                type="number"
                min="0"
                placeholder="Please enter plate name"
              />
              <small style={{ color: "red" }}>
                {formik.touched.plate_price &&
                  formik.errors.plate_price &&
                  formik.errors.plate_price}
              </small>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                name="plate_image"
                onChange={(e) => {
                  formik.setFieldValue("plate_image", e.target.files[0]);
                }}
                placeholder="Please enter plate name"
              />
            </Form.Group>
            <div className="text-center">
              {load ? (
                <div className="text-center">
                  <Loading />
                </div>
              ) : (
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "#372B2B",
                    color: "white",
                    marginRight: "10px",
                  }}
                  variant="contained"
                  // onClick={props.onHide}
                >
                  Save
                </Button>
              )}

              <Button
                style={{ color: "#372B2B" }}
                variant="outlined"
                onClick={props.onHide}
              >
                Close
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddPlateModal;

import React from "react";
import MyProfile from "./../../../Components/UserProfileComps/MyProfile/MyProfile";

const MyProfilePage = () => {
  return (
    <div>
      <MyProfile />
    </div>
  );
};

export default MyProfilePage;

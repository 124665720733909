import {
  DELIVERYMAN_ACCEPTED_ORDERS,
  GET_DELIVERY_MAN_UPCOMING_ORDERS,
} from "./DeliveryManTypes";

const init = {
  upcoming_orders: "",
  accepted_orders: "",
};

export const deliveryManReducer = (state = init, action) => {
  switch (action.type) {
    case GET_DELIVERY_MAN_UPCOMING_ORDERS: {
      return {
        ...state,
        upcoming_orders: action.payload,
      };
    }
    case DELIVERYMAN_ACCEPTED_ORDERS: {
      return {
        ...state,
        accepted_orders: action.payload,
      };
    }

    default:
      return state;
  }
};

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import HeaderAdmin from "../../Components/AdminComps/HeaderAdmin/HeaderAdmin";
import { Paper } from "@mui/material";
import logo from "../../assets/Images/Header/logo.svg";
import Styles from "./Index.module.css";
import SidebarAdmin from "../../Components/AdminComps/SidebarAdmin/SidebarAdmin";
import { Route, Switch } from "react-router";
import DashBoardPage from "./DashBoardPage";
import ViewUserListPage from "./ViewUserListPage";
import ViewOrdersPage from "./ViewOrdersPage";
import ViewAdminsPage from "./ViewAdminsPage";
import AddAdminPage from "./AddAdminPage";
import ViewTutorialsPage from "./ViewTutorialsPage";
import AddTutorialPage from "./AddTutorialPage";
import ViewDeliveryMansPage from "./ViewDeliveryMansPage";
import ViewDeliveryMethodsPage from "./ViewDeliveryMethodsPage";
import UpdateDeliveryMethodPage from "./UpdateDeliveryMethodPage";
import AddDeliveryChargePage from "./AddDeliveryChargePage";
import ViewReportInfoPage from "./ViewReportInfoPage";
import ViewReportedPost from "./ViewReportedPost";

const Index = () => {
  return (
    <div>
      <HeaderAdmin />
      <Container fluid>
        <Row>
          <Col md={3}>
            {" "}
            <SidebarAdmin />
          </Col>
          <Col md={9}>
            {" "}
            <Switch>
              {" "}
              <Route exact path="/admin/dashboard">
                <DashBoardPage />
              </Route>
              {/* ADMIN */}
              <Route exact path="/admin/view-admins">
                <ViewAdminsPage />
              </Route>
              <Route exact path="/admin/add-admin">
                <AddAdminPage />
              </Route>
              {/* USERS */}
              <Route exact path="/admin/view-users">
                <ViewUserListPage />
              </Route>
              {/* ORDERS */}
              <Route exact path="/admin/view-orders">
                <ViewOrdersPage />
              </Route>
              {/* Tutorial */}
              <Route exact path="/admin/view-tutorials">
                <ViewTutorialsPage />
              </Route>
              <Route exact path="/admin/add-tutorial">
                <AddTutorialPage />
              </Route>
              {/* Delivery Man */}
              <Route exact path="/admin/view-deliveryMans">
                <ViewDeliveryMansPage />
              </Route>
              {/* Report Info */}
              <Route exact path="/admin/view-reports">
                <ViewReportInfoPage/>
              </Route>
              <Route exact path="/admin/report-post/:reportedPostId">
                <ViewReportedPost/>
              </Route>
              {/* Delivery Method */}
              <Route exact path="/admin/view-delivery-methods">
                <ViewDeliveryMethodsPage />
              </Route>
              <Route exact path="/admin/update-delivery-method/:methodId">
                <UpdateDeliveryMethodPage />
              </Route>
              <Route exact path="/admin/add-delivery-method">
                <AddDeliveryChargePage />
              </Route>
            </Switch>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Index;

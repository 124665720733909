import React from "react";
import { useEffect } from "react";
import HomeComps from "../../Components/HomeComps/HomeComps";

const HomePage = () => {
  return (
    <div>
      <HomeComps />
    </div>
  );
};

export default HomePage;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Avatar from "@material-ui/core/Avatar";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import logoImg from "../../../assets/Images/Header/appBarLogo.svg";
import { FiShoppingBag } from "react-icons/fi";
import { RiAccountCircleLine } from "react-icons/ri";
import { useHistory } from "react-router";
import swal from "sweetalert";
import { AiOutlineLogout } from "react-icons/ai";

const useStyles = makeStyles((theme) => ({
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 0,
    height: 50,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
}));

const AppBarMobile = () => {
  const classes = useStyles();
  const history = useHistory();

  const accountHandler = () => {
    const user = localStorage.getItem("user_token");

    if (user) {
      history.push("/profile");
    } else {
      swal("", "Please login first to see your profile", "error");
      history.push("/login");
    }
  };
  return (
    <div>
      <AppBar
        position="fixed"
        style={{ backgroundColor: "#372B2B" }}
        className={classes.appBar}
      >
        <Toolbar>
          <IconButton
            style={{ cursor: "pointer" }}
            onClick={() => {
              localStorage.removeItem("user_token");
              localStorage.removeItem("delivery_man_token");
              history.push("/login");
            }}
            edge="start"
            color="inherit"
            aria-label="open drawer"
          >
            <AiOutlineLogout />
          </IconButton>
          <Fab
            onClick={() => {
              history.push("/");
            }}
            aria-label="add"
            className={classes.fabButton}
          >
            <Avatar style={{ objectFit: "contain" }} src={logoImg} alt="" />
          </Fab>
          <div className={classes.grow} />

          <IconButton onClick={accountHandler} edge="end" color="inherit">
            <RiAccountCircleLine />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default AppBarMobile;

import React from "react";
import { useParams } from "react-router-dom";
import Styles from "./Header.module.css";

const Header = ({ userId, headerInfo, setHeaderInfo }) => {
  const buttonHandler = (val) => {
    
    setHeaderInfo(val);
  };
  return (
    <div>
      <div className={Styles.main__header}>
        <button
          exact
          className={Styles.navitem__style}
          onClick={() => buttonHandler("Profile")}
        >
          Profile
        </button>
        <button
          exact
          className={Styles.navitem__style}
          onClick={() => buttonHandler("Plates")}
        >
          Plates
        </button>
        <button
          exact
          className={Styles.navitem__style}
          onClick={() => buttonHandler("Posts")}
        >
          Posts
        </button>
      </div>
    </div>
  );
};

export default Header;

import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetch_get_report_infos, fetch_delete_reported_post, fetch_ignore_reported_post } from "../../Redux/Admin/AdminActions";
import Loading from "./../../utils/Loading";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const ViewReportInfoPage = () => {
  const dispatch = useDispatch();
  const { report_info } = useSelector((state) => state.admin);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [plateId, setPlateId] = React.useState(false);
  const history = useHistory();

  const handleOpenDeleteDialog = (id) => {
    setOpenDeleteDialog(true);
    setPlateId(id);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setPlateId(false);
  };

  const handleDelete = () => {
    if (plateId) {
      dispatch(fetch_delete_reported_post(plateId));
      handleCloseDeleteDialog();
    }
  }

  const handleIgnore = (id) => {
    dispatch(fetch_ignore_reported_post(id));
    handleCloseDeleteDialog();
  }

  const handleShowReportedPost = (id) => {
    console.log('admin clicked to see the reported post...');
    history.push(`/admin/report-post/${id}`);
  }
  useEffect(() => {
    dispatch(fetch_get_report_infos());
  }, []);

  if (!report_info) {
    return (
      <>
        <Loading />
      </>
    );
  }

  //console.log(report_details)
  return (
    <div>
      <h2 className="text-center py-3">View Post Reports</h2>

      <Table
        striped
        bordered
        hover
        className="text-center"
        style={{ cursor: "pointer" }}
      >
        <thead>
          <tr>
            <th>Post Title</th>
            <th>Reports</th>
            <th>Total Report</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {report_info?.map((item, index) => {
            return (
              <tr key={index}>
                <td onClick={() => handleShowReportedPost(item._id)}>{item.plate_name}</td>
                <td><ul>{item.report.reporter.map((text, i) => {
                  return (
                    <li key={i}>{text.reportText}</li>
                  )
                })}</ul></td>
                <td>{item.report.reporter.length}</td>
                <td>
                  <Stack direction="row" spacing={2}>
                    <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => handleOpenDeleteDialog(item._id)}>
                      Delete
                    </Button>
                    <Button variant="contained" color="success" endIcon={<CheckCircleOutlineIcon />} onClick={() => handleIgnore(item._id)}>
                      Ignore
                    </Button>
                  </Stack>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Deleting Reported Post..?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting the post, the post'll no longer available in the database. So,are you going to delete the post..?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} autoFocus>Cancel</Button>
          <Button onClick={handleDelete} >Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewReportInfoPage;
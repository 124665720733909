import React, { useState, useEffect } from "react";
import { Alert, Container, Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Axios from "axios";
import DeliveryCard from "./DeliveryCard/DeliveryCard";
import Header from "./../Header/Header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetch_get_own_delivery_info } from "../../../Redux/Order/OrderActions";

const MyDeliveryComp = () => {
  const dispatch = useDispatch();
  const deliveryData = useSelector((state) => state.order?.own_delivery_data);

  useEffect(() => {
    dispatch(fetch_get_own_delivery_info());
  }, []);

  const delivery_man_token = localStorage.getItem("delivery_man_token");

  return (
    <div>
      <Header />
      <Container>
        <Row>
          {delivery_man_token ? (
            <Alert className="text-center" variant="info">
              <Link
                style={{ textDecoration: "none" }}
                to="/delivery-man/orders"
              >
                Switch as a delivery man and see your orders for delivery
              </Link>
            </Alert>
          ) : (
            <Alert className="text-center" variant="info">
              <Link style={{ textDecoration: "none" }} to="/delivery-man/login">
                Login
              </Link>{" "}
              or
              <Link
                style={{ textDecoration: "none", padding: "5px" }}
                to="/delivery-man/register"
              >
                Register
              </Link>
              as a delivery man.
            </Alert>
          )}

          <h4 className="text-center py-4">
            My Delivery ({deliveryData && deliveryData?.length})
          </h4>
          {deliveryData &&
            deliveryData.map((item) => {
              return (
                <Col md={4}>
                  {" "}
                  <DeliveryCard item={item} />{" "}
                </Col>
              );
            })}
        </Row>
      </Container>
    </div>
  );
};

export default MyDeliveryComp;

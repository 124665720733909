import React from "react";
import AddAdmin from '../../Components/AdminComps/AdminComps/AddAdmin/AddAdmin';

const AddAdminPage = () => {
  return (
    <div>
      <AddAdmin />
    </div>
  );
};

export default AddAdminPage;

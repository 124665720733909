import React from 'react';
import MyOrdersComp from '../../../Components/UserProfileComps/MyOrdersComp/MyOrdersComp';

const MyOrdersPage = () => {
  return (
    <div>
      <MyOrdersComp/>
    </div>
  );
};

export default MyOrdersPage;

import {
  GET_ALL_DELIVERY_METHODS,
  GET_SINGLE_DELIVERY_METHOD,
} from "./DeliveryMethodTypes";
import Axios from "axios";

export const get_all_deliveryMethod = (methods) => {
  return {
    type: GET_ALL_DELIVERY_METHODS,
    payload: methods,
  };
};

export const get_single_deliveryMethod = (method) => {
  return {
    type: GET_SINGLE_DELIVERY_METHOD,
    payload: method,
  };
};

export const fetch_get_all_deliveryMethod = () => {
  return async (dispatch) => {
    Axios.get("/api/all-delivery-methods", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        dispatch(get_all_deliveryMethod(result.data));
      })
      .catch((error) => {
        dispatch(get_all_deliveryMethod({ error: "Something went wrong" }));
      });
  };
};

export const fetch_get_single_deliveryMethod = (id) => {
  return async (dispatch) => {
    Axios.get(`/api/single-delivery-method/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        dispatch(get_single_deliveryMethod(result.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(get_single_deliveryMethod({ error: "Something went wrong" }));
      });
  };
};

import {
  DELIVERYMAN_ACCEPTED_ORDERS,
  GET_DELIVERY_MAN_UPCOMING_ORDERS,
} from "./DeliveryManTypes";
import Axios from "axios";
import swal from "sweetalert";

export const get_upcoming_orders = (items) => {
  return {
    type: GET_DELIVERY_MAN_UPCOMING_ORDERS,
    payload: items,
  };
};

export const get_accepted_orders = (items) => {
  return {
    type: DELIVERYMAN_ACCEPTED_ORDERS,
    payload: items,
  };
};

export const fetch_get_upcoming_orders = () => {
  return (dispatch) => {
    Axios.get("/api/delivery-man/view-orders", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("delivery_man_token"),
      },
    })
      .then((result) => {
        // console.log(result.data);
        dispatch(get_upcoming_orders(result.data));
      })
      .catch((error) => {
        dispatch(get_upcoming_orders({ error: "Something went wrong" }));
      });
  };
};

export const fetch_delivery_man_accept_order = (orderId) => {
  return (dispatch) => {
    fetch(`/api/delivery-man/accept-order/${orderId}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("delivery_man_token"),
      },
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.success) {
          dispatch(fetch_get_upcoming_orders());
          return swal("", result.success, "success");
        } else {
          return swal("", "Something went wrong", "error");
        }
      })
      .catch((err) => {
        console.log(err);
        return swal("", "Something went wrong", "error");
      });
  };
};

export const fetch_deliveryman_accepted_delivery_for_orders = () => {
  return (dispatch) => {
    Axios.get("/api/delivery-man/accepted-delivery", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("delivery_man_token"),
      },
    })
      .then((result) => {
        dispatch(get_accepted_orders(result.data));
      })
      .catch((error) => {
        dispatch(get_accepted_orders({ error: "Something went wrong" }));
      });
  };
};

import Axios from "axios";
import swal from "sweetalert";
import {
  GET_ADMIN_LIST,
  GET_COUNT_ITEMS,
  GET_ORDERS_LIST,
  GET_USER_LIST,
  GET_TUTORIAL_LIST,
  GET_DELIVERYMAN_INFOS,
  GET_REPORT_INFOS,
  GET_REPORT_DETAILS,
  GET_REPORTED_PLATE_INFO,
} from "./AdminTypes";

export const get_user_list = (users) => {
  return {
    type: GET_USER_LIST,
    payload: users,
  };
};

export const get_admin_list = (users) => {
  return {
    type: GET_ADMIN_LIST,
    payload: users,
  };
};

export const get_order_list = (orders) => {
  return {
    type: GET_ORDERS_LIST,
    payload: orders,
  };
};

export const get_count_items = (items) => {
  return {
    type: GET_COUNT_ITEMS,
    payload: items,
  };
};

export const get_tutorial_list = (items) => {
  return {
    type: GET_TUTORIAL_LIST,
    payload: items,
  };
};

export const get_report_info = (items) => {
  return {
    type: GET_REPORT_INFOS,
    payload: items,
  };
};

export const get_reported_plate = (plate) => {
  return {
    type: GET_REPORTED_PLATE_INFO,
    payload: plate,
  };
};

export const get_report_details = (items) => {
  return {
    type: GET_REPORT_DETAILS,
    payload: items,
  };
};

export const get_deliveryman_list = (items) => {
  return {
    type: GET_DELIVERYMAN_INFOS,
    payload: items,
  };
};

export const fetch_get_user_lists = () => {
  return (dispatch) => {
    Axios.get("/admin/api/get-all-users", {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
      },
    })
      .then((result) => {
        dispatch(get_user_list(result.data));
      })
      .catch((err) => {
        dispatch(get_user_list({ error: "Something went wrong" }));
      });
  };
};

export const fetch_get_admin_lists = () => {
  return (dispatch) => {
    Axios.get("/admin/api/get-all-admins", {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
      },
    })
      .then((result) => {
        dispatch(get_admin_list(result.data));
      })
      .catch((err) => {
        dispatch(get_admin_list({ error: "Something went wrong" }));
      });
  };
};

//add host certification action
export const add_certification_action = (userId) => {
  return async (dispatch) => {
    fetch(`/admin/api/add-certification/${userId}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        // console.log(result);
        dispatch(fetch_get_user_lists());
        return swal(
          "CERTIFICATION",
          "Certification added successfully...",
          "success"
        );
      })
      .catch((err) => {
        console.log(err);
        return swal(
          "CERTIFICATION",
          "Something went wrong during adding certification to the host...",
          "error"
        );
      });
  };
};

//edit host quota certification action
export const edit_host_quota_action = (userId, quota) => {
  return async (dispatch) => {
    fetch(`/admin/api/edit-host-quota/${userId}/${quota}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        // console.log(result);
        dispatch(fetch_get_user_lists());
        return swal("QUOTA", "Host Quota Edited Successfully...", "success");
      })
      .catch((err) => {
        console.log(err);
        return swal(
          "QUOTA",
          "Something went wrong during editing the quota...",
          "error"
        );
      });
  };
};
export const delete_admin_action = (admin_id) => {
  // console.log('deleting admin... ' + admin_id)
  return async (dispatch) => {
    Axios.delete(`/admin/api/delete-admin/${admin_id}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
      },
    })
      .then((result) => {
        console.log(result);
        dispatch(fetch_get_admin_lists());

        if (result.data.success) {
          dispatch(fetch_get_admin_lists());
          return swal("", "admin deleted successfully", "success");
        } else {
          return swal("", "Something went wrong ", "error");
        }
      })
      .catch((err) => {
        console.log(err);
        return swal("", "Something went wrong", "error");
      });
  };
};

export const delete_user_action = (user_id) => {
  // console.log('deleting admin... ' + admin_id)
  return async (dispatch) => {
    Axios.delete(`/admin/api/delete-user/${user_id}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
      },
    })
      .then((result) => {
        console.log(result);
        dispatch(fetch_get_user_lists());

        if (result.data.success) {
          dispatch(fetch_get_user_lists());
          return swal("", "user deleted successfully", "success");
        } else {
          return swal("", "Something went wrong ", "error");
        }
      })
      .catch((err) => {
        console.log(err);
        return swal("", "Something went wrong", "error");
      });
  };
};

export const delete_deliveryman_action = (user_id) => {
  // console.log('deleting admin... ' + admin_id)
  return async (dispatch) => {
    Axios.delete(`/admin/api/delete-deliveryman/${user_id}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
      },
    })
      .then((result) => {
        console.log(result);
        dispatch(fetch_get_deliveryMan_lists());

        if (result.data.success) {
          dispatch(fetch_get_deliveryMan_lists());
          return swal("", "Deliveryman deleted successfully", "success");
        } else {
          return swal("", "Something went wrong ", "error");
        }
      })
      .catch((err) => {
        console.log(err);
        return swal("", "Something went wrong", "error");
      });
  };
};

export const delete_tutorial_action = (tutorial_id) => {
  // console.log('deleting admin... ' + admin_id)
  return async (dispatch) => {
    Axios.delete(`/admin/api/delete-tutorial/${tutorial_id}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
      },
    })
      .then((result) => {
        console.log(result);
        dispatch(fetch_get_tutorial_lists());

        if (result.data.success) {
          dispatch(fetch_get_tutorial_lists());
          return swal("", "Tutorial deleted successfully", "success");
        } else {
          return swal("", "Something went wrong ", "error");
        }
      })
      .catch((err) => {
        console.log(err);
        return swal("", "Something went wrong", "error");
      });
  };
};

export const fetch_get_order_lists = () => {
  return (dispatch) => {
    Axios.get("/admin/api/get-all-orders", {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
      },
    })
      .then((result) => {
        dispatch(get_order_list(result.data));
      })
      .catch((err) => {
        dispatch(get_order_list({ error: "Something went wrong" }));
      });
  };
};

export const fetch_get_count_items = () => {
  return (dispatch) => {
    Axios.get("/admin/api/count-items", {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
      },
    })
      .then((result) => {
        dispatch(get_count_items(result.data));
      })
      .catch((err) => {
        dispatch(get_count_items({ error: "Something went wrong" }));
      });
  };
};

export const fetch_get_tutorial_lists = () => {
  return (dispatch) => {
    Axios.get("/api/view-tutorials")
      .then((result) => {
        dispatch(get_tutorial_list(result.data));
      })
      .catch((err) => {
        dispatch(get_tutorial_list({ error: "Something went wrong" }));
      });
  };
};

export const fetch_get_deliveryMan_lists = () => {
  return (dispatch) => {
    Axios.get("/admin/api/view-deliveryman-infos", {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
      },
    })
      .then((result) => {
        dispatch(get_deliveryman_list(result.data));
      })
      .catch((err) => {
        dispatch(get_deliveryman_list({ error: "Something went wrong" }));
      });
  };
};

export const fetch_get_report_infos = () => {
  return (dispatch) => {
    Axios.get("/admin/api/view-report-infos", {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
      },
    })
      .then((result) => {
        dispatch(get_report_info(result.data));
      })
      .catch((err) => {
        dispatch(get_report_info({ error: "Something went wrong" }));
      });
  };
};

export const fetch_get_report_details = (plateId) => {
  return (dispatch) => {
    Axios.get(`/admin/api/view-report-details/${plateId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
      },
    })
      .then((result) => {
        dispatch(get_report_details(result.data));
      })
      .catch((err) => {
        dispatch(get_report_details({ error: "Something went wrong" }));
      });
  };
};

export const fetch_delete_reported_post = (postId) => {
  return async (dispatch) => {
    Axios.delete(`/admin/api/delete-plate/${postId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
      },
    })
      .then((result) => {
        console.log(result);
        dispatch(fetch_get_report_infos());

        if (result.data.success) {
          dispatch(fetch_get_report_infos());
          return swal("", "Reported Post deleted successfully", "success");
        } else {
          return swal("", "Something went wrong ", "error");
        }
      })
      .catch((err) => {
        console.log(err);
        return swal("", "Something went wrong", "error");
      });
  };
};

export const fetch_ignore_reported_post = (postId) => {
  return async (dispatch) => {
    fetch(`/admin/api/ignore-plate/${postId}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        // console.log(result);
        dispatch(fetch_get_report_infos());
        return swal("", "Reported post ignored successfully", "success");
      })
      .catch((err) => {
        console.log(err);
        return swal(
          "",
          "Something went wrong during ignoring the reported post",
          "error"
        );
      });
  };
};

export const fetch_get_reported_plate = (plateId) => {
  return (dispatch) => {
    Axios.get(`/admin/api/reported-plate/${plateId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
      },
    })
      .then((result) => {
        dispatch(get_reported_plate(result.data.plateInfo));
      })
      .catch((err) => {
        console.log(err.response.data);
        dispatch(get_reported_plate({ error: "Something went wrong" }));
      });
  };
};

export const block_user_action = (user_id) => {
  // console.log('deleting admin... ' + admin_id)
  return async (dispatch) => {
    fetch(`/admin/api/block-user/${user_id}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        // console.log(result);
        dispatch(fetch_get_user_lists());
        return swal("", "User blocked successfully", "success");
      })
      .catch((err) => {
        console.log(err);
        return swal(
          "",
          "Something went wrong during blocking the user",
          "error"
        );
      });
  };
};

export const unblock_user_action = (user_id) => {
  // console.log('deleting admin... ' + admin_id)
  return async (dispatch) => {
    fetch(`/admin/api/unblock-user/${user_id}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        // console.log(result);
        dispatch(fetch_get_user_lists());
        return swal("", "User unblocked successfully", "success");
      })
      .catch((err) => {
        console.log(err);
        return swal(
          "",
          "Something went wrong unduring blocking the user",
          "error"
        );
      });
  };
};

export const block_deliveryman_action = (user_id) => {
  // console.log('deleting admin... ' + admin_id)
  return async (dispatch) => {
    fetch(`/admin/api/block-deliveryman/${user_id}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        // console.log(result);
        dispatch(fetch_get_deliveryMan_lists());
        return swal("", "deliveryman blocked successfully", "success");
      })
      .catch((err) => {
        console.log(err);
        return swal(
          "",
          "Something went wrong during blocking the deliveryman",
          "error"
        );
      });
  };
};

export const unblock_deliveryman_action = (user_id) => {
  // console.log('deleting admin... ' + admin_id)
  return async (dispatch) => {
    fetch(`/admin/api/unblock-deliveryman/${user_id}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        // console.log(result);
        dispatch(fetch_get_deliveryMan_lists());
        return swal("", "deliveryman unblocked successfully", "success");
      })
      .catch((err) => {
        console.log(err);
        return swal(
          "",
          "Something went wrong unduring blocking the deliveryman",
          "error"
        );
      });
  };
};

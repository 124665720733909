import React, { useEffect } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";

import { BsPeopleFill } from "react-icons/bs";
import { Paper } from "@material-ui/core";
import Styles from "./DashBoard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetch_get_count_items } from "../../../Redux/Admin/AdminActions";
import Loading from "./../../../utils/Loading";
import { useHistory } from "react-router-dom";

const DashBoard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { count_items } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(fetch_get_count_items());
  }, []);

  if (!count_items) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (count_items?.error) {
    return (
      <Alert variant="danger" className="text-center">
        {count_items?.error}
      </Alert>
    );
  }

  //   console.log(count_items);
  const { admins, orders, users } = count_items;
  return (
    <div className="mt-4">
      <Container>
        <Row>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push("/admin/view-admins");
            }}
            className="py-2 "
            md={3}
          >
            <Paper className="p-4 shadow text-center">
              <BsPeopleFill className={Styles.icon__style} /> <br />
              Admins
              <h4>{admins}</h4>
            </Paper>
          </Col>
          <Col
            onClick={() => {
              history.push("/admin/view-users");
            }}
            style={{ cursor: "pointer" }}
            className="py-2"
            md={3}
          >
            <Paper className="p-4 shadow text-center">
              <BsPeopleFill className={Styles.icon__style} /> <br />
              Users
              <h4>{users}</h4>
            </Paper>
          </Col>
          <Col
            onClick={() => {
              history.push("/admin/view-orders");
            }}
            style={{ cursor: "pointer" }}
            className="py-2"
            md={3}
          >
            <Paper className="p-4 shadow text-center">
              <BsPeopleFill className={Styles.icon__style} /> <br />
              Orders
              <h4>{orders}</h4>
            </Paper>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashBoard;

import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { Paper } from "@material-ui/core";
import { useHistory } from "react-router";
import swal from "sweetalert";
import Loading from "../../../../utils/Loading";

const initialValues = {
  title: "",
  video_link: "",
};

const AddTutorial = () => {
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState("");
  const [load, setLoad] = useState(false);
  const validate = (values) => {
    const { title, video_link } = values;
    let errors = {};

    if (!title) {
      errors.title = "Please enter your video title";
    }
    if (!video_link) {
      errors.video_link = "Please enter your video video link";
    }

    return errors;
  };
  const onSubmit = (values) => {
    const { title, video_link } = values;

    setLoad(true);
    fetch("/api/add-tutorial", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("admin_token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        video_link,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setLoad(false);
        if (result.success) {
          swal("", result.success, "success");
          return history.push("/admin/view-tutorials");
        } else {
          setErrorMsg(result.error);
        }
      })
      .catch((err) => {
        setLoad(false);
        setErrorMsg("Something went wrong");
      });
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });
  return (
    <div>
      <Container>
        <h4 className="text-center py-3">Add Tutorial</h4>
        <Paper className="p-4 shadow">
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Title *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please enter your video title"
                name="title"
                {...formik.getFieldProps("title")}
              />

              <small style={{ color: "red" }}>
                {formik.touched.title &&
                  formik.errors.title &&
                  formik.errors.title}
              </small>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Video Link *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please enter your video video link"
                name="video_link"
                {...formik.getFieldProps("video_link")}
              />

              <small style={{ color: "red" }}>
                {formik.touched.video_link &&
                  formik.errors.video_link &&
                  formik.errors.video_link}
              </small>
            </Form.Group>

            {load ? (
              <Loading />
            ) : (
              <button className="common_button_Style" type="submit">
                Add Tutorial
              </button>
            )}
          </Form>
        </Paper>
      </Container>
    </div>
  );
};

export default AddTutorial;

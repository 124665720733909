import React, { useEffect, useState } from "react";
import { delete_admin_action, fetch_get_admin_lists } from "../../../../Redux/Admin/AdminActions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loading from "./../../../../utils/Loading";
import { MdDeleteOutline } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { Container, Alert, Table } from "react-bootstrap";
import CommonViewTitle from "./../../../../utils/CommonViewTitle";
import jwt_decode from "jwt-decode";

const ViewAdmins = () => {
  const [adminInfo, setAdminInfo] = useState("");
  const dispatch = useDispatch();
  const { adminList } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(fetch_get_admin_lists());
    var decoded = jwt_decode(sessionStorage.getItem("admin_token"));
    setAdminInfo(decoded);
  }, []);

  if (!adminList) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (adminList?.error) {
    return (
      <Alert variant="danger" className="text-center">
        {adminList?.error}
      </Alert>
    );
  }

  const handleEdit = (admin_id) => {
    alert(admin_id + ' edit icon..');
  }


  const handleDelete = (admin_id) => {
    console.log('admin click to delete an admin...');
    dispatch(delete_admin_action(admin_id));
  }


  return (
    <div>
      <Container>
        <CommonViewTitle
          title="Admins"
          btnText="Add Admin"
          url="/admin/add-admin"
        />
        <Table bordered hover responsive className="text-center">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Admin Code</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {adminList?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.name}</td>

                  <td>{item?.email}</td>
                  <td>{item.admin_code}</td>
                  {adminInfo?._id === item._id ? (
                    <td>
                      {" "}
                      <BiEdit className="edit_icon_Style" onClick={() => handleEdit(item._id)} />
                    </td>
                  ) : (
                    <td>
                      <BiEdit className="edit_icon_Style" onClick={() => handleEdit(item._id)} />
                      <MdDeleteOutline className="delete_icon_Style" onClick={() => handleDelete(item._id)} />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default ViewAdmins;

import React from "react";
import { Container } from "react-bootstrap";
import cook from "../../assets/Images/OrderStatus/cook.svg";

const OrderStatus = () => {
  return (
    <div>
      <Container className="text-center">
        <h4 className="py-3">Food is cooking</h4>
        <img src={cook} alt="" />
      </Container>
    </div>
  );
};

export default OrderStatus;

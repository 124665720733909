import React from "react";
import DeliveryManForgotPass from "../../Components/DeliveryManComps/DeliveryManForgotPass/DeliveryManForgotPass";

const DeliveryManForgotPassPage = () => {
  return (
    <div>
      <DeliveryManForgotPass />
    </div>
  );
};

export default DeliveryManForgotPassPage;

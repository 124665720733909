import {
  GET_ALL_PLATES,
  GET_PAGINATE_PLATES,
  GET_MY_PLATES,
  GET_SINGLE_PLATE_INFO,
  GET_EDIT_PLATE_INFO,
  GET_FILTER_PLATE,
} from "./PlateTypes";

const init = {
  all_plates: "",
  my_plates: "",
  single_plate_info: "",
  edit_post_info: "",
  paginate_plates: "",
  filter_plates: "",
};

export const plateReducer = (state = init, action) => {
  switch (action.type) {
    case GET_ALL_PLATES:
      return {
        ...state,
        all_plates: action.payload,
      };

    case GET_PAGINATE_PLATES:
      return {
        ...state,
        paginate_plates: action.payload,
      };

    case GET_FILTER_PLATE:
      return {
        ...state,
        filter_plates: action.payload,
      };
    case GET_MY_PLATES:
      return {
        ...state,
        my_plates: action.payload,
      };

    case GET_SINGLE_PLATE_INFO: {
      return {
        ...state,
        single_plate_info: action.payload,
      };
    }
    case GET_EDIT_PLATE_INFO: {
      return {
        ...state,
        edit_post_info: action.payload,
      };
    }

    default:
      return state;
  }
};

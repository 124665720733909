import React, { useState } from "react";
import { Col, Container, Form } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Styles from "./DeliveryManForgotPass.module.css";
import { Paper, Button } from "@material-ui/core";
import loginImg from "../../../assets/Images/Login/Rectangle 11 (1).png";
// import { Link } from "react-router-dom";
// import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useFormik } from "formik";
import swal from "sweetalert";
import { useHistory } from "react-router";
import plateLogo from "../../../assets/Images/Login/plateLogo.svg";
import { useSpring, animated } from "react-spring";
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

const initialValues = {
  email: "",
  phone: "",
  otp_code: "",
};

const DeliveryManForgotPass = () => {
  const [emailType, setEmailType] = useState("phone");
  const [phoneType, setPhoneType] = useState("");

  const [isDisable, setIsDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const emailButtonHandler = (type) => {
    setEmailType(type);
    setPhoneType("");
  };
  const phoneButtonHandler = (type) => {
    setEmailType("");
    setPhoneType(type);
  };

  const validate = (values) => {
    const { phone, otp_code } = values;

    let errors = {};

    if (!phone) {
      errors.phone = "Please enter your phone number";
    }

    if (!otp_code) {
      errors.otp_code = "Please enter your verification code";
    }

    return errors;
  };
  const onSubmit = (values) => {
    const { phone, otp_code } = values;

    let forgotPassData;

    forgotPassData = {
      phone,
      otp_code,
    };

    console.log(forgotPassData);
    fetch("/api/delivery-man/forgot-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(forgotPassData),
    })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        if (result.success) {
          sessionStorage.setItem(
            "deliveryMan_forgotPassData",
            JSON.stringify(forgotPassData)
          );
          history.push("/delivery-man/new-password");
          return swal("", result.success, "success");
        } else {
          return swal("", result.error, "error");
        }
      })
      .catch((err) => {
        return swal("", "Something went wrong", "error");
      });
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  const verificationCodeHandler = () => {
    let only_phone_number = 0;
    setLoading(true);
    const { phone } = formik.values;
    if (phone) {
      // regualr expression checking to remove country code and extra spaces and dash
      only_phone_number = phone.replace(/\D/g, '').slice(-11);
      console.log(only_phone_number);
      if (only_phone_number.length !== 11) {
        setLoading(false);
        return swal("", "Please provide valid phone number", "error");
      }
    }
    let verifyData;
    verifyData = {
      value: phone,
      verifyType: "changePass_deliveryMan",
    };

    fetch("/api/verify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(verifyData),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          setLoading(false);
          setIsDisable(true)
          wait3min();
          return swal("", result.success, "success");
        } else {
          setLoading(false);
          return swal("", result.error, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return swal("", "Something went wrong", "error");
      });
  };
  //react-spring animation props
  const props = useSpring({
    to: { x: 0 },
    from: { x: -80 },
    config: { duration: 750 },
  });

   //count down timer for verficiation code resend...
   const wait3min = () => {
    setTimeout(() => { setIsDisable(false) }, 180000);
  }


  //console.log(formik.errors);

  return (
    <div className={Styles.main__div}>
      <Container className="mt-4 ">
        <Row>
          <Col className="mx-auto" md={10}>
            <Paper className="shadow">
              <Row>
                <Col md={6}>
                  <div className={Styles.left__style}>
                    <img src={loginImg} alt="" />
                    <div className={Styles.overlay__style}>
                      <div className={Styles.content__style}>
                        <img
                          style={{ width: "130px", height: "130px" }}
                          src={plateLogo}
                          alt=""
                        />
                        {/* react-spring animated div */}
                        <animated.div style={props}>
                          <h4>HOMEMADE </h4>
                          <h1>FOOD</h1>
                          <h6>AT YOUR DOORSTEP</h6>
                        </animated.div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="px-5 pt-5 pb-4" md={6}>
                  <div className="text-center">
                    <img
                      className={Styles.myPlate__logo__style}
                      src={plateLogo}
                      alt=""
                    />
                  </div>
                  <h4 className="text-center py-4" style={{ color: "#372B2B" }}>
                    Forgot Your Password
                  </h4>

                  <Form onSubmit={formik.handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone Number *</Form.Label> <br />
                      <Form.Control
                        type="text"
                        placeholder="Please enter your phone number "
                        name="phone"
                        {...formik.getFieldProps("phone")}
                      />
                      <small style={{ color: "red" }}>
                        {formik.touched.phone &&
                          formik.errors.phone &&
                          formik.errors.phone}
                      </small>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Verification code *</Form.Label> <br />
                      <LoadingButton
                        onClick={verificationCodeHandler}
                        endIcon={<SendIcon />}
                        disabled={isDisable}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        style={{
                          backgroundColor: '#958470',
                          marginBottom: '10px',
                          '&:hover': {
                            backgroundColor: '#372B2B',
                            color: 'white',
                          }
                        }}
                      >
                        Send Code
                      </LoadingButton>
                      {isDisable && <p style={{ color: '#958470' }}>plz wait atleast 3 mins before resending the code</p>}
                        <Form.Control
                          className={Styles.sendCodeInputField__style}
                          type="text"
                          placeholder="Please enter your verification code"
                          name="otp_code"
                          {...formik.getFieldProps("otp_code")}
                        />
                        {/* <button
                          disabled={isDisable}
                          type="button"
                          onClick={verificationCodeHandler}
                          className={Styles.sendCodeButton__Style}
                        >
                          Send code
                        </button> */}
                      <small style={{ color: "red" }}>
                        {formik.touched.otp_code &&
                          formik.errors.otp_code &&
                          formik.errors.otp_code}
                      </small>
                    </Form.Group>

                    <Button
                      style={{
                        width: "100%",
                        color: "white",
                        backgroundColor: "#372B2B",
                        marginTop: "7px",
                      }}
                      variant="contained"
                      type="submit"
                    >
                      Forgot Password
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Paper>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DeliveryManForgotPass;

import React from "react";
import { Paper } from "@mui/material";
import logo from "../../../assets/Images/Header/logo.svg";
import Styles from "./SidebarAdmin.module.css";
import { Col, Container, Row } from "react-bootstrap";
import SidebarItems from "./SidebarItems/SidebarItems";

const SidebarAdmin = () => {
  return (
    <div>
      <div>
        <Paper className="p-4 shadow">
          <img className={Styles.sidebarLogo__style} src={logo} />
          <hr />
          <SidebarItems />
        </Paper>
      </div>
    </div>
  );
};

export default SidebarAdmin;

import React from "react";
import MyPosts from "../../../Components/UserProfileComps/MyPosts/MyPosts";

const MyPostsPage = () => {
  return (
    <div>
      <MyPosts />
    </div>
  );
};

export default MyPostsPage;

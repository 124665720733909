import React, { useEffect, useState } from "react";
import logo from "../../assets/Images/Header/logo.svg";
import Styles from "./Header.module.css";
import { GiCook } from "react-icons/gi"
import { NavLink, Link } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import AppBarMobile from "./AppBarMobile/AppBarMobile";
import { withRouter, useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { fetch_get_profile_info } from "../../Redux/profile/profileAction";

import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import RestaurantIcon from '@mui/icons-material/Restaurant';



const Header = () => {
  const user_token = localStorage.getItem("user_token");
  const history = useHistory();

  // console.log(decoded);

  const { profileInfo } = useSelector((state) => state.profile);
  const { user_plates } = useSelector((state) => state.myPlates);
  const dispatch = useDispatch();
  // const [headerInfo, setHeaderInfo] = useState("Profile");

  // console.log(profileInfo);

  useEffect(() => {
    if (user_token) {
      var decoded = jwt_decode(user_token);
      dispatch(fetch_get_profile_info(decoded._id));
    }
  }, [user_token]);
  return (
    <div style={{ position: "sticky", top: "0px", zIndex: "999" }}>
      <div className={Styles.mainNavbar__style}>
        <Link to="/" className={Styles.left__Style}>
          <img className={Styles.logImage__style} src={logo} alt="" />
        </Link>
        <div className={Styles.middle__Style}>
          <NavLink
            to="/"
            exact
            activeClassName={Styles.activeNavItems__style}
            className={Styles.navItems_Style}
          >
            <RestaurantIcon style={{ marginRight: "6px" }} />
            <span> Explore</span>
          </NavLink>
          <NavLink
            to="/host"
            exact
            activeClassName={Styles.activeNavItems__style}
            className={Styles.navItems_Style}
          >
            <GiCook style={{ marginRight: "6px" }} />
            <span> Host</span>
          </NavLink>
          <NavLink
            to="/my-delivery"
            exact
            activeClassName={Styles.activeNavItems__style}
            className={Styles.navItems_Style}
          >
            <DeliveryDiningIcon style={{ marginRight: "6px" }} />
            <span> DELIVERY</span>
          </NavLink>
          <NavLink
            to="/tutorial"
            exact
            activeClassName={Styles.activeNavItems__style}
            className={Styles.navItems_Style}
          >
            <OndemandVideoIcon style={{ marginRight: "6px" }} />
            <span>TUTORIAL</span>
          </NavLink>
        </div>

        <div className={Styles.right__Style}>
          {user_token ? (
            <div className={Styles.dropdown}>
              <div className={Styles.dropbtn}>
                <Avatar
                  src={profileInfo?.profile_image || ""}
                  style={{ height: "45px", width: "45px" }}
                />
              </div>
              <div className={Styles.dropdown__content}>
                <Link to="/profile">Profile</Link>
                <Link to="/my-plates">My plates</Link>
                <Link to="/my-posts">Posts</Link>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    localStorage.removeItem("user_token");
                    localStorage.removeItem("delivery_man_token");
                    history.push("/login");
                  }}
                >
                  Logout
                </p>
              </div>
            </div>
          ) : (
            <NavLink
              to="/login"
              exact
              activeClassName={Styles.activeNavItems__style}
              className={Styles.navItems_Style}
            >
              Login
            </NavLink>
          )}
        </div>
      </div>
      <div className={Styles.mobile__appbar}>
        <AppBarMobile />
      </div>
    </div>
  );
};

export default withRouter(Header);

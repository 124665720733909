import { Paper } from "@material-ui/core";
import React from "react";
import Styles from "./UserProfile.module.css";

const UserProfile = ({
  email,
  profile_image,
  name,
  avatarImg,
  total_plates,
  userPlates,
}) => {
  return (
    <div>
      <Paper className="p-4 shadow">
        <div className={Styles.userProfileSection__Style}>
          {profile_image ? (
            <img
              className={Styles.userImage_style}
              src={profile_image}
              alt=""
            />
          ) : (
            <img className={Styles.userImage_style} src={avatarImg} alt="" />
          )}

          <div className="mt-3">
            <b>{name}</b>
            <p
              style={{
                marginBottom: "5px",
                fontSize: "13px",
                color: "gray",
              }}
            >
              {email}
            </p>
            <p style={{ marginBottom: "5px" }}>Total Posts: {total_plates}</p>
            <p style={{ marginBottom: "5px" }}>
              Total Plates: {userPlates?.length}
            </p>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default UserProfile;

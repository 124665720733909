import React, { useEffect } from "react";
import { Alert, Badge, Container } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../Header/Header";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import Axios from "axios";
import { fetch_get_my_orders } from "./../../../Redux/Order/OrderActions";
import Loading from "../../../utils/Loading";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const MyOrdersComp = () => {
  const dispatch = useDispatch();
  const { my_orders } = useSelector((state) => state.order);
  const [orderId, setOrderId] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  useEffect(() => {
    dispatch(fetch_get_my_orders());
  }, []);

  // delete the pending post api controller
  const deleteOrder = async (orderId) => {
    Axios.delete(`/api/delete-order/${orderId}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((result) => {
        console.log(result);
        dispatch(fetch_get_my_orders());

        if (result.data.success) {
          dispatch(fetch_get_my_orders());
          return swal("", "Order deleted successfully", "success");
        } else {
          return swal("", "Something went wrong ", "error");
        }
      })
      .catch((err) => {
        console.log(err);
        return swal("", "Something went wrong", "error");
      });
  };

  const deleteOrderHandler = () => {
    setOpenDeleteDialog(false);
    deleteOrder(orderId);
  };

  const handleClickOpenDeleteDialog = (orderId) => {
    setOpenDeleteDialog(true);
    setOrderId(orderId);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setOrderId(false);
  };

  if (!my_orders) {
    return (
      <div className="text-center">
        <Loading />
      </div>
    );
  }

  if (my_orders?.error) {
    return (
      <Alert className="alert alert-danger text-center">
        {my_orders?.error}
      </Alert>
    );
  }
  if (my_orders?.text) {
    return (
      <>
        <Header />
        <Container>
          <Alert className="alert alert-info text-center">
            {my_orders?.text}
          </Alert>
        </Container>
      </>
    );
  }

  return (
    <>
      <Header />
      <Container>
        <h4 className="text-center py-3">My Orders</h4>
        <Table>
          <Thead>
            <Tr>
              {/* <Th>OrderId</Th> */}
              <Th>Plate</Th>
              <Th>Host</Th>
              <Th>Phone</Th>
              <Th>Status</Th>
              <Th>Quantity</Th>
              <Th>Price</Th>
            </Tr>
          </Thead>
          <Tbody>
            {my_orders?.map(
              (
                {
                  order_id,
                  plate_info,
                  item_quantity,
                  total_price,
                  order_status,
                  host_info,
                },
                index
              ) => {
                return (
                  <Tr key={index}>
                    <Td>{plate_info?.plate_name}</Td>
                    <Td>{host_info?.name}</Td>
                    <Td>{host_info?.phone}</Td>
                    <Td>
                      {order_status === "Confirmed" && (
                        <Badge bg="success">{order_status}</Badge>
                      )}
                      {order_status === "Completed" && (
                        <Badge bg="light" text="dark">
                          {order_status}
                        </Badge>
                      )}
                      {order_status === "Cancelled" && (
                        <Badge bg="danger">{order_status}</Badge>
                      )}
                      {order_status === "Pending" && (
                        <>
                          <Badge bg="warning" text="dark">
                            {order_status}
                          </Badge>

                          <IconButton aria-label="delete">
                            <DeleteIcon
                              onClick={() =>
                                handleClickOpenDeleteDialog(order_id)
                              }
                            />
                          </IconButton>
                        </>
                      )}
                      {order_status === "Pick Up" && (
                        <>
                          <Badge bg="secondary">{order_status}</Badge>
                        </>
                      )}
                    </Td>
                    <Td>{item_quantity}</Td>
                    <Td>{total_price}</Td>
                  </Tr>
                );
              }
            )}
          </Tbody>
        </Table>
        <h4 className="text-center py-5">________</h4>

        <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete this  order?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are going to delete this order. You can't undo this operation.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} autoFocus>
              Cancel
            </Button>
            <Button onClick={deleteOrderHandler}>Delete</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default MyOrdersComp;

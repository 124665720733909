import { Container, Table } from "react-bootstrap";
import Header from "./../Header/Header";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { TableFooter, TablePagination } from "@material-ui/core";
import Axios from "axios";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import Styles from "./MyPlates.module.css";
import { AiOutlinePlus } from "react-icons/ai";
import AddPlateModal from "./AddPlateModal/AddPlateModal";
import { useDispatch, useSelector } from "react-redux";
import { fetch_get_my_plates } from "../../../Redux/MyPlates/myPlatesAction";
import Loading from "./../../../utils/Loading";
import { Alert } from "react-bootstrap";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    height: 500,
    width: "100%",
    paddingTop: "30px",
  },
});

const MyPlates = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { my_plates } = useSelector((state) => state.myPlates);

  const [modalShow, setModalShow] = React.useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(fetch_get_my_plates());
  }, []);

  //   const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!my_plates) {
    return (
      <div className="text-center">
        <Loading />
      </div>
    );
  }

  if (my_plates.error) {
    return (
      <div>
        <Alert className="text-center" variant="danger">
          {my_plates.error}
        </Alert>
      </div>
    );
  }
  return (
    <div>
      <Header />
      <Container>
        <div className={Styles.heading__Style}>
          <h4>My plates</h4>
          <button
            onClick={() => setModalShow(true)}
            className={Styles.button__Style}
          >
            <AiOutlinePlus style={{ marginRight: "5px" }} />
            Add plate
          </button>
        </div>

        <Table className="text-center mt-3" striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Image</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {my_plates?.myPlates?.map((item) => {
              return (
                <tr key={item._id}>
                  <td>{item.plate_name}</td>
                  <td>
                    <img
                      style={{
                        width: "100px",
                        height: "50px",
                        objectFit: "cover",
                      }}
                      src={item.plate_image}
                      alt=""
                    />
                  </td>
                  <td>{item.plate_price}tk</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>

      {/* ADD PLATE MODAL */}
      <AddPlateModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default MyPlates;

export const GET_USER_LIST = "GET_USER_LIST";
export const GET_ADMIN_LIST = "GET_ADMIN_LIST";
export const GET_ORDERS_LIST = "GET_ORDERS_LIST";
export const GET_COUNT_ITEMS = "GET_COUNT_ITEMS";
export const GET_TUTORIAL_LIST = "GET_TUTORIAL_LIST";
export const GET_DELIVERYMAN_INFOS = "GET_DELIVERYMAN_INFOS";
export const GET_REPORT_INFOS = "GET_REPORT_INFOS";
export const GET_REPORT_DETAILS = "GET_REPORT_DETAILS";
export const DELETE_REPORTED_POST = "DELETE_REPORTED_POST";
export const GET_REPORTED_PLATE_INFO = "GET_REPORTED_PLATE_INFO";

import { Paper, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import swal from "sweetalert";
import Axios from "axios";
import { useHistory } from "react-router";
import Loading from "../../utils/Loading";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetch_get_edit_plate_info } from '../../Redux/Plates/plateActions';
import Skeleton from '@mui/material/Skeleton';


function EditPost() {

    const { postId } = useParams();
    const { edit_post_info } = useSelector((state) => state.plate);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetch_get_edit_plate_info(postId));
    }, []);

    const history = useHistory();
    const [load, setLoad] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");

    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [location, setLocation] = useState('');
    const [quantity, setQuantity] = useState('');
    const [description, setDescription] = useState('');

    const onSubmitHandler = (e) => {
        e.preventDefault();
        // creating the data for passing through the api...
        const updateData = {
            plate_name: name,
            quantity: quantity,
            price: price,
            location: location,
            description: description
        }

        setLoad(true);

        //sending the updated data to server for updating...
        Axios.put(`/api/update-plate/${postId}`, updateData, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user_token"),
                "Content-Type": "application/json",
            },
        })
            .then((result) => {
                setLoad(false);
                if (result.data.error) {
                    return setErrorMsg(result.data.error);
                } else {
                    swal("", result.data.success, "success");
                    history.push("/");
                }
            })
            .catch((err) => {
                setLoad(false);
                swal("", "Something went wrong", "error");
            });
    };

    // update the satate value when fetching is complete...
    useEffect(() => {
        setName(edit_post_info.plate_name);
        setPrice(edit_post_info.price);
        setQuantity(edit_post_info.quantity);
        setLocation(edit_post_info.location);
        setDescription(edit_post_info.description);
    }, [edit_post_info]);

    if (!edit_post_info) {

        return (
            <div>
            <Container className="mb-5 pb-5">
                <h4 className="text-center py-3">Update Your Plate</h4>
                <h6 className="text-center">You can only update the following fileds....</h6>

                <Row>
                    <Col className="mx-auto" md={8}>
                        <Paper className="p-4 shadow">
                            {errorMsg && (
                                <Alert variant="danger" className="text-center">
                                    {errorMsg}
                                </Alert>
                            )}

                            <Form onSubmit={onSubmitHandler}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Plate name *</Form.Label>
                                    <Skeleton height={50}/>
                                </Form.Group>

                                <Row>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Plate price *</Form.Label>
                                            <Skeleton height={50}/>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Group className="mb-3">
                                    <Form.Label>Plate quantity *</Form.Label>
                                    <Skeleton height={50}/>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Your location *</Form.Label>
                                    <Skeleton height={50}/>
                                </Form.Group>

                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Description</Form.Label>
                                    <Skeleton height={150}/>
                                </Form.Group>
                            </Form>

                        </Paper>
                    </Col>
                </Row>
            </Container>
        </div>
        )

    } else {

        return (
            <div>
                <Container className="mb-5 pb-5">
                <h4 className="text-center py-3">Update Your Plate</h4>
                <h6 className="text-center">You can only update the following fileds....</h6>

                    <Row>
                        <Col className="mx-auto" md={8}>
                            <Paper className="p-4 shadow">
                                {errorMsg && (
                                    <Alert variant="danger" className="text-center">
                                        {errorMsg}
                                    </Alert>
                                )}

                                <Form onSubmit={onSubmitHandler}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Plate name *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Please enter plate name"
                                            name="plate_name"
                                            value={name || ''}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Plate price *</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Please enter plate price"
                                                    name="price"
                                                    value={price || ''}
                                                    onChange={(e) => setPrice(e.target.value)}
                                                />

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Plate quantity *</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Please enter plate quantity"
                                            name="quantity"
                                            value={quantity || ''}
                                            onChange={(e) => setQuantity(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Your location *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Please enter your current location"
                                            name="location"
                                            value={location || ''}
                                            onChange={(e) => setLocation(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={5}
                                            placeholder="Please enter your plate description"
                                            name="description"
                                            value={description || ''}
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </Form.Group>


                                    <div className="text-center mt-4">
                                        {load ? (<>
                                            <Loading />
                                        </>
                                        ) : (
                                            <Button
                                                style={{ backgroundColor: "#958470" }}
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                            >
                                                Update Plate
                                            </Button>
                                        )}
                                    </div>
                                </Form>

                            </Paper>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
};

export default EditPost;

import React from "react";
import DeliveryManLoginComp from "./../../Components/DeliveryManComps/DeliveryManLoginComp/DeliveryManLoginComp";

const DeliveryManLoginPage = () => {
  return (
    <div>
      <DeliveryManLoginComp />
    </div>
  );
};

export default DeliveryManLoginPage;

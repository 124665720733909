import React from "react";
import DeliveryManRegisterComp from "./../../Components/DeliveryManComps/DeliveryManRegisterComp/DeliveryManRegisterComp";

const DeliveryManRegisterPage = () => {
  return (
    <div>
      <DeliveryManRegisterComp />
    </div>
  );
};

export default DeliveryManRegisterPage;

import React, { useState } from "react";
import { Col, Container, Form } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Styles from "./RegisterComp.module.css";
import { Paper, Button } from "@material-ui/core";
import loginImg from "../../assets/Images/Login/Rectangle 11 (1).png";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useFormik } from "formik";
import swal from "sweetalert";
import { useHistory } from "react-router";
import plateLogo from "../../assets/Images/Login/plateLogo.svg";
import { useSpring, animated } from "react-spring";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
const initialValues = {
  email: "",
  phone: "",
  name: "",
  otp_code: "",
  password: "",
  confirmPassword: "",
  promoCode: "",
};

const RegisterComp = () => {
  const [emailType, setEmailType] = useState("");
  const [phoneType, setPhoneType] = useState("phone");
  const [passType, setPassType] = useState("password");
  const [passType2, setPassType2] = useState("password");
  const [isDisable, setIsDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const emailButtonHandler = (type) => {
    setEmailType(type);
    setPhoneType("");
  };
  const phoneButtonHandler = (type) => {
    setEmailType("");
    setPhoneType(type);
  };

  const validate = (values) => {
    const {
      name,
      email,
      phone,
      otp_code,
      password,
      confirmPassword,
      promoCode,
    } = values;

    let errors = {};

    if (!name) {
      errors.name = "Please enter your full name";
    }
    // promocode is optional

    // if (!promoCode) {
    //   errors.promoCode = "You have not given any Promo Code";
    // }

    if (emailType && !email) {
      errors.email = "Please enter your full email";
    }
    if (phoneType && !phone) {
      errors.phone = "Please enter your phone number";
    }

    if (!otp_code) {
      errors.otp_code = "Please enter your verification code";
    }

    if (!password) {
      errors.password = "Please enter your password";
    } else if (password.length < 6) {
      errors.password = "Password at least 6 characters";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Please enter your confirmPassword";
    } else if (confirmPassword !== password) {
      errors.confirmPassword = "Password not matched";
    }

    return errors;
  };
  const onSubmit = (values) => {
    const { name, email, phone, otp_code, password, promoCode } = values;

    let registerData;

    if (emailType) {
      registerData = {
        name,
        email,
        otp_code,
        password,
        promoCode,
        registerBy: emailType,
      };
    } else if (phoneType) {
      registerData = {
        name,
        phone,
        otp_code,
        password,
        promoCode,
        registerBy: phoneType,
      };
    }

    fetch("/api/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(registerData),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          history.push("/login");
          return swal("", result.success, "success");
        } else {
          return swal("", result.error, "error");
        }
      })
      .catch((err) => {
        return swal("", "Something went wrong", "error");
      });
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  //async await verification code sender function
  const verificationCodeHandler = async () => {
    let only_phone_number = 0;
    setLoading(true);
    const { email, phone } = formik.values;
    let verifyData;
    if (!email && !phone) {
      setLoading(false);
      return swal(
        "",
        "Please provide phone or email to send your verification code",
        "error"
      );
    }

    if (phone) {
      // regualr expression checking to remove country code and extra spaces and dash
      only_phone_number = phone.replace(/\D/g, "").slice(-11);
      console.log(only_phone_number);
      if (only_phone_number.length !== 11) {
        setLoading(false);
        return swal("", "Please provide valid phone number", "error");
      }
    }

    if (emailType) {
      verifyData = {
        value: email,
        registerBy: emailType,
      };
    } else if (phoneType) {
      verifyData = {
        value: only_phone_number,
        registerBy: phoneType,
      };
    }

    await fetch("/api/verify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(verifyData),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          setLoading(false);
          setIsDisable(true);
          wait3min();
          return swal("", result.success, "success");
        } else {
          setLoading(false);
          return swal("", result.error, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return swal("", "Something went wrong", "error");
      });
  };
  //react-spring animation props..
  const props = useSpring({
    to: { x: 0 },
    from: { x: -80 },
    config: { duration: 750 },
  });

  //count down timer for verficiation code resend...
  const wait3min = () => {
    setTimeout(() => {
      setIsDisable(false);
    }, 180000);
  };
  const steps = [
    {
      label: "Name and Password",
      description: (
        <>
          <Form.Group className="mb-3">
            <Form.Label>
              Your Name <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Firstname Lastname"
              name="name"
              {...formik.getFieldProps("name")}
            />
            <small style={{ color: "red" }}>
              {formik.touched.name && formik.errors.name && formik.errors.name}
            </small>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Password <span style={{ color: "red" }}>*</span>
            </Form.Label>{" "}
            <br />
            <div className={Styles.passwordField__Style}>
              <input
                type={passType}
                placeholder="Password"
                name="password"
                {...formik.getFieldProps("password")}
              />
              {passType === "password" ? (
                <AiOutlineEyeInvisible
                  onClick={() => setPassType("text")}
                  style={{ fontSize: "20px", cursor: "pointer" }}
                />
              ) : (
                <AiOutlineEye
                  onClick={() => setPassType("password")}
                  style={{ fontSize: "20px", cursor: "pointer" }}
                />
              )}
            </div>
            <small style={{ color: "red" }}>
              {formik.touched.password &&
                formik.errors.password &&
                formik.errors.password}
            </small>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Confirm Password <span style={{ color: "red" }}>*</span>
            </Form.Label>{" "}
            <br />
            <div className={Styles.passwordField__Style}>
              <input
                type={passType2}
                placeholder="Confirm Password"
                name="confirmPassword"
                {...formik.getFieldProps("confirmPassword")}
                s
              />
              {passType2 === "password" ? (
                <AiOutlineEyeInvisible
                  onClick={() => setPassType2("text")}
                  style={{ fontSize: "20px", cursor: "pointer" }}
                />
              ) : (
                <AiOutlineEye
                  onClick={() => setPassType2("password")}
                  style={{ fontSize: "20px", cursor: "pointer" }}
                />
              )}
            </div>
            <small style={{ color: "red" }}>
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword &&
                formik.errors.confirmPassword}
            </small>
          </Form.Group>
        </>
      ),
    },
    {
      label: "Verification",
      description: (
        <>
          <div className="mb-2">
            <button
              type="button"
              onClick={() => phoneButtonHandler("phone")}
              className={
                !emailType
                  ? `${Styles.buttonActive__Style}`
                  : `${Styles.button__Style}`
              }
            >
              Phone
            </button>
            <button
              type="button"
              onClick={() => emailButtonHandler("email")}
              className={
                !phoneType
                  ? `${Styles.buttonActive__Style}`
                  : `${Styles.button__Style}`
              }
            >
              Email
            </button>
          </div>
          {emailType === "email" && (
            <Form.Group className="mb-3">
              <Form.Control
                type="email"
                placeholder="Email Address"
                name="email"
                {...formik.getFieldProps("email")}
              />
              <small style={{ color: "red" }}>
                {formik.touched.email &&
                  formik.errors.email &&
                  formik.errors.email}
              </small>
            </Form.Group>
          )}
          {phoneType === "phone" && (
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="01XXXXXXXXX"
                name="phone"
                {...formik.getFieldProps("phone")}
              />
              <small style={{ color: "red" }}>
                {formik.touched.phone &&
                  formik.errors.phone &&
                  formik.errors.phone}
              </small>
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Form.Label>
              Verification code <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <br />
            <LoadingButton
              onClick={verificationCodeHandler}
              endIcon={<SendIcon />}
              disabled={isDisable}
              loading={loading}
              loadingPosition="end"
              variant="contained"
              style={{
                backgroundColor: "#958470",
                marginBottom: "10px",
                "&:hover": {
                  backgroundColor: "#372B2B",
                  color: "white",
                },
              }}
            >
              Send Code
            </LoadingButton>
            {isDisable && (
              <p style={{ color: "#958470" }}>
                plz wait atleast 3 mins before resending the code
              </p>
            )}
            <Form.Control
              className={Styles.sendCodeInputField__style}
              type="text"
              placeholder="Verification Code"
              name="otp_code"
              {...formik.getFieldProps("otp_code")}
            />

            <small style={{ color: "red" }}>
              {formik.touched.otp_code &&
                formik.errors.otp_code &&
                formik.errors.otp_code}
            </small>
          </Form.Group>
        </>
      ),
    },
    {
      label: "Promo Code",
      description: (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Promo Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Optional"
              name="promoCode"
              {...formik.getFieldProps("promoCode")}
            />
            <small style={{ color: "red" }}>
              {formik.touched.promoCode &&
                formik.errors.promoCode &&
                formik.errors.promoCode}
            </small>
          </Form.Group>
        </>
      ),
    },
  ];
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={Styles.main__div}>
      <Container className="mt-4 py-2">
        <Row>
          <Col className="mx-auto" md={10}>
            <Paper className="shadow">
              <Row>
                <Col md={6}>
                  <div className={Styles.left__style}>
                    <img src={loginImg} alt="" />
                    <div className={Styles.overlay__style}>
                      <div className={Styles.content__style}>
                        <img
                          style={{ width: "130px", height: "130px" }}
                          src={plateLogo}
                          alt=""
                        />
                        {/* react-spring animated div */}
                        <animated.div style={props}>
                          <h4>HOMEMADE </h4>
                          <h1>FOOD</h1>
                          <h6>AT YOUR DOORSTEP</h6>
                        </animated.div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="px-5 pb-4" md={6}>
                  <div className="text-center">
                    <img
                      className={Styles.myPlate__logo__style}
                      src={plateLogo}
                      alt=""
                    />
                  </div>
                  <h4 className="text-center py-4" style={{ color: "#372B2B" }}>
                    Registration
                  </h4>
                  <p className="mt-2">
                    Already have an account ?
                    <Link className={Styles.registerLink__style} to="/login">
                      Login
                    </Link>
                  </p>
                  <Form onSubmit={formik.handleSubmit}>
                    <Box>
                      <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                          <Step
                            key={step.label}
                            sx={{
                              "& .MuiStepLabel-root .Mui-completed": {
                                color: "#372B2B", // circle color (COMPLETED)
                              },
                              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                                {
                                  color: "#372B2B", // Just text label (COMPLETED)
                                },
                              "& .MuiStepLabel-root .Mui-active": {
                                color: "#372B2B", // circle color (ACTIVE)
                              },
                              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                                {
                                  color: "common.white", // Just text label (ACTIVE)
                                },
                              "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text":
                                {
                                  fill: "common.white", // circle's number (ACTIVE)
                                },
                            }}
                          >
                            <StepLabel>{step.label}</StepLabel>
                            <StepContent>
                              {step.description}
                              <Box sx={{ mb: 10 }}>
                                <div>
                                  <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    sx={{ mt: 1, mr: 1 }}
                                  >
                                    {index === steps.length - 1
                                      ? "Finish"
                                      : "Continue"}
                                  </Button>
                                  <Button
                                    disabled={index === 0}
                                    onClick={handleBack}
                                    sx={{ mt: 1, mr: 1 }}
                                  >
                                    Back
                                  </Button>
                                </div>
                              </Box>
                            </StepContent>
                          </Step>
                        ))}
                      </Stepper>
                      {activeStep === steps.length && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                          <Typography align="center">
                            <Button
                              variant="contained"
                              onClick={handleBack}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Go Back
                            </Button>
                          </Typography>

                          <Form.Group
                            className="mb-3 d-flex align-items-center justify-content-between"
                            controlId="formBasicCheckbox"
                          >
                            <Form.Check
                              required
                              style={{ fontSize: "14px" }}
                              type="checkbox"
                              label="I agree to all the Terms and Privacy Policy"
                            />
                          </Form.Group>

                          <Button
                            style={{
                              width: "100%",
                              color: "white",
                              backgroundColor: "#372B2B",
                              marginBottom: "80px",
                            }}
                            variant="contained"
                            type="submit"
                          >
                            Sign in
                          </Button>
                        </Paper>
                      )}
                    </Box>
                    {/* <Form.Group className="mb-3">
                      <Form.Label>
                        Your Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Firstname Lastname"
                        name="name"
                        {...formik.getFieldProps("name")}
                      />
                      <small style={{ color: "red" }}>
                        {formik.touched.name &&
                          formik.errors.name &&
                          formik.errors.name}
                      </small>
                    </Form.Group>
                    <div className="mb-2">
                      <button
                        type="button"
                        onClick={() => phoneButtonHandler("phone")}
                        className={
                          !emailType
                            ? `${Styles.buttonActive__Style}`
                            : `${Styles.button__Style}`
                        }
                      >
                        Phone
                      </button>
                      <button
                        type="button"
                        onClick={() => emailButtonHandler("email")}
                        className={
                          !phoneType
                            ? `${Styles.buttonActive__Style}`
                            : `${Styles.button__Style}`
                        }
                      >
                        Email
                      </button>
                    </div>
                    {emailType === "email" && (
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="email"
                          placeholder="Email Address"
                          name="email"
                          {...formik.getFieldProps("email")}
                        />
                        <small style={{ color: "red" }}>
                          {formik.touched.email &&
                            formik.errors.email &&
                            formik.errors.email}
                        </small>
                      </Form.Group>
                    )}
                    {phoneType === "phone" && (
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="01XXXXXXXXX"
                          name="phone"
                          {...formik.getFieldProps("phone")}
                        />
                        <small style={{ color: "red" }}>
                          {formik.touched.phone &&
                            formik.errors.phone &&
                            formik.errors.phone}
                        </small>
                      </Form.Group>
                    )} */}

                    {/* <Form.Group className="mb-3">
                      <Form.Label>
                        Verification code{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <br />
                      <LoadingButton
                        onClick={verificationCodeHandler}
                        endIcon={<SendIcon />}
                        disabled={isDisable}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        style={{
                          backgroundColor: "#958470",
                          marginBottom: "10px",
                          "&:hover": {
                            backgroundColor: "#372B2B",
                            color: "white",
                          },
                        }}
                      >
                        Send Code
                      </LoadingButton>
                      {isDisable && (
                        <p style={{ color: "#958470" }}>
                          plz wait atleast 3 mins before resending the code
                        </p>
                      )}

                      {/* 

                      <button
                        disabled={isDisable}
                        type="button"
                        onClick={verificationCodeHandler}
                        className={Styles.sendCodeButton__Style}
                      >
                        Send code
                      </button> */}

                    {/* <Form.Control
                        className={Styles.sendCodeInputField__style}
                        type="text"
                        placeholder="Verification Code"
                        name="otp_code"
                        {...formik.getFieldProps("otp_code")}
                      /> */}

                    {/* <div className={Styles.verification_field__style}>
                        <input
                          className={Styles.sendCodeInputField__style}
                          type="text"
                          placeholder="Verification Code"
                          name="otp_code"
                          {...formik.getFieldProps("otp_code")}
                        />
                        <button
                          disabled={isDisable}
                          type="button"
                          onClick={verificationCodeHandler}
                          className={Styles.sendCodeButton__Style}
                        >
                          Send code
                        </button>
                      </div> */}
                    {/* <small style={{ color: "red" }}>
                        {formik.touched.otp_code &&
                          formik.errors.otp_code &&
                          formik.errors.otp_code}
                      </small>
                    </Form.Group> */}
                    {/* 
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Password <span style={{ color: "red" }}>*</span>
                      </Form.Label>{" "}
                      <br />
                      <div className={Styles.passwordField__Style}>
                        <input
                          type={passType}
                          placeholder="Password"
                          name="password"
                          {...formik.getFieldProps("password")}
                        />
                        {passType === "password" ? (
                          <AiOutlineEyeInvisible
                            onClick={() => setPassType("text")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        ) : (
                          <AiOutlineEye
                            onClick={() => setPassType("password")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        )}
                      </div>
                      <small style={{ color: "red" }}>
                        {formik.touched.password &&
                          formik.errors.password &&
                          formik.errors.password}
                      </small>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </Form.Label>{" "}
                      <br />
                      <div className={Styles.passwordField__Style}>
                        <input
                          type={passType2}
                          placeholder="Confirm Password"
                          name="confirmPassword"
                          {...formik.getFieldProps("confirmPassword")}
                          s
                        />
                        {passType2 === "password" ? (
                          <AiOutlineEyeInvisible
                            onClick={() => setPassType2("text")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        ) : (
                          <AiOutlineEye
                            onClick={() => setPassType2("password")}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        )}
                      </div>
                      <small style={{ color: "red" }}>
                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword &&
                          formik.errors.confirmPassword}
                      </small>
                    </Form.Group> */}

                    {/* <Form.Group
                      className="mb-3 d-flex align-items-center justify-content-between"
                      controlId="formBasicCheckbox"
                    >
                      <Form.Check
                        required
                        style={{ fontSize: "14px" }}
                        type="checkbox"
                        label="I agree to all the Terms and Privacy Policy"
                      />
                    </Form.Group> */}

                    {/* <Button
                      style={{
                        width: "100%",
                        color: "white",
                        backgroundColor: "#372B2B",
                        marginBottom: "80px",
                      }}
                      variant="contained"
                      type="submit"
                    >
                      Sign in
                    </Button> */}
                  </Form>
                </Col>
              </Row>
            </Paper>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RegisterComp;

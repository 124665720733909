import React from "react";
import OrderStatus from "../../Components/OrderStatus/OrderStatus";

const OrderStatusPage = () => {
  return (
    <div>
      <OrderStatus />
    </div>
  );
};

export default OrderStatusPage;

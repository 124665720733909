import {
  GET_CUSTOMER_ORDERS,
  GET_MY_ORDERS,
  OWN_DELIVERY_ORDERS,
  DELETE_MY_ORDER,
} from "./OrderTypes";

const init = {
  customer_orders: "",
  my_orders: "",
  own_delivery_data: "",
  delete_my_order: "",
};

export const orderReducer = (state = init, action) => {
  switch (action.type) {
    case GET_MY_ORDERS: {
      return {
        ...state,
        my_orders: action.payload,
      };
    }
    case GET_CUSTOMER_ORDERS: {
      return {
        ...state,
        customer_orders: action.payload,
      };
    }
    case OWN_DELIVERY_ORDERS: {
      return {
        ...state,
        own_delivery_data: action.payload,
      };
    }
    case DELETE_MY_ORDER: {
      return {
        ...state,
        delete_my_order: action.payload,
      };
    }

    default:
      return state;
  }
};

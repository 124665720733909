import React from "react";
import DeliveryManProfileComp from "./../../Components/DeliveryManComps/DeliveryManProfileComp/DeliveryManProfileComp";

const DeliveryManprofilePage = () => {
  return (
    <div>
      <DeliveryManProfileComp />
    </div>
  );
};

export default DeliveryManprofilePage;

import React from "react";
import UpdateDeliveryMethod from "../../Components/AdminComps/DeliveryMethodComps/UpdateDeliveryMethod/UpdateDeliveryMethod";

const UpdateDeliveryMethodPage = () => {
  return (
    <div>
      <UpdateDeliveryMethod />
    </div>
  );
};

export default UpdateDeliveryMethodPage;
